import {
    Button, Space, theme, Drawer,
} from 'antd';
import { useState, useEffect } from 'react';

import SeoMeta from 'Components/SeoMeta';
import DynamicTable from 'Components/DynamicTable';

import GreenhouseForm from '../GreenhouseForm';
// thinking of passing the lands parent item instead of adding only entry here.
function GreenhouseMgmt({ entry, open }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [model, setModel] = useState([]);

    const { token } = theme.useToken();

    const containerStyle = {
        position: 'relative',
        overflow: 'hidden',
        textAlign: 'center',
        background: token.colorFillAlter,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };

    useEffect(() => {
        if (entry) {
            const { greenhouses } = entry;
            setModel(greenhouses);
        }
    }, [entry]);

    useEffect(() => {
        setIsDrawerOpen(open);
    }, [open]);

    const columns = [
        {
            title: 'مساحة',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'وحدة قياس',
            dataIndex: 'unit',
            key: 'unit',
        },
    ];

    const onCreate = (entity) => {
        const updatedModel = [...model, entity];
        setModel(updatedModel);
    };

    return (
        <>
            <SeoMeta title="إدارة الدفيئات" />
            <div style={containerStyle}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <Button type="primary" onClick={() => { setIsDrawerOpen(true); }}>إنشاء جديد</Button>
                    <DynamicTable columns={columns} dataSource={model} light />
                </Space>
                <Drawer
                    title="إدارة الدفيئات/أضف الدفيئ"
                    placement="left"
                    closable={false}
                    onClose={() => setIsDrawerOpen(false)}
                    open={isDrawerOpen}
                    getContainer="body" // Set getContainer to 'body'
                    width="25%"
                    headerStyle={{ backgroundColor: '#3E97FF', color: 'white' }} // Set the background color to green and text color to white
                >
                    <GreenhouseForm entry={entry} onComplete={onCreate} />
                </Drawer>
            </div>
        </>
    );
}

export default GreenhouseMgmt;
