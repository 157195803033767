import React, { useContext } from 'react';
import {
    Space, message,
} from 'antd';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateLand } from 'apis/lands';
import { AuthContext } from 'shared/AuthProvider';

import SelectField from '../FormFields/SelectField';
import { landFeatures, landTypes, soilTypes } from '../../shared/constants';
import CustomForm from '../CustomForm';

function EditLandSoilForm({ entry, onComplete, onClose }) {
    const { user } = useContext(AuthContext);

    const updateLand = useUpdateLand();
    const schema = yup.object().shape({
        // array of strings
        landType: yup.string().required(),
        soilType: yup.array().of(yup.string()).required(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            landType: entry?.landType,
            soilType: entry?.soilType,
            features: entry?.features,
        },
    });

    const handleFinish = async (data) => {
        try {
            await updateLand.mutateAsync({
                landId: entry.id,
                data,
                userUid: user.uid,
            });
            message.success('تمت العملية بنجاح');
        } catch (e) {
            console.error('Error updating land soil', e);
            message.error('لقد حصل خطأ');
        } finally {
            onComplete?.(data);
            onClose?.();
        }
    };

    return (
        <CustomForm layout="vertical" onFinish={handleSubmit(handleFinish)} reset={reset} submitText="حفظ" loading={updateLand.isLoading} fullForm>
            <Space
                direction="vertical"
                style={{ display: 'flex', marginBottom: 8 }}
            >
                <SelectField
                    search
                    label="نوع الأرض"
                    placeholder="نوع الأرض"
                    control={control}
                    fieldName="landType"
                    errors={errors}
                    options={landTypes}
                />
                <SelectField
                    search
                    mode="multiple"
                    label="نوع التربة"
                    placeholder="نوع التربة"
                    control={control}
                    fieldName="soilType"
                    errors={errors}
                    options={soilTypes}
                />
                <SelectField
                    search
                    mode="multiple"
                    label="خصائص"
                    control={control}
                    fieldName="features"
                    errors={errors}
                    options={landFeatures}
                />
            </Space>
        </CustomForm>
    );
}
export default EditLandSoilForm;
