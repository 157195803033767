import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetCrops = (profiles, land, offset = 0, queryLimit = 9999) => useQuery(['useGetCrops', {
    profiles, land, queryLimit, offset,
}], async () => {
    const entries = [];

    const cropsRef = collection(firebaseFirestore, DbTableNames.crops);
    const queryParams = [cropsRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (land) {
        queryParams.push(where('landId', '==', land.id));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetLandsCrops = (lands, offset = 0, queryLimit = 9999) => useQuery(['useGetLandsCrops', {
    lands, queryLimit, offset,
}], async () => {
    const entries = [];

    const cropsRef = collection(firebaseFirestore, DbTableNames.crops);
    const queryParams = [cropsRef];

    if (lands) {
        queryParams.push(where('landId', 'in', lands));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
}, {
    enabled: !!lands,
});

export const useGetCrop = (cropId) => useQuery(
    ['useGetCrop', cropId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.crops, cropId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!cropId,
    },
);

export const useCreateCrop = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.crops), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const searchCrop = (queryString) => {
    const results = [].filter((crop) => {
        // Case-insensitive search by family or name
        const familyMatch = crop.family.includes(queryString);
        const nameMatch = crop.name.includes(queryString);
        return familyMatch || nameMatch;
    });
    return results;
};
