import { Button, Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

import {
    cities,
} from 'shared/constants';
import TextField from 'Components/FormFields/TextField';
import SelectField from 'Components/FormFields/SelectField';

export default function CreateEditAddressForm({ onSubmit, entry }) {
    // const { user } = useContext(AuthContext);
    // const createWater = useCreateWaterSource();
    // const udateWaterSource = useUpdateWaterSource();
    const schema = yup.object().shape({
        address: yup.string().required(),
        street: yup.string().required(),
        houseNumber: yup.string().required(),
        city: yup.string().required(),
    });
    const {
        form,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            address: entry?.address,
            street: entry?.street,
            houseNumber: entry?.houseNumber,
            city: entry?.city?.id,
            governorate: entry?.governorate,
        },
    });

    const governorateValue = watch('governorate');

    const handleFinish = async (data) => {
        // try {
        //     if (entry?.id) {
        //         // await udateWaterSource.mutateAsync({
        //         //     data,
        //         //     userUid: user?.uid,
        //         //     waterSourceId: entry?.id,
        //         // });
        //     } else {
        //         await createWater.mutateAsync({
        //             data,
        //             userUid: user?.uid,
        //         });
        //     }
        //     message.success('تم الحفظ');
        // } catch (e) {
        //     message.error('لقد حصل خطأ ما');
        // } finally {
        //     onSubmit?.(data);
        // }

        const city = _.find(cities, { id: data.city });
        // const city = {
        //     id: city.id,
        //     city,
        // };
        setValue('city', city);
        setValue('id', city?.id);
        console.table(data);

        onSubmit?.(data);
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit(handleFinish)} autocomplete="off">
            <SelectField
                search
                label="المحافظة"
                control={control}
                fieldName="governorate"
                errors={errors}
                options={cities
                    .filter((city) => city.parent === null)
                    .map((city) => ({
                        key: city.id,
                        value: city.id,
                        label: city.arabic,
                    }))}
            />
            <SelectField
                search
                label="المدينة/القرية"
                control={control}
                fieldName="city"
                errors={errors}
                options={cities
                    .filter((city) => city.parent !== null && city.parent === governorateValue)
                    .map((city) => ({
                        key: city.id,
                        value: city.id,
                        label: city.arabic,
                    }))}
            />
            <TextField
                label="اسم المنطقة"
                control={control}
                fieldName="address"
                errors={errors}
            />
            <TextField
                label="الشارع"
                control={control}
                fieldName="street"
                errors={errors}
            />
            <TextField
                label="رقم المنزل"
                control={control}
                fieldName="houseNumber"
                errors={errors}
            />
            <Form.Item>
                <Button
                    type="primary"
                    size="small"
                    htmlType="sشubmit"
                    // loading={createWater.isLoading || udateWaterSource.isLoading}
                >
                    { entry?.id ? 'حفظ' : 'إضافة'}
                </Button>
            </Form.Item>
        </Form>
    );
}
