import {
    Button, Space, Badge, Avatar, Dropdown, Modal, message, Typography,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { find } from 'lodash';

import SeoMeta from 'Components/SeoMeta';

import { useGetSubCropTypes } from '../../apis/sub-crop-types';
import FullSubCropTypeDrawer from '../FullSubCropTypeDrawer';
import { sampleCropTypes } from '../../shared/constants';
import DynamicTable from '../DynamicTable';

function SubCropTypeMgmt({
    onComplete, cropTypeId, cropType,
}) {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetSubCropTypes(null, cropTypeId);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [createFlag, setCreateFlag] = useState(false);
    const [entry, setEntry] = useState(null);

    useEffect(() => {
        refetchEntities();
    }, [cropTypeId]);

    const openForEdit = () => {
        setCreateFlag(false);
        setIsDrawerOpen(true);
    };

    const handleOnCreate = (type) => {
        refetchEntities();
        setEntry(type);
        setIsDrawerOpen(false);
        openForEdit();
    };

    const openForCreate = () => {
        setCreateFlag(true);
        setIsDrawerOpen(true);
    };

    const selectAndOpen = (record) => {
        setCreateFlag(false);
        setEntry(record);
        setIsDrawerOpen(true);
    };

    const doDeleteSubCropType = async (id) => {
        // try {
        //     await deleteLand.mutateAsync(id);
        message.success(id);
        message.success('تم الحذف');
        //     refetchEntities();
        // } catch (err) {
        //     message.error('لقد حصل خطأ ما');
        // }
    };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.image}
                        />
                    </Badge>
                    <div style={{ marginRight: '15px' }}>
                        <Typography.Text onClick={() => selectAndOpen(record)}>
                            {text}
                        </Typography.Text>
                    </div>
                </div>
            ),
        },
        {
            title: 'الاسم',
            dataIndex: 'cropTypeName',
            key: 'cropTypeName',
        },
        {
            title: 'العائلة',
            dataIndex: 'family',
            key: 'family',
            render: (text) => {
                const foundCropType = find(sampleCropTypes, { id: parseInt(text, 10) });
                return foundCropType ? foundCropType.name : null;
            },
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                setEntry(record);
                                openForEdit();
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                            onClick: () => {
                                Modal.confirm({
                                    title: 'هل أنت متأكد من الحذف؟',
                                    okText: 'نعم',
                                    okType: 'danger',
                                    cancelText: 'لا',
                                    onOk() {
                                        doDeleteSubCropType(record.id);
                                    },
                                });
                            },
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة الأصناف" />
            <FullSubCropTypeDrawer
                width="calc(100%)"
                entry={entry}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreate}
                onClose={() => setIsDrawerOpen(false)}
                cropTypeId={cropTypeId}
                onComplete={onComplete}
                createFlag={createFlag}
                cropType={cropType}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', height: '100%', overflow: 'auto' }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        searchable
                        searchKeys={['name']}
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                        pagination={{
                            pageSize: 10,
                        }}
                        toolBarRender={() => [
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    setEntry(null);
                                    openForCreate();
                                }}
                                type="primary"
                            >
                                إنشاء نبات جديد
                            </Button>,
                        ]}
                    />
                </Space>
            </Space>
        </>

    );
}

export default SubCropTypeMgmt;
