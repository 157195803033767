import React, { useEffect, useState, useContext } from 'react';
import {
    Button, message, Progress, Space, Input, Radio,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import NumberField from '../FormFields/NumberField';
import { AuthContext } from '../../shared/AuthProvider';
import TextField from '../FormFields/TextField';
import ListSubCropTypes from '../ListSubCropTypes';
import SelectField from '../FormFields/SelectField';
import { weightMeasurementUnits, CROP_TYPE_STATUS } from '../../shared/constants';
import { useCreateCrop } from '../../apis/crops';
import { useUpdateLand } from '../../apis/lands';
import DatePickerField from '../FormFields/DatePickerField';
import './style.scss';
import CustomFrom from '../CustomForm';

const schema = yup.object().shape({
    subCropType: yup.string().required('الصنف مطلوب').label('الصنف'),
});

export default function CropForm({
    entry, onComplete, onClose, profileId, selectedLand,
}) {
    const { user } = useContext(AuthContext);
    const createCrop = useCreateCrop();
    const updateLand = useUpdateLand();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedSubCropType, setSelectedSubCropType] = useState(null);
    const [valueElement, setValueElement] = useState(1);
    const [seedValue, setSeedValue] = useState(0);
    const [otherValue, setOtherValue] = useState(0);
    const [calculatedPlantedArea, setCalculatedPlantedArea] = useState(0);
    const [plantedArea, setPlantedArea] = useState(0);

    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            family: entry?.family,
            subCropType: entry?.subCropType,
            cropType: entry?.cropType,
            plantedPercentage: entry?.plantedPercentage,
            cultivatedAreaPercent: entry?.cultivatedAreaPercent,
        },
    });

    const handleFinish = async (data) => {
        console.table(data);
        const newForm = { ...data };
        newForm.subCropTypeId = selectedSubCropType.id;
        newForm.subCropTypeName = selectedSubCropType.name;
        newForm.landId = selectedLand.id;

        setValue('subCropTypeId', selectedSubCropType.id);
        setValue('subCropTypeName', selectedSubCropType.name);
        setValue('landId', selectedLand.id);
        const land = {
            id: selectedLand.id,
            cityDetails: {
                id: selectedLand.id,
                fullCity: selectedLand['full-city'],
                plantedArea,
                plantedPercentage: calculatedPlantedArea,
            },
        };

        // Adding those extra attributes.
        setValue('beneficiaries', [selectedLand.owner?.id]);
        setValue('traders', [selectedLand.owner?.id]);
        setValue('cropTypeId', selectedSubCropType?.cropTypeId);
        setValue('cropType', selectedSubCropType?.cropType);
        setValue('plantingDetails', land);

        newForm.plantingDetails = land;
        newForm.cropType = selectedSubCropType?.cropType;
        newForm.cropTypeId = selectedSubCropType?.cropTypeId;
        newForm.traders = [selectedLand.owner?.id];
        newForm.beneficiaries = [selectedLand.owner?.id];
        // newForm.plantingDate = Timestamp.fromDate(dayjs(newForm.plantingDate));
        // newForm.plantingExpectedEndDate = Timestamp.fromDate(dayjs(newForm.plantingExpectedEndDate));

        console.table(newForm);
        try {
            await createCrop.mutateAsync({
                data: newForm,
                profileId,
                userUid: user?.uid,
            });

            const updatedLandDoc = { ...selectedLand, cultivatedAreaPercent: calculatedPlantedArea };
            await updateLand.mutateAsync({
                data: updatedLandDoc,
                landId: selectedLand.id,
                userUid: user?.uid,
            });

            message.success('It worked yes!');
        } catch (e) {
            message.error('Error!');
        } finally {
            onComplete(data);
        }
        onComplete(data);
        if (onClose) {
            onClose();
        }
    };

    // Use useEffect to reset the form when entry changes
    useEffect(() => {
        reset({
            subCropType: entry?.subCropType?.name || '',
            plantedPercentage: entry?.plantedPercentage || 0,
        });
    }, [entry]);

    // Use useEffect to reset the form when entry changes
    useEffect(() => {
        reset({
            subCropType: entry?.subCropType?.name || '',
            plantedPercentage: entry?.plantedPercentage || 0,
        });
    }, [selectedLand]);

    const handleShowSubCropTypes = () => {
        setIsDrawerOpen(true);
    };

    const closeSelection = () => {
        setIsDrawerOpen(false);
    };

    const handleSelectionChange = (record) => {
        console.table(record);
        setSelectedSubCropType(record);
        setValue('subCropType', record.name);
        setIsDrawerOpen(false);
    };

    const onChange = (e) => {
        setValueElement(e.target.value);
    };

    const changeSeedsAmount = (e) => {
        setSeedValue(e.target.value);
    };

    const changeOtherAmount = (e) => {
        setOtherValue(e.target.value);
    };

    const plantedAreaChanged = (newValue) => {
        setPlantedArea(newValue);
        setCalculatedPlantedArea(() => {
            const totalArea = selectedLand.area;
            const remainingArea = totalArea - newValue;
            const newPercent = Math.floor(((totalArea - remainingArea) / totalArea) * 100);

            return newPercent;
        });
    };

    return (
        <CustomFrom
            form={form}
            layout="horizontal"
            onFinish={handleSubmit(handleFinish)}
            submitText={entry?.id ? 'حفظ' : 'انشاء'}
            reset={reset}
            loading={createCrop.isLoading || updateLand.isLoading}
            fullForm
        >
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <Space direction="horizontal">
                        <TextField
                            label="صنف"
                            control={control}
                            fieldName="subCropType"
                            errors={errors}
                            placeholder="صنف"
                        />
                        <Button className="FromBtn" type="primary" onClick={handleShowSubCropTypes}>
                            اضغط هنا
                        </Button>
                    </Space>
                    <ListSubCropTypes isOpen={isDrawerOpen} onClose={closeSelection} handleSelectionChange={handleSelectionChange} />
                    <Space
                        direction="horizontal"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <NumberField
                            label="المساحة المزروعة"
                            control={control}
                            onChange={plantedAreaChanged}
                            errors={errors}
                            placeholder="المساحة المزروعة"
                            fieldName="plantedPercentage"
                            max={selectedLand?.area}
                            min={0}
                        />
                        <Progress percent={calculatedPlantedArea} size={[300, 20]} />
                    </Space>

                    <Space
                        direction="horizontal"
                        size="middle"
                    >
                        <SelectField
                            search
                            label="نسبه الراعه"
                            control={control}
                            fieldName="status"
                            errors={errors}
                            options={CROP_TYPE_STATUS}
                        />
                        <DatePickerField
                            label="تاريخ الزراعة"
                            control={control}
                            fieldName="plantingDate"
                            errors={errors}
                        />
                        <DatePickerField
                            label="تاريخ الانتهاء المتوقع"
                            control={control}
                            fieldName="plantingExpectedEndDate"
                            errors={errors}
                        />
                    </Space>

                    <h3>طريقة زراعة</h3>
                    <Radio.Group onChange={onChange} value={valueElement}>
                        <Space direction="vertical">
                            <Radio value={1}>
                                بذور
                                {valueElement === 1 ? (
                                    <Input
                                        onChange={changeSeedsAmount}
                                        value={seedValue}
                                        style={{
                                            width: 100,
                                            marginLeft: 10,
                                            marginRight: 10,
                                        }}
                                    />
                                ) : null}
                            </Radio>
                            <Radio value={2}>
                                اشتال
                                {valueElement === 2 ? (
                                    <Input
                                        onChange={changeOtherAmount}
                                        value={otherValue}
                                        style={{
                                            width: 100,
                                            marginLeft: 10,
                                            marginRight: 10,
                                        }}
                                    />
                                ) : null}
                            </Radio>
                        </Space>
                    </Radio.Group>
                    <Space
                        direction="horizontal"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <NumberField
                            label="العائد المقدر"
                            control={control}
                            errors={errors}
                            placeholder="الحد الأدنى لعدد الأيام"
                            fieldName="estimatedYield"
                        />
                        <SelectField
                            search
                            label="اختر وحدة القياس"
                            control={control}
                            fieldName="unit1"
                            errors={errors}
                            options={weightMeasurementUnits.map((unit) => ({
                                value: unit.id,
                                label: unit.name,
                            }))}
                        />
                    </Space>
                    <Space
                        direction="horizontal"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <NumberField
                            label="العائد المقدر للمزارع"
                            control={control}
                            errors={errors}
                            placeholder="الحد الأدنى لعدد الأيام"
                            fieldName="estimatedFarmerYield"
                        />
                        <SelectField
                            search
                            label="اختر وحدة القياس"
                            control={control}
                            fieldName="unit2"
                            errors={errors}
                            options={weightMeasurementUnits.map((unit) => ({
                                value: unit.id,
                                label: unit.name,
                            }))}
                        />
                    </Space>
                </Space>
            </Space>
        </CustomFrom>
    );
}
