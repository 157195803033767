import SeoMeta from 'Components/SeoMeta';

import CropsMgmt from '../../../Components/CropsMgmt';

function Crops({ entry }) {
    return (
        <>
            <SeoMeta title="إدارة الأراضي" />
            <CropsMgmt land={entry} />
        </>

    );
}

export default Crops;
