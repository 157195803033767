import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

const instance = axios.create({
    timeout: 10000,
});

const baseDomain = '5hkp6mgkcq-uc.a.run.app';

// Add an interceptor to the Axios instance
instance.interceptors.request.use((config) => {
    const idToken = localStorage.getItem('firebaseIdToken');
    if (idToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = idToken;
    }

    return config;
});

export const useGetAuthUsers = (page) => useQuery(
    ['auth_users'],
    async () => {
        const { data: result } = await instance.get(`https://getallusers-${baseDomain}`, {
            params: {
                page,
                pageSize: 10,
            },
        });
        return result;
    },
);

export const useUpdateAuthUser = () => useMutation({
    mutationFn: async ({ uid, data }) => {
        const { data: result } = await instance.post(`https://deleteuser-${baseDomain}`, {
            uid,
            data,
        });
        return result;
    },
});

export const useUpdateAuthUserAdmin = () => useMutation({
    mutationFn: async ({ uid, isAdmin }) => {
        const { data: result } = await instance.post(`https://setadminclaim-${baseDomain}`, { uid, isAdmin });
        return result;
    },
});

export const useUpdateAuthUserAdminTemp = () => useMutation({
    mutationFn: async ({ uid, isAdmin }) => {
        const { data: result } = await instance.post(`https://setadminclaimtemp-${baseDomain}`, { uid, isAdmin });
        return result;
    },
});

export const useUpdateAuthUserEngineer = () => useMutation({
    mutationFn: async ({ data }) => {
        const { data: result } = await instance.post(`https://setengineerclaim-${baseDomain}`, {
            uid: data.uid, displayName: data.displayName, isEngineer: data.isEngineer, email: data.email,
        });
        return result;
    },
});

// deleteuser disableenableuser
