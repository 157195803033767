// utils/authProvider.js

import React, {
    createContext, useEffect, useState, useMemo,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { firebaseAuth } from './firebase.init';

const AuthContext = createContext();

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEngineer, setIsEngineer] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, async (loggedInUser) => {
            loggedInUser?.getIdTokenResult()
                .then((idTokenResult) => {
                    localStorage.setItem('firebaseIdToken', idTokenResult.token);
                    if (idTokenResult.claims.admin) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                    if (idTokenResult.claims.engineer) {
                        setIsEngineer(true);
                    } else {
                        setIsEngineer(false);
                    }
                });
            setUser(loggedInUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const signOut = async () => {
        try {
            await firebaseAuth.signOut();
        } catch { /* empty */ }
    };

    const value = useMemo(() => ({
        user,
        loading,
        signOut,
        isAdmin,
        isEngineer,
    }), [user, loading, signOut, isAdmin, isEngineer]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
