import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc, updateDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetCropTypes = (profiles, offset = 0, queryLimit = 9999) => useQuery(['useGetCropTypes', { profiles, queryLimit, offset }], async () => {
    const entries = [];

    const cropTypesRef = collection(firebaseFirestore, DbTableNames.cropTypes);
    const queryParams = [cropTypesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetCropTypesForAggregator = (profiles, offset = 0, queryLimit = 9999) => useQuery(['useGetCropTypesForAggregator', { profiles, queryLimit, offset }], async () => {
    const entries = [];

    const cropTypesRef = collection(firebaseFirestore, DbTableNames.cropTypes);
    const queryParams = [cropTypesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(where('plantingDetails', '!=', null));
    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetCropType = (cropTypeId) => useQuery(
    ['useGetCropType', cropTypeId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.cropTypes, cropTypeId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!cropTypeId,
    },
);

export const useCreateCropType = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.cropTypes), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdateCropType = () => useMutation({
    mutationFn: async ({ data, cropTypeId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.cropTypes, cropTypeId), updateData);
        return {
            ...data,
        };
    },
});

export const searchCropTypes = (queryString) => {
    const results = [].filter((crop) => {
        // Case-insensitive search by family or name
        const familyMatch = crop.family.includes(queryString);
        const nameMatch = crop.name.includes(queryString);
        return familyMatch || nameMatch;
    });
    return results;
};

// import { useQuery } from '@tanstack/react-query';
//
// // We will replace this with the real db.
// const listDataSource = [
//     {
//         id: 1,
//         family: 'خضروات',
//         name: 'طماطم',
//         image: 'https://images-prod.healthline.com/hlcmsresource/images/AN_images/tomatoes-1296x728-feature.jpg',
//     },
//     {
//         id: 2,
//         family: 'خضروات',
//         name: 'بطاطس',
//         image: 'https://media.zid.store/cdn-cgi/image/f=auto/https://media.zid.store/038bdcd1-ceae-4636-a55d-9c4a592615ca/6ba8dd65-3d09-4467-b516-517d166548d2.jpeg',
//     },
//     {
//         id: 3,
//         family: 'فواكه',
//         name: 'تفاح',
//         image: 'https://png.pngtree.com/element_our/png/20181129/vector-illustration-of-fresh-red-apple-with-single-leaf-png_248312.jpg',
//     },
//     {
//         id: 5,
//         family: 'حبوب',
//         name: 'قمح',
//         image: 'https://i.pinimg.com/736x/8d/52/f8/8d52f8ebafcf52b62a40f073225956b2.jpg',
//     },
//     {
//         id: 6,
//         family: 'حبوب',
//         name: 'أرز',
//         image: 'https://www.pngarts.com/files/3/White-Rice-PNG-Image-Background.png',
//     },
//     {
//         id: 9,
//         family: 'زهور',
//         name: 'ورد',
//         image: 'https://3.bp.blogspot.com/-PmhdGF0RJjM/VydFTT97bAI/AAAAAAAAUqM/9gIm46cNgKMSvJac25XAAXZkZuO0pA-BwCLcB/s1600/%25D8%25B5%25D9%2588%25D8%25B1%2B%25D9%2588%25D8%25B1%25D8%25AF_1.jpg',
//     },
// ];
//
// export function getCropType(id) {
//     return listDataSource.find((item) => item.id === id);
// }
//
// // export const listCropTypes = () => useQuery(['cropTypes'], async () => listDataSource, {
// //     enabled: true,
// // });
//
// export function listCropTypes() {
//     return listDataSource;
// }
//
// export const searchCropTypes = (query) => {
//     const results = listDataSource.filter((crop) => {
//         // Case-insensitive search by family or name
//         const familyMatch = crop.family.includes(query);
//         const nameMatch = crop.name.includes(query);
//         return familyMatch || nameMatch;
//     });
//     return results;
// };
//
// export const listCropTypesByFamily = (familyId) => useQuery([`cropTypes${familyId}`], async () => listDataSource, {
//     enabled: true,
// });
