import { Form, InputNumber } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function NumberField({
    control, label, placeholder, fieldName, errors, min, max, addonAfter, onChange,
}) {
    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <InputNumber
                        min={min}
                        max={max}
                        {...field}
                        placeholder={placeholder}
                        addonAfter={addonAfter}
                        onChange={(value) => {
                            field.onChange(value); // Update the value in React Hook Form
                            if (onChange) {
                                onChange(value); // Call the additional onChange function if provided
                            }
                        }}
                    />
                )}
            />
        </Form.Item>
    );
}

export default NumberField;
