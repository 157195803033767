import {
    Button, Dropdown, message, Modal, Space,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
// import { find } from 'lodash';

import SeoMeta from 'Components/SeoMeta';

import FullPestsDrawer from '../FullPestsDrawer';
// import { useDeletePest } from '../../apis/pests';
import FullMediaDrawer from '../FullMediaDrawer';
import { useGetPestsReportsAggregator } from '../../apis/pests-aggregator';
import DynamicTable from '../DynamicTable';

function PestsReportsAggregatorMgmt() {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetPestsReportsAggregator();
    // const deletePest = useDeletePest();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);
    const [entry, setEntry] = useState(null);

    const handleOnCreateOrUpdate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const handleOnClose = () => {
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const onMediaCreate = () => {
    };

    const doDeleteIncident = async (id) => {
        try {
            // await deletePest.mutateAsync(id);
            message.success(id);
            refetchEntities();
        } catch (err) {
            message.error('لقد حصل خطأ ما');
        }
    };

    const getCityArabicName = (city) => {
        switch (city) {
        case 'hebron-governorate':
            return 'محافظة الخليل';
        case 'jenin-governorate':
            return 'محافظة جنين';
        case 'jericho-governorate':
            return 'محافظة اريحا';
        case 'jerusalem-governorate':
            return 'محافظة القدس';
        case 'nablus-governorate':
            return 'محافظة نابلس';
        case 'qalqiliya-governorate':
            return 'محافظة قلقيلية';
        case 'ramallah_and_al_bireh-governorate':
            return 'محافظة رام الله والبيرة';
        case 'salfit-governorate':
            return 'محافظة سلفيت';
        case 'tubas-governorate':
            return 'محافظة طوباس';
        case 'tulkarem-governorate':
            return 'محافظة طولكرم';
        default:
            return '';
        }
    };

    const columns = [
        {
            title: 'المحافظة',
            dataIndex: 'govId',
            key: 'govId',
            render: (govId) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{getCityArabicName(govId)}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'عدد الحوادث',
            dataIndex: 'count',
            key: 'count',
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'عرض الملف',
                                onClick: () => {
                                },
                            },
                            {
                                key: '2',
                                label: 'وسائط',
                                onClick: () => {
                                },
                            },
                            {
                                key: '3',
                                label: 'حذف',
                                onClick: () => {
                                    Modal.confirm({
                                        title: 'هل أنت متأكد من الحذف؟',
                                        okText: 'نعم',
                                        okType: 'danger',
                                        cancelText: 'لا',
                                        onOk() {
                                            doDeleteIncident(record.id);
                                        },
                                    });
                                },
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                    />
                </Space>
            </Space>
            <FullPestsDrawer
                width="calc(100%)"
                entry={entry}
                onClose={handleOnClose}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreateOrUpdate}
            />
            <FullMediaDrawer
                width="calc(100% - 200px)"
                entry={entry}
                onClose={() => setIsMediaOpen(false)}
                isOpen={isMediaOpen}
                onConfirm={onMediaCreate}
            />
        </>

    );
}

export default PestsReportsAggregatorMgmt;
