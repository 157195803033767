import React, { useEffect, useState, useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
    Avatar,
    List,
    message,
    Spin,
} from 'antd';

import { useCreateMedia, useGetEntryMediaByType } from '../../../apis/media';
import { AuthContext } from '../../../shared/AuthProvider';

import CreateLinkDialog from './CreateLinkDialog';

function LinksContainer({
    entry, onUploadCompleted,
}) {
    const queryClient = useQueryClient();
    const { data, isLoading, refetch } = useGetEntryMediaByType(null, entry?.id, 'link');

    useEffect(() => {
        if (entry) {
            // setData(entry.links);
            refetch();
        }
    }, [entry, onUploadCompleted]);

    const [isCreateLinkOpen, setIsCreateLinkOpen] = useState(false);

    const createMedia = useCreateMedia();
    const { user } = useContext(AuthContext);

    const onLinkCreated = async (formData) => {
        const newForm = { ...formData };
        try {
            if (entry) {
                const dataElement = {
                    entryId: entry.id,
                    url: newForm.link,
                    name: newForm.title,
                    status: 'uploaded',
                    type: 'link',
                    description: newForm.description,
                };

                await createMedia.mutateAsync({
                    data: dataElement,
                    userUid: user?.uid,
                });
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            message.success('تم إنشاء الآفات بنجاح');
            queryClient.invalidateQueries(['useGetEntryMediaByType']);
            setIsCreateLinkOpen(false);
        }
    };

    const onLinkCreateCanceled = () => {
        setIsCreateLinkOpen(false);
    };

    return (
        <>
            <div>
                <PlusOutlined onClick={() => setIsCreateLinkOpen(true)} />
                إنشاء رابط جديد
            </div>
            <div>
                {isLoading && data && data.length > 0 ? (
                    <Spin size="large" />
                ) : (
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                                    title={<a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                )}
            </div>
            <CreateLinkDialog onCreate={onLinkCreated} onCancel={onLinkCreateCanceled} visible={isCreateLinkOpen} />
        </>
    );
}
export default LinksContainer;
