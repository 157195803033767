import React, { useState, useEffect } from 'react';
import {
    Menu,
    Space,
} from 'antd';

import SeoMeta from 'Components/SeoMeta';

import { PestsInSubCropTypes } from '../../shared/constants';
import { useGetPests } from '../../apis/pests';
import PestInformationMgmt from '../PestInformationMgmt';
import PestMediaMgmt from '../PestMediaMgmt';
import PestFightInfoMgmt from '../PestFightInfoMgmt';
import PestsTransfer from '../PestsTransfer';
import DynamicTable from '../DynamicTable';

function SubCropTypePestsMgmt({ entry, subCropTypeId, onComplete }) {
    const { data: entities, isLoading: isLoadingEntities } = useGetPests({});
    const [current, setCurrent] = useState(PestsInSubCropTypes[0].id);
    const [selectedPest, setSelectedPest] = useState(PestsInSubCropTypes[0].id);
    const [pests, setPests] = useState([]);

    const handleCellClick = (record) => {
        setSelectedPest(record);
    };

    const columns = [
        {
            title: 'اسم الآفة',
            dataIndex: 'name',
            key: 'name',
            onCell: (record) => ({
                onClick: () => handleCellClick(record),
            }),
        },
    ];

    useEffect(() => {
        if (!entities) {
            return;
        }
        const newData = entities.map((entity) => ({
            key: entity.id,
            title: entity.name,
            description: entity.content,
        }));

        const filteredData = entities.filter((pest) => newData.some(() => pest.subCropTypesIds && pest.subCropTypesIds.includes(entry.id)));
        setPests(filteredData);
    }, [entry, entities]);

    function getRenderWidget() {
        let componentToRender;

        if (current === 'information') {
            componentToRender = <PestInformationMgmt entry={entry} selectedPest={selectedPest} subCropTypeId={subCropTypeId} />;
        } else if (current === 'fight') {
            componentToRender = <PestFightInfoMgmt entry={entry} selectedPest={selectedPest} />;
        } else if (current === 'links') {
            componentToRender = <PestMediaMgmt entry={entry} selectedPest={selectedPest} />;
        } else {
            // Handle the default case or any other conditions here
            componentToRender = <div />;
        }

        return <div>{componentToRender}</div>;
    }

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                align="start"
                style={{
                    display: 'flex', width: '100%', height: '100%', overflow: 'auto',
                }}
            >
                <div style={{ width: '300px' }}>
                    <PestsTransfer allPests={entities} entry={entry} onComplete={onComplete} />
                </div>

                <Space direction="horizontal" size="middle" align="start" style={{ display: 'flex', width: '100%', gap: 24 }}>
                    <div style={{ width: '300px' }}>
                        <DynamicTable columns={columns} dataSource={pests} loading={isLoadingEntities} rowKey="id" light />
                    </div>
                    <div>
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ display: 'flex', width: '100%' }}
                        >
                            <Space
                                direction="horizontal"
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center', // Vertically center-align items
                                    justifyContent: 'space-between', // Distribute items evenly
                                    padding: '16px', // Add padding for better spacing
                                }}
                            >
                                <Menu
                                    onClick={(newKey) => setCurrent(newKey.key)}
                                    selectedKeys={[current]}
                                    mode="horizontal"
                                    items={PestsInSubCropTypes.map((element) => ({ label: element.name, key: element.id }))}
                                    style={{ flex: 1, width: '400px', whiteSpace: 'normal' }} // Enable text wrapping
                                />
                            </Space>
                            {getRenderWidget()}
                        </Space>
                    </div>
                </Space>
            </Space>

        </>

    );
}

export default SubCropTypePestsMgmt;
