import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from 'react-router-dom';

import PrivateRoute from 'Components/PrivateRoute';
import LoginPage from 'Pages/Login';
import DashboardPage from 'Pages/Dashboard';
import UsersManagement from 'Pages/UsersManagement';
import Users from 'Pages/UsersManagement/Users';
import Profiles from 'Pages/UsersManagement/Profiles';
import DashboardLayout from 'Components/DashboardLayout';
import LandsManagement from 'Pages/LandsManagement';
import Lands from 'Pages/LandsManagement/Lands';
// import Wells from 'Pages/LandsManagement/Wells';
// import PlasticHouses from 'Pages/LandsManagement/PlasticHouses';
import CropsManagement from 'Pages/CropsManagement';
import Crops from 'Pages/CropsManagement/Crops';
import CropsDashboard from 'Pages/CropDashboardManagement/Crops';
import Pests from 'Pages/CropsManagement/Pests';
import CropTypesManagment from 'Pages/CropTypeManagment';
import SubCropTypesManagement from 'Pages/SubCropTypeManagement';
import CropTypes from 'Pages/CropTypeManagment/CropTypes';
import SubCropTypes from 'Pages/CropTypeManagment/SubCropTypes';
import AccountSettings from 'Pages/AccountSettings';

import WaterManagment from './Pages/WaterManagment';
import WaterMgmt from './Components/WaterMgmt';
import CropDashboardManagement from './Pages/CropDashboardManagement';
import PestsManagment from './Pages/PestsManagment';
import CropTypesAggregation from './Pages/CropDashboardManagement/CropTypesAggregation';
import PestsReports from './Pages/CropsManagement/PestsReports';
import PestsAggregator from './Pages/CropsManagement/PestsAggregator';
import SubCropTypesRequests from './Pages/CropTypeManagment/SubCropTypesRequests';
import SubCropTypesReviews from './Pages/CropTypeManagment/SubCropTypesReviews';

const router = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/account-settings',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <AccountSettings />
                </DashboardLayout>
            </PrivateRoute>
        ),
    },
    {
        path: '/dashboard',
        element: (
            <PrivateRoute>
                <DashboardLayout>
                    <DashboardPage />
                </DashboardLayout>
            </PrivateRoute>
        ),
    },
    {
        path: '/users-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <UsersManagement />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'users',
                element: <Users />,
            },
            {
                path: 'profiles',
                element: <Profiles />,
            },
        ],
    },
    {
        path: '/lands-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <LandsManagement />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'lands',
                element: <Lands />,
            },
            {
                path: 'people-lands',
                element: <Lands profileType="people" />,
            },
            {
                path: 'merchants-lands',
                element: <Lands profileType="merchants" />,
            },
            {
                path: 'agricultural_institutions-lands',
                element: <Lands profileType="agricultural_institutions" />,
            },
            {
                path: 'companies-lands',
                element: <Lands profileType="companies" />,
            },
        ],
    },
    {
        path: '/water-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <WaterManagment />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'sources',
                element: <WaterMgmt />,
            },
        ],
    },
    {
        path: '/crops-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <CropsManagement />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'crops',
                element: <Crops />,
            },
            {
                path: 'pests',
                element: <Pests />,
            },
        ],
    },
    {
        path: '/crops-dashboard-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <CropDashboardManagement />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'crops-dashboard-crop-types',
                element: <CropTypesAggregation />,
            },
            {
                path: 'crops-dashboard/:id',
                element: <CropsDashboard />,
            },
        ],
    },
    {
        path: '/crop-types-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <CropTypesManagment />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'types',
                element: <CropTypes />,
            },
            {
                path: 'subtypes',
                element: <SubCropTypes />,
            },
            {
                path: 'subtypesRequests',
                element: <SubCropTypesRequests />,
            },
            {
                path: 'subtypesReviews',
                element: <SubCropTypesReviews />,
            },
        ],
    },
    {
        path: '/sub-crop-types-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <SubCropTypesManagement />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'subtypes',
                element: <SubCropTypes />,
            },
        ],
    },
    {
        path: '/pests-management',
        element: (
            <PrivateRoute>
                <DashboardLayout withPadding>
                    <PestsManagment />
                </DashboardLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: 'pests-dashboard',
                element: <Pests />,
            },
            {
                path: 'pests-reports-area',
                element: <PestsAggregator />,
            },
            {
                path: 'pests-reports',
                element: <PestsReports />,
            },
        ],
    },
    {
        path: '/*',
        element: <Navigate to="/dashboard" />,
    },
]);

function Routes() {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
