import { Outlet, NavLink } from 'react-router-dom';
import {
    Space,
} from 'antd';

import SeoMeta from 'Components/SeoMeta';

import './styles.scss';

const links = [
    {
        title: 'المستخدمين',
        path: 'users',
    },
    {
        title: 'الملفات',
        path: 'profiles',
    },
];

function UsersManagement() {
    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <div>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <nav className="Nav">
                        {links.map((entry) => (
                            <NavLink
                                key={entry.path}
                                to={`/users-management/${entry.path}`}
                                className={({ isActive }) => (isActive ? 'IsActive' : '')}
                            >
                                {entry.title}
                            </NavLink>
                        ))}
                    </nav>
                    <Outlet />
                </Space>
            </div>
        </>
    );
}

export default UsersManagement;
