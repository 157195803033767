import React from 'react';

import SeoMeta from 'Components/SeoMeta';

// import { AuthContext } from '../../shared/AuthProvider';
// import MyLands from '../../Components/MyLands';

function UsersManagement() {
    // const { user } = useContext(AuthContext);

    return (
        <>
            <SeoMeta title="اللوحة الرئيسية" />
            <div>
                sdfsd
            </div>
        </>
    );
}

export default UsersManagement;
