import {
    message, Space,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useContext } from 'react';

import { soilTypes } from '../../shared/constants';
import NumberField from '../FormFields/NumberField';
import { useUpdateSubCropType } from '../../apis/sub-crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import SelectField from '../FormFields/SelectField';
import CustomForm from '../CustomForm';

const schema = yup.object().shape({
});

export default function SoilForm({
    entry, onComplete, onClose,
}) {
    const { user } = useContext(AuthContext);
    const updateSubCropType = useUpdateSubCropType();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            soilType: entry?.soil?.soilType,
            phMin: entry?.soil?.phMin,
            phMax: entry?.soil?.phMax,
            TDsMin: entry?.soil?.TDsMin,
            TDsMax: entry?.soil?.TDsMax,
            heatMin: entry?.soil?.heatMin,
            heatMax: entry?.soil?.heatMax,
            hueMin: entry?.soil?.hueMin,
            hueMax: entry?.soil?.hueMax,
        },
    });

    const handleFinish = async (data) => {
        const soil = {
            soilType: data.soilType,
            phMin: data.phMin,
            phMax: data.phMax,
            TDsMin: data.TDsMin,
            TDsMax: data.TDsMax,
            heatMin: data.heatMin,
            heatMax: data.heatMax,
            hueMin: data.hueMin,
            hueMax: data.hueMax,
        };

        try {
            await updateSubCropType.mutateAsync({
                data: { soil },
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            console.log('Issue');
            if (onComplete) {
                onComplete(data);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    const layout = {
    };

    return (
        <CustomForm
            {...layout}
            onFinish={handleSubmit(handleFinish)}
            loading={updateSubCropType.isLoading}
            submitText="حفظ"
            reset={reset}
            fullForm
            editable
        >
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <NumberField
                    label="PH"
                    control={control}
                    errors={errors}
                    placeholder="Min amount"
                    fieldName="phMin"
                />
                <NumberField
                    label=""
                    control={control}
                    errors={errors}
                    placeholder="Max amount"
                    fieldName="phMax"
                />
            </Space>
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <NumberField
                    label="TDs"
                    control={control}
                    errors={errors}
                    placeholder="Min amount"
                    fieldName="TDsMin"
                />
                <NumberField
                    label=""
                    control={control}
                    errors={errors}
                    placeholder="Max amount"
                    fieldName="TDsMax"
                />
            </Space>
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <NumberField
                    label="حرارة"
                    control={control}
                    errors={errors}
                    placeholder="Min amount"
                    fieldName="heatMin"
                />
                <NumberField
                    label=""
                    control={control}
                    errors={errors}
                    placeholder="Max amount"
                    fieldName="heatMax"
                />
            </Space>
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <NumberField
                    label="رطوبة"
                    control={control}
                    errors={errors}
                    placeholder="Min amount"
                    fieldName="hueMin"
                />
                <NumberField
                    label=""
                    control={control}
                    errors={errors}
                    placeholder="Max amount"
                    fieldName="hueMax"
                />
            </Space>
            <SelectField
                label="نوع التربة"
                placeholder="اختر أنواع التربة"
                control={control}
                fieldName="soilType"
                errors={errors}
                mode="multiple"
                options={soilTypes}
            />
        </CustomForm>
    );
}
