const subscriptionStatus = {
    active: 'active',
    ended: 'ended',
};

const subscriptionStateColor = {
    [subscriptionStatus.active]: '#00CB7D',
    [subscriptionStatus.ended]: '#FD5E5E',
};

const subscriptionStateTitleColor = {
    [subscriptionStatus.active]: '#3E97FF',
    [subscriptionStatus.ended]: '#FD5E5E',
};

const subscriptionStateDateColor = {
    [subscriptionStatus.active]: '#3E97FF',
    [subscriptionStatus.ended]: '#FD5E5E',
};

const subscriptionStateTitleTxt = {
    [subscriptionStatus.active]: 'إشتراك فعال',
    [subscriptionStatus.ended]: 'إشتراك منتهي',
};

function SubscriptionWidget({ subscription }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                background: subscriptionStateColor[subscription.state],
                marginLeft: '6px',
            }}
            />
            <div>
                <div style={{
                    fontSize: '15px',
                    color: subscriptionStateTitleColor[subscription.state],
                }}
                >
                    {subscriptionStateTitleTxt[subscription.state]}
                </div>
                <div style={{
                    fontSize: '15px',
                    color: subscriptionStateDateColor[subscription.state],
                }}
                >
                    {subscription.expirationDate}
                </div>
            </div>
        </div>
    );
}

export default SubscriptionWidget;
