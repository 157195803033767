/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Button } from 'antd';

import WajedModal from 'Components/WajedModal';

import UserDashboard from '../UserDashboard';

export default function FullUserDrawer({
    onClose, isOpen, width, entry,
}) {
    const renderTitle = () => entry?.name;

    const renderSubTitle = () => entry?.name;
    const [subTitle, setSubTitle] = useState('');

    return (
        <WajedModal
            title={renderSubTitle()}
            subTitle={subTitle}
            width={width}
            onClose={onClose}
            isOpen={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
            scrollableContent={false}
            id="full-profile-drawer"
        >
            <UserDashboard profileId={entry?.id} profileEntry={entry} setSubTitle={setSubTitle} />
        </WajedModal>
    );
}
