import './styles.scss';
import { Space } from 'antd';
import { NavLink, Outlet } from 'react-router-dom';

const links = [
    {
        title: 'معلومات',
        path: 'information',
    },
    {
        title: 'الأصناف',
        path: 'آفات',
    },
    {
        title: 'الصور والروابط',
        path: 'links',
    },
];

function SubCropTypeManagement() {
    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <nav className="Nav">
                    {links.map((entry) => (
                        <NavLink
                            key={entry.path}
                            to={`/sub-crop-types-management/${entry.path}`}
                            className={({ isActive }) => (isActive ? 'IsActive' : '')}
                        >
                            {entry.title}
                        </NavLink>
                    ))}
                </nav>
                <Outlet />
            </Space>
        </div>
    );
}

export default SubCropTypeManagement;
