import React, { useMemo, useContext } from 'react';
import {
    Button, Form, message,
} from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { debounce } from 'lodash';

import { AuthContext } from '../../shared/AuthProvider';
import { useUpdateLand } from '../../apis/lands';
import NumberField from '../FormFields/NumberField';
// import AreaField from '../FormFields/AreaField';
import './styles.scss';
import SelectField from '../FormFields/SelectField';
// generated unique colors for greenhouse fields colors
const greenhouseColors = [
    '#6CCA64',
    '#66B2F2',
    '#FF9696',
    '#FFC000',
    '#FF99FF',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
    '#FF6666',
    '#FFCC66',
    '#FF99CC',
];

const schema = yup.object().shape({
    greenhouseActive: yup.boolean(),
    greenHouses: yup.array().of(
        yup.object().shape({
            area: yup.number().required('المساحة مطلوبة'),
            // unit: yup.string().required('الوحدة مطلوبة'),
        }),
    ),

});

function GreenhouseForm({
    // eslint-disable-next-line no-unused-vars
    pestUid, onComplete, onClose, entry,
}) {
    const { user } = useContext(AuthContext);
    const updateLand = useUpdateLand();
    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        // reset,
        setValue,
        getValues,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            greenhouseActive: entry?.greenhouseActive || false,
            greenHouses: entry?.greenHouses || [],
        },
    });

    const greenHouses = watch('greenHouses') || [];
    const isGreenHouseActive = watch('greenhouseActive');

    const handleFinish = async (data) => {
        try {
            if (entry) {
                console.log('payload::::', {
                    ...data,
                    landId: entry?.id,
                    userUid: user?.uid,
                });
                await updateLand.mutateAsync({
                    data,
                    landId: entry?.id,
                    userUid: user?.uid,
                });
            }
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
        } finally {
            // onComplete(data);
            if (onComplete) {
                onComplete();
            }
        }
    };

    const calculateRemainingArea = (allValues) => {
        const totalGreenHousesArea = allValues.reduce((acc, curr) => acc + curr.area, 0);
        return (entry?.area || 0) - totalGreenHousesArea;
    };

    const remainingAreaPercentage = useMemo(() => 100 * (((entry?.area ?? 0) - (greenHouses?.reduce((acc, curr) => acc + curr.area, 0) ?? 0)) / (entry?.area ?? 0)), [greenHouses]);

    const onAddGreenHouse = () => {
        const currentGreenHouse = getValues('greenHouses') || [];
        setValue('greenHouses', [...currentGreenHouse, { area: 1, dateCreated: new Date() }]);
    };

    const debouncedOnChangeArea = debounce((value, index) => {
        const oldValue = greenHouses[index].area;
        const newGreenHousesArray = [...greenHouses];
        newGreenHousesArray[index].area = value;
        const newRemainingArea = calculateRemainingArea(newGreenHousesArray);
        if (newRemainingArea < 0) {
            setValue(`greenHouses[${index}].area`, oldValue);
            return;
        }
        const currentGreenHouse = getValues('greenHouses') || [];
        currentGreenHouse[index].area = value;
        setValue('greenHouses', currentGreenHouse);
    }, 200);

    return (
        <div style={{
            display: 'flex', flexDirection: 'row', height: '100%', overflowY: 'scroll',
        }}
        >
            <div style={{}}>
                <Form
                    form={form}
                    // layout="vertical"
                    onFinish={handleSubmit(handleFinish)}
                    autocomplete="off"
                    layout={{
                        labelCol: { span: 8 },
                        wrapperCol: { span: 16 },

                    }}
                >
                    <Form.Item label="مساحة الأرض" colon={false}>
                        <span>
                            {' '}
                            {entry?.area}
                            {' '}
                            دونوم
                        </span>
                    </Form.Item>
                    <Form.Item label="يوجد دفيئاات" colon={false}>
                        <SelectField
                            control={control}
                            fieldName="greenhouseActive"
                            options={[
                                { value: true, label: 'نعم' },
                                { value: false, label: 'لا' },
                            ]}
                        />
                    </Form.Item>

                    {isGreenHouseActive && greenHouses.map((greenHouse, index) => (
                        <Form.Item key={`greenHouse-${greenHouse.dateCreated}`} label={`دفيئة رقم ${index}`} colon={false}>
                            <div className="fieldsContainer">
                                <NumberField
                                    control={control}
                                    // label="المساحة"
                                    placeholder="المساحة"
                                    fieldName={`greenHouses[${index}].area`}
                                    onChange={(value) => debouncedOnChangeArea(value, index)}
                                    errors={errors}
                                    disabled={remainingAreaPercentage <= 0}
                                />
                                <span className="greenhouseUnit">دونم</span>
                                <span style={{ backgroundColor: `${greenhouseColors[index]}` }} className="greenhouseColor" />
                                <Button
                                    type="text"
                                    className="deleteGreenhouseBtn"
                                    danger
                                    onClick={() => {
                                        const currentGreenHouse = getValues('greenHouses') || [];
                                        currentGreenHouse.splice(index, 1);
                                        setValue('greenHouses', currentGreenHouse);
                                    }}
                                >
                                    <DeleteFilled />
                                </Button>
                            </div>

                        </Form.Item>
                    ))}
                    {isGreenHouseActive && remainingAreaPercentage > 0 && (
                        <Form.Item label={' '} colon={false}>
                            <div className="fieldsContainer">

                                <Button type="" className="addGreenhouseBtn" style={{ width: '240px' }} onClick={onAddGreenHouse}>
                                    <PlusOutlined />
                                    <span className="addBtnLabel">اضافة محمية</span>

                                </Button>
                                {/* <span style={{ backgroundColor: `${greenhouseColors[greenHouses.length]}` }} className="greenhouseColor" /> */}

                            </div>
                        </Form.Item>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {entry?.id ? 'حفظ' : 'انشاء'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className="landsPresentation">

                <div className="fullLand">
                    {greenHouses.map((greenHouse, index) => (
                        <div
                            key={`greenHouse${greenHouse.dateCreated}`}
                            className="greenHouseItemPres"
                            style={{
                                width: `${(greenHouse.area / (entry?.area || 0)) * 100}%`, backgroundColor: `${greenhouseColors[index]}`, borderTopRightRadius: index === 0 ? 5 : 0, borderBottomRightRadius: index === 0 ? 5 : 0,
                            }}
                        >
                            <div className="greenHouseArea">
                                <span className="singleLangPercentage">
                                    {((greenHouse.area / (entry?.area || 0)) * 100).toFixed(0)}
                                    %
                                </span>
                            </div>
                            <div className="greenHouseColor" style={{ backgroundColor: `${greenhouseColors[index]}` }} />
                        </div>
                    ))}
                    <div className="remainingLandEmpty">
                        <div className="remainingAreaPercentage">
                            <span>
                                {remainingAreaPercentage.toFixed(0)}
                            </span>
                            <span>%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default GreenhouseForm;
