import {
    Avatar,
    Badge,
    Button, Dropdown, Progress, Space, Typography,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import SeoMeta from 'Components/SeoMeta';

import { plantFamily } from '../../shared/constants';
import FullCropsDetailsDrawer from '../FullCropsDetailDrawer';
import ListPlantedCrops from '../ListPlantedCrops';
import { useGetCropTypesForAggregator } from '../../apis/crop-types';
import FullCropTypesAggregationMgmt from '../FullCropTypesAggregationMgmt';
import DynamicTable from '../DynamicTable';

const _ = require('lodash');

function CropTypesAggregationMgmt() {
    // const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [selectedCropsList, setSelectedCropsList] = useState(null);
    const [entry, setEntry] = useState(null);
    // const { data: entities, isLoading, refetch: refetchEntities } = useGetCropTypesAggregation();
    const { data: entities, isLoading, refetch: refetchEntities } = useGetCropTypesForAggregator();
    const [isPlantedCropsDrawerOpen, setPlantedCropsDrawerOpen] = useState(false);

    const onCreate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
    };

    const handleOnClose = () => {
        setEntry(null);
        setIsDetailsOpen(false);
    };

    const handleOnOpen = (record) => {
        setEntry(record);
        setIsDetailsOpen(true);
    };

    useEffect(() => {
        if (entry) {
            setIsDetailsOpen(true);
        }
    }, [entry]);

    const handleShowCropsDetails = () => {
        setPlantedCropsDrawerOpen(true);
    };

    const closeCropsDetails = () => {
        setPlantedCropsDrawerOpen(false);
    };

    const handleSelectionChange = () => {
        setPlantedCropsDrawerOpen(false);
    };

    const handlePlantedAreasDetailsClicked = (data) => {
        setSelectedCropsList(data);
        handleShowCropsDetails();
    };

    // const goToCropsAggregator = (record) => {
    //     navigate(`/crops-dashboard-management/crops-dashboard/${record.id}`);
    // };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Badge size="default" offset={[50, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={50}
                            src={record.image}
                        />
                    </Badge>
                    <div style={{
                        fontSize: '15px', color: '#131313', fontWeight: 'bold', marginRight: '15px',
                    }}
                    >
                        <Typography.Text onClick={() => handleOnOpen(record)}>
                            {text}
                        </Typography.Text>
                    </div>
                </div>
            ),
        },
        {
            title: 'عائلة زرع',
            dataIndex: 'family',
            key: 'family',
            render: (family) => {
                const plant = _.find(plantFamily, { id: Number(family) });
                return plant ? plant.name : '';
            },
        },
        {
            title: 'مساحة مزروعة',
            dataIndex: 'plantingDetails',
            key: 'plantingDetails',
            render: (plantingDetails) => (
                plantingDetails && plantingDetails.length > 0
                    ? (
                        <span
                            role="button"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => handlePlantedAreasDetailsClicked(plantingDetails)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handlePlantedAreasDetailsClicked(plantingDetails);
                                }
                            }}
                            tabIndex={0}
                        >
                            {`${plantingDetails.reduce((sum, detail) => sum + (detail.cityDetails ? detail.cityDetails.plantedArea || 0 : 0), 0)} Tn`}
                        </span>
                    )
                    : 'N/A' // Or any default text you prefer when the area data is not available
            ),
        },
        {
            title: 'مائة للمحاصيل الأخرى',
            dataIndex: 'plantedPercentage',
            key: 'plantedPercentage',
        },
        {
            title: 'العائد المتوقع',
            key: 'estimatedYield',
            dataIndex: 'estimatedYield',
        },
        {
            title: 'نسبة فائدة البلد',
            key: 'countryPercent',
            dataIndex: 'countryPercent',
        },
        {
            title: 'عدد المزارعين',
            key: 'farmersCount',
            dataIndex: 'farmersCount',
        },
        {
            title: 'نسبة النمو',
            key: 'growthPercent',
            dataIndex: 'growthPercent',
            render: (growthPercent) => (
                <Progress size={[100, 15]} percent={growthPercent} />
            ),
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'عرض الملف',
                                onClick: () => {
                                    setEntry(record);
                                    setIsDrawerOpen(true);
                                },
                            },
                            {
                                key: '2',
                                label: 'حذف',
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <FullCropTypesAggregationMgmt
                width="calc(100%)"
                entry={entry}
                onClose={() => {
                    // refetchEntities();
                    setIsDetailsOpen(false);
                    setEntry(null);
                }}
                onComplete={() => {
                    // refetchEntities();
                    setIsDetailsOpen(false);
                    setEntry(null);
                }}
                isOpen={isDetailsOpen}
            />
            <FullCropsDetailsDrawer
                width="calc(100% - 200px)"
                entry={entry}
                onClose={() => handleOnClose()}
                isOpen={isDrawerOpen}
                onConfirm={onCreate}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <DynamicTable
                    searchable
                    searchableKeys={['name']}
                    columns={columns}
                    dataSource={entities}
                    loading={isLoading}
                    rowKey="id"
                />
            </Space>
            <ListPlantedCrops isOpen={isPlantedCropsDrawerOpen} onClose={closeCropsDetails} handleSelectionChange={handleSelectionChange} cropsList={selectedCropsList} />
        </>

    );
}

export default CropTypesAggregationMgmt;
