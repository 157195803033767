/* eslint-disable jsx-a11y/media-has-caption */
import React, {
    useEffect, useState, useContext,
} from 'react';
import { MoreOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Modal, message, Dropdown, Button, Popconfirm, Typography,
} from 'antd';
import { Timestamp } from 'firebase/firestore';
import * as dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import ReactPlayer from 'react-player';

import { useCreateMedia, useGetEntryMediaByType } from '../../../apis/media';
import { AuthContext } from '../../../shared/AuthProvider';
import DynamicTable from '../../DynamicTable';
import CreateResourceModal from '../../CreateResourceModal';

function VideosContainer({ entry, onUploadCompletedExtra, onComplete }) {
    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage] = useState('');
    // const [previewTitle] = useState('');
    const [uploadShown, setUploadShown] = useState(false);
    const queryClient = useQueryClient();
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const showModal = (url) => {
        setVideoUrl(url);
    };

    const handleOk = () => {
        setVideoUrl('');
        setShowVideoModal(false);
    };

    const handleCancel = () => {
        setVideoUrl('');
        setShowVideoModal(false);
    };

    const { data: fileListData, isLoading: isLoadingEntities, refetch } = useGetEntryMediaByType(null, entry?.id, 'video');

    const [fileList, setFileList] = useState([]);

    const createMedia = useCreateMedia();
    const { user } = useContext(AuthContext);

    const onUploadCompleted = async (url) => {
        try {
            if (entry && url) {
                const data = {
                    entryId: entry.id,
                    url,
                    name: 'Later',
                    status: 'uploaded',
                    type: 'video',
                    description: '',
                };

                const uploadedElement = await createMedia.mutateAsync({
                    data,
                    userUid: user?.uid,
                });

                console.table(uploadedElement);
                message.success('تم إنشاء الآفات بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            console.log('Done');
            if (onComplete) {
                onComplete(entry, url);
            }
        }
    };

    const onFileUploadedFull = async (data) => {
        await onUploadCompleted(data);
        await refetch();
        if (onUploadCompletedExtra) {
            onUploadCompletedExtra();
        }
        queryClient.invalidateQueries(['useGetEntryMediaByType']);
    };

    const handleChange = (info) => {
        console.log(info);
        setUploadShown(true);
    };

    useEffect(() => {
        if (entry) {
            console.table(entry);
            console.log('Media and entry available ');
        }
    }, [entry]);

    useEffect(() => {
        if (entry && fileListData && fileListData.length > 0) {
            setFileList(fileListData);
        }
    }, [fileListData]);

    const handleView = (record) => {
        if (record.url) showModal(record.url);
    };

    const handleEdit = (record) => {
        console.table(record);
    };

    const handleDelete = (record) => {
        console.table(record);
    };

    const handleLinkClick = (record) => {
        console.table(record);
    };

    const convertToDate = (timestamp) => {
        if (timestamp instanceof Timestamp) {
            const dateObject = timestamp.toDate();
            const formattedDate = dayjs(dateObject).format('YYYY-MM-DD');
            return formattedDate;
        }
        if (typeof timestamp === 'number') {
            // Assuming the number is in milliseconds
            const dateObject = new Date(timestamp * 1000);
            const formattedDate = dayjs(dateObject).format('YYYY-MM-DD');
            return formattedDate;
        }

        return null;
    };

    const columns = [
        {
            title: 'المصدر',
            dataIndex: 'url',
            render: (text, record) => (
                <Button type="link" onClick={() => handleView(record)} style={{ padding: 0 }}>
                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {/* just ot show the video thumbnail */}
                        <video
                            src={record.url}
                            style={{
                                width: 100,
                                height: 80,
                            }}
                        />
                    </div>
                </Button>

            ),
        },
        {
            title: 'معلومات الملف',
            dataIndex: 'name',
            render: (text, record) => (
                <div style={{ marginRight: '15px' }}>
                    <Typography.Link
                        style={{
                            marginRight: '5px', fontSize: '22px', color: '#131313', fontWeight: 'bold',
                        }}
                        onClick={() => handleLinkClick(record)}
                    >
                        {record.name}
                    </Typography.Link>
                    <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.description}</div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <Button type="danger" style={{ marginRight: '10px' }} onClick={() => handleEdit(record)}>
                            تعديل
                        </Button>
                        <span style={{ color: '#6E6893' }}>|</span>
                        <Button type="danger" style={{ marginRight: '10px' }} onClick={() => handleView(record)}>
                            عرض
                        </Button>
                        <span style={{ color: '#6E6893' }}> | </span>
                        <Popconfirm
                            title="هل أنت متأكد من حذف هذا العنصر؟"
                            onConfirm={() => handleDelete(record)}
                            okText="نعم"
                            cancelText="لا"
                        >
                            <Button type="danger">حذف</Button>
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
        {
            title: 'الناشر',
            dataIndex: 'publisher',
        },
        {
            title: 'تاريخ النشر',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <div>
                    {convertToDate(createdAt)}
                </div>
            ),
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            // eslint-disable-next-line no-unused-vars
            render: (value, record) => {
                const items = [
                    {
                        key: '1',
                        label: 'تعديل',
                        onClick: () => {
                        },
                    },
                    {
                        key: '2',
                        label: 'حذف',
                        onClick: async () => {
                            Modal.confirm({
                                title: 'هل أنت متأكد من الحذف؟',
                                okText: 'نعم',
                                okType: 'danger',
                                cancelText: 'لا',
                                onOk() {
                                },
                            });
                        },
                    },
                ];
                return (
                    <Dropdown menu={{ items }}>
                        <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    useEffect(() => {
        if (videoUrl) {
            setShowVideoModal(true);
        }
    }, [videoUrl]);

    return (
        <>
            <div id="tesging">
                {fileList !== null ? (
                    <DynamicTable
                        tableType="pro"
                        search={false}
                        columns={columns}
                        dataSource={fileList}
                        rowKey="id"
                        loading={isLoadingEntities}
                        toolBarRender={() => [
                            <Button icon={<UploadOutlined />} onClick={handleChange} type="primary">
                                رفع فيديو
                            </Button>,
                        ]}
                        light
                    />
                ) : (
                    <p>لا يوجد شيء.</p>
                )}
            </div>
            {/* <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal> */}

            <Modal
                title="عرض الفيديو"
                visible={showVideoModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null} // Remove default footer buttons
                style={{
                    width: 800,
                }}
            >
                <ReactPlayer
                    url={videoUrl}
                    controls
                    width="100%"
                    height="100%"
                    playing // Start playing the video as soon as it is ready
                />
            </Modal>

            <CreateResourceModal isShown={uploadShown} onClose={() => setUploadShown(false)} onCreated={async (data) => { onFileUploadedFull(data); }} />
        </>
    );
}
export default VideosContainer;
