import React, { useContext } from 'react';
import {
    Button, Divider, Form, message,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from '../FormFields/TextField';
import TextAreaField from '../FormFields/TextAreaField';
import { useCreateSubCropType, useUpdateSubCropType } from '../../apis/sub-crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import FlatImagesContainer from '../Media/FlatImagesContainer';
import AutoCompleteField from '../FormFields/AutoCompleteField';
import { ProfilesContext } from '../../shared/ProfilesProvider';
import { useCreateRequest } from '../../apis/requests';

const schema = yup.object().shape({
    name: yup.string().required('العائلة مطلوبة'),
    adminRemarks: yup.string().required('العائلة مطلوبة'),
    engRemarks: yup.string().required('العائلة مطلوبة'),
});

export default function CreateEditSubCropTypeRequestForm({
    request, entry, onComplete, onClose,
}) {
    const { user, isAdmin } = useContext(AuthContext);
    const updateSubCropType = useUpdateSubCropType();
    const createSubCropType = useCreateSubCropType();
    const createRequest = useCreateRequest();
    // const updateRequest = useUpdateRequest();
    const { engineersList } = useContext(ProfilesContext);

    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: request?.name,
            engRemarks: request?.engRemarks,
            selectedEngineer: request?.selectedEngineer,
            adminRemarks: request?.adminRemarks,
        },
    });

    const handleCreateInitialSubCropType = async (profileId, name) => {
        let createdSubCropType;
        try {
            const data = { name };
            // setValue('cropTypeName', name);
            createdSubCropType = await createSubCropType.mutateAsync({
                data,
                profileId,
                userUid: user?.uid,
            });

            return createdSubCropType;
        } catch (e) {
            message.error('Error!');
        }

        return null;
    };

    const handleFinish = async (data) => {
        // let updatedSubCropType;
        const newForm = { ...data };

        if (request) {
            console.log('Only validate');
        } else {
            const createdSubType = await handleCreateInitialSubCropType(user?.uid, newForm.name);
            if (createdSubType) {
                newForm.entityId = createdSubType?.id;
                newForm.status = 'new';
                newForm.type = 'subCropType';
                const createdRequest = await createRequest.mutateAsync({
                    data: newForm,
                    entityId: entry?.id,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء طلب النبات!');
                console.log('something');
                console.table(createdRequest);
            }
        }

        if (onComplete) {
            onComplete();
        }

        if (onClose) {
            onClose();
        }
    };

    const handleEngineerSelected = (value, record) => {
        if (record) {
            setValue('selectedEngineer', record.value);
            setValue('engineer', record.item);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit(handleFinish)} autocomplete="off">
            <Divider orientation="left" orientationMargin={50}>
                معلومات
            </Divider>
            <TextField
                label="اسم النبات"
                control={control}
                errors={errors}
                placeholder="اسم النبات"
                fieldName="name"
            />
            <TextAreaField
                label="ملاحظات للمهندس"
                control={control}
                errors={errors}
                placeholder="ملاحظات للمهندس"
                fieldName="engRemarks"
            />
            <Divider orientation="left" orientationMargin={50}>
                صور
            </Divider>
            <FlatImagesContainer />
            <Divider orientation="left" orientationMargin={50}>
                تعيين مهندس
            </Divider>
            <AutoCompleteField
                label="تعيين مهندس"
                control={control}
                errors={errors}
                options={engineersList}
                onSelect={handleEngineerSelected}
                placeholder="تعيين مهندس"
                fieldName="selectedEngineer"
            />
            <TextAreaField
                label="ملاحظات المدير"
                control={control}
                errors={errors}
                placeholder="ملاحظات المدير"
                fieldName="adminRemarks"
            />
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateSubCropType.isLoading}
                >
                    {isAdmin ? 'إنشاء كمسؤول' : 'إنشاء'}
                </Button>
            </Form.Item>
        </Form>
    );
}
