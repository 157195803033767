import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { firebaseFirestore, firebaseStorage } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetMedia = (profiles, entryId, offset = 0, queryLimit = 9999) => useQuery(['useGetImages', {
    profiles, entryId, queryLimit, offset,
}], async () => {
    const entries = [];

    const pestsRef = collection(firebaseFirestore, DbTableNames.media);
    const queryParams = [pestsRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    if (entryId) {
        queryParams.push(where('entryId', '==', entryId));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

/**
 * Should method for an entry by type.
 * @param profiles
 * @param entryId
 * @param type
 * @param offset
 * @param queryLimit
 * @returns {UseQueryResult<*[], unknown>}
 */
export const useGetEntryMediaByType = (profiles, entryId, type, offset = 0, queryLimit = 9999) => useQuery(['useGetEntryMediaByType', {
    profiles, entryId, type, queryLimit, offset,
}], async () => {
    const entries = [];

    const pestsRef = collection(firebaseFirestore, DbTableNames.media);
    const queryParams = [pestsRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    if (entryId) {
        queryParams.push(where('entryId', '==', entryId));
    }

    if (type) {
        queryParams.push(where('type', '==', type));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetMediaSummary = (profiles, entryId) => useQuery(['useGetMediaSummary', {
    profiles, entryId,
}], async () => {
    const entries = [];

    if (!entryId) {
        return null;
    }

    // Fetch the count of documents
    const countQueryParams = [collection(firebaseFirestore, DbTableNames.media)];

    if (profiles && profiles.length > 0) {
        countQueryParams.push(where('profiles', 'in', profiles));
    }

    if (entryId) {
        countQueryParams.push(where('entryId', '==', entryId));
    }

    const countQuery = query(...countQueryParams);
    const countQuerySnapshot = await getDocs(countQuery);
    const docCount = countQuerySnapshot.size;

    // Fetch the first document
    const firstQueryParams = [...countQueryParams, limit(1)];
    const firstQuery = query(...firstQueryParams);
    const firstQuerySnapshot = await getDocs(firstQuery);

    // Extract the data from the first document
    firstQuerySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return { docCount, firstEntry: entries[0] || null };
});

export const useGetMediaElement = (mediaId) => useQuery(
    ['useGetMediaElement', mediaId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.media, mediaId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!mediaId,
    },
);

export const useCreateMedia = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.media), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const uploadFile = async (file, onUploadProgress, onUploadCompleted, onUploadCompletedExtra) => {
    if (!file) {
        alert('Please upload an video first!');
    }

    const storageRef = ref(firebaseStorage, `/files/${file.name}`);
    console.table(storageRef);

    // Create a promise wrapper for upload task
    const uploadPromise = new Promise((resolve, reject) => {
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
        console.log('Reached here');

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const percentOfUpload = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                );

                // update progress
                if (onUploadProgress) {
                    onUploadProgress(percentOfUpload);
                }
            },
            (err) => {
                console.log(err);
                reject(err); // Reject the promise if an error occurs
            },
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    resolve(url); // Resolve the promise with the download URL
                });
            },
        );
    });

    try {
        // Wait for the upload to complete and get the download URL
        const downloadURL = await uploadPromise;

        // Handle the download URL as needed
        if (onUploadCompleted) {
            onUploadCompleted(downloadURL);
        }
        if (onUploadCompletedExtra) {
            onUploadCompletedExtra(downloadURL);
        }

        return downloadURL;
    } catch (error) {
        // Handle error if necessary
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const useCreateResource = () => useMutation({
    mutationFn: async ({ file, onUploadProgress }) => {
        const fileUploadResult = await uploadFile(file, (uploadProgress) => {
            if (onUploadProgress) {
                onUploadProgress(uploadProgress);
            }
        });
        return fileUploadResult;
    },
});
