import React, { useState, useEffect, useContext } from 'react';
import {
    Button, Col, message, Row,
} from 'antd';
import * as dayjs from 'dayjs';

import SeedCard from '../../../../Components/CreateCrop/SeedCard';
import FullLandPhasesDrawer from '../../../../Components/FullLandPhasesDrawer';
import { useGetSubCropType, useUpdateSubCropType } from '../../../../apis/sub-crop-types';
import { AuthContext } from '../../../../shared/AuthProvider';

function Phases({ entry }) {
    const [showAdditionWidget, setShowAdditionWidget] = useState(false);
    const [selectedEntry] = useState(null);
    const [renderPhases, setRenderPhases] = useState(entry?.phases ?? []);
    const updateSubCropType = useUpdateSubCropType();
    const { data: subCropTypeData, refetch } = useGetSubCropType(entry?.id);
    const { user } = useContext(AuthContext);
    const [phaseIndex, setPhaseIndex] = useState(0);

    useEffect(() => {
        if (subCropTypeData && subCropTypeData.phases && subCropTypeData.phases.length > 0) {
            setRenderPhases(subCropTypeData.phases);
        }
    }, [subCropTypeData]);

    useEffect(() => {
        if (entry && entry.phases && entry.phases.length > 0) {
            setRenderPhases(entry.phases);
        }
    }, [entry]);

    const handlePeriodOnClose = () => {
        setShowAdditionWidget(false);
    };

    const handlePeriodOnConfirm = async (data) => {
        const startDate = data.period?.[0] ? dayjs(data.period[0]).format('YYYY-MM-DD') : null;
        const endDate = data.period?.[1] ? dayjs(data.period[1]).format('YYYY-MM-DD') : null;

        let { phases } = entry;
        phases = phases || [];

        const phase = {
            index: phaseIndex,
            title: data.name,
            date: {
                start: startDate,
                end: endDate,
            },
            fertilizers: {
                name: 'another thing',
                amount: '1 liter',
            },
        };

        setPhaseIndex(phaseIndex + 1);

        if (renderPhases && renderPhases.length > 0) {
            phases.concat(renderPhases);
        }
        phases.push(phase);

        try {
            await updateSubCropType.mutateAsync({
                data: { phases },
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            refetch();
            console.log('Issue');
        }

        setShowAdditionWidget(false);
    };

    return (
        <div style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
        }}
        >
            <Row gutter={16}>
                {renderPhases.length > 0 && (
                    renderPhases.map((phase) => (
                        <Col span={8} key={phase.index}>
                            <SeedCard phase={phase} dateObject={phase.date} />
                        </Col>
                    ))
                )}
            </Row>

            <Button
                type="primary"
                onClick={() => setShowAdditionWidget(true)}
                style={{ marginTop: 16 }}
            >
                أضف مرحلة
            </Button>
            <FullLandPhasesDrawer
                entry={selectedEntry}
                isOpen={showAdditionWidget}
                onConfirm={handlePeriodOnConfirm}
                onClose={handlePeriodOnClose}
            />
        </div>
    );
}

export default Phases;
