import React, { useEffect, useContext, useState } from 'react';
import {
    Button, Form, message, Space, Spin, Avatar, List,
} from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as dayjs from 'dayjs';

import { useUpdateSubCropType, useGetSubCropType } from '../../apis/sub-crop-types';
import TextAreaField from '../FormFields/TextAreaField';
import { AuthContext } from '../../shared/AuthProvider';

const IconText = function IconText({ icon, text }) {
    return (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
};

function SubCropTypeReviewForm({
    entry, onComplete, onClose,
}) {
    const [remarks, setRemarks] = useState([]);

    const schema = yup.object().shape({
        family: yup.string().required('العائلة مطلوبة').label('العائلة'),
        minDays: yup.string().required('العائلة مطلوبة').label('العائلة'),
        maxDays: yup.string().required('العائلة مطلوبة').label('العائلة'),
    });
    const updateSubCropType = useUpdateSubCropType();
    const { user } = useContext(AuthContext);

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            family: entry?.family,
            features: entry?.features,
            minDays: entry?.minDays,
            maxDays: entry?.maxDays,
        },
    });

    useEffect(() => {
        if (entry) {
            const itemRemarks = entry.remakrs || []; // Make sure remarks is an array or set it to an empty array
            const data = Array.from({
                length: itemRemarks.length || 23, // Use the length of remarks array or default to 23
            }).map((_, i) => ({
                href: 'https://ant.design',
                title: `ant design part ${i}`,
                avatar: `https://api.dicebear.com/7.x/miniavs/svg?seed=${i}`,
                description:
                    'Ant Design, a design language for background applications, is refined by Ant UED Team.',
                content: itemRemarks[i] || '', // Use the remark at the current index or an empty string if undefined
            }));

            setRemarks(data);
        }
    }, [entry]);

    const handleFinish = async (formData) => {
        const allValues = getValues();
        const plantingDateFields = Object.keys(allValues).filter((key) => key.startsWith('plantingDate'));
        const seasonFields = Object.keys(allValues).filter((key) => key.startsWith('season'));

        seasonFields.map((key) => ({
            [key]: key,
        }));

        const seasonsArray = plantingDateFields.map((key) => {
            const startDate = allValues[key]?.[0] ? dayjs(allValues[key][0]).format('YYYY-MM-DD') : null;
            const endDate = allValues[key]?.[1] ? dayjs(allValues[key][1]).format('YYYY-MM-DD') : null;

            if (startDate && endDate) {
                return {
                    startDate,
                    endDate,
                };
            }
            return null;
        }).filter((season) => season !== null);
        const newForm = { ...formData };

        if (entry.seasons) {
            const updatedSeasonsArray = [...seasonsArray, ...entry.seasons];
            setValue('seasons', updatedSeasonsArray);
            newForm.seasons = updatedSeasonsArray;
        } else {
            setValue('seasons', seasonsArray);
            newForm.seasons = seasonsArray;
        }

        // Remove all plantingDate fields from the form
        plantingDateFields.forEach((field) => {
            setValue(field, null);
            newForm[field] = null;
        });

        seasonFields.forEach((field) => {
            setValue(field, null);
            newForm[field] = null;
        });

        try {
            await updateSubCropType.mutateAsync({
                data: newForm,
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            if (onComplete) {
                onComplete(formData);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    return (
        <div style={{
            overflow: 'auto',
            height: '100%',
        }}
        >
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 3,
                }}
                dataSource={remarks}
                footer={
                    (
                        <div>
                            <b>ant design</b>
                            footer part
                        </div>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                        actions={[
                            <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                            <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                            <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                        ]}
                        extra={
                            (
                                <img
                                    width={272}
                                    alt="logo"
                                    src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                />
                            )
                        }
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.avatar} />}
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.description}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
            );
            <Form layout="vertical" onFinish={handleSubmit(handleFinish)} autocomplete="off">
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <TextAreaField
                        label="remarks"
                        control={control}
                        errors={errors}
                        placeholder="remarks"
                        fieldName="remarks"
                    />
                </Space>
                <Form.Item>
                    <Space
                        direction="horizontal"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={updateSubCropType.isLoading}
                        >
                            {entry?.id ? 'حفظ المراجعة' : 'حفظ المراجعة'}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={updateSubCropType.isLoading}
                        >
                            نشر
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={updateSubCropType.isLoading}
                        >
                            رفض
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
        </div>
    );
}

export default function EditSubCropTypeReview({ entry, onComplete, subCropTypeId }) {
    const { data: subCropTypeData, isLoading } = useGetSubCropType(subCropTypeId);

    if (isLoading) {
        return (
            <Spin tip="يتم تحميل المعلومات" size="large">
                <div className="content" />
            </Spin>
        );
    }

    return (
        <SubCropTypeReviewForm entry={entry} onComplete={onComplete} subCropTypeData={subCropTypeData} />
    );
}
