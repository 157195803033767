import React, { useState, useEffect, useContext } from 'react';
import {
    Upload, Button, Space, Avatar, message,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { firebaseStorage } from '../../../shared/firebase.init';
import { useCreateMedia } from '../../../apis/media';
import { AuthContext } from '../../../shared/AuthProvider';

function UploadableAvatar({
    image, entry, onComplete,
}) {
    const [file, setFile] = useState(null);
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const createMedia = useCreateMedia();
    const { user } = useContext(AuthContext);

    const [uploadedImage, setUploadedImage] = useState();

    const onUploadCompleted = async (url) => {
        try {
            if (entry && url) {
                const data = {
                    entryId: entry.id,
                    url,
                    name: 'Later',
                    status: 'uploaded',
                    description: '',
                };

                await createMedia.mutateAsync({
                    data,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            console.log('Done');
            if (onComplete) {
                onComplete(entry, url);
            }
        }
    };

    const handleUpload = () => {
        if (!file) {
            alert('Please upload an image first!');
        }

        const storageRef = ref(firebaseStorage, `/files/${file.name}`);
        console.table(storageRef);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
        console.log('Reached here');

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const percentOfUpload = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                );

                console.log(percentOfUpload);
                // update progress
                setPercent(percentOfUpload);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setLoading(false);
                    setImageUrl(url);
                    console.log(url);
                    console.log(imageUrl);
                    console.log(percent);
                    setUploadedImage(url);
                    onUploadCompleted(url);
                });
            },
        );
    };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    useEffect(() => {
        if (image) {
            setUploadedImage(image);
        }
    }, [image]);

    const customRequest = ({ onSuccess }) => {
        // You can implement your own logic for image upload here
        // In this example, we simulate a successful upload after a delay
        setTimeout(() => {
            onSuccess();
        }, 1000);
    };

    const handleChange = (info) => {
        setFile(info.file.originFileObj);
    };

    return (
        <Space>
            <Upload
                fileList={file ? [file] : []} // Display the uploaded file
                customRequest={customRequest}
                onChange={handleChange}
                showUploadList={{ showDownloadIcon: false }}
            >
                <Avatar size={120} src={uploadedImage} />
                <div>&nbsp;</div>
                <Button icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>انقر للتحميل</Button>
            </Upload>
        </Space>
    );
}

export default UploadableAvatar;
