import React, { useState, useEffect } from 'react';
import {
    Space,
} from 'antd';

import SeoMeta from 'Components/SeoMeta';

function PestFightInfoMgmt({
    selectedPest,
}) {
    const [name, setName] = useState(selectedPest?.id);
    const [content, setContent] = useState(selectedPest?.id);

    useEffect(() => {
        if (selectedPest) {
            setName(selectedPest.name);
            setContent(selectedPest.content);
        }
    }, [selectedPest]);

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <div>
                    {name}
                </div>
                {content}
            </Space>
        </>

    );
}

export default PestFightInfoMgmt;
