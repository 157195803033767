import {
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetCropsAggregation = (profiles, cropTypes, offset = 0, queryLimit = 9999) => useQuery(['useGetCropsAggregation', {
    profiles, cropTypes, queryLimit, offset,
}], async () => {
    const entries = [];

    const cropsAggregatorRef = collection(firebaseFirestore, DbTableNames.cropsAggregator);
    const queryParams = [cropsAggregatorRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    console.table(cropTypes);
    if (cropTypes && cropTypes.length > 0) {
        queryParams.push(where('cropTypeId', 'in', cropTypes));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
}, {
    enabled: !!cropTypes,
});

export const useGetCropAggregation = (cropId) => useQuery(
    ['useGetCropAggregation', cropId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.cropsAggregator, cropId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!cropId,
    },
);

export const searchCropAggregation = (queryString) => {
    const results = [].filter((crop) => {
        // Case-insensitive search by family or name
        const familyMatch = crop.family.includes(queryString);
        const nameMatch = crop.name.includes(queryString);
        return familyMatch || nameMatch;
    });
    return results;
};
