import { Form, Select } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function SelectField({
    control, label, placeholder, fieldName, errors, options, mode = 'single', search, disabled, ...rest
}) {
    return (
        <Form.Item
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
            label={label}
        >
            <Controller
                name={fieldName}
                control={control}
                render={({ field }) => (
                    <Select
                        style={{ minWidth: '150px' }}
                        aria-label={placeholder}
                        mode={mode}
                        showSearch={search}
                        filterOption={search ? (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) : () => { }}
                        {...field}
                        options={options}
                        disabled={disabled}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        {...rest}
                    />
                )}
            />
        </Form.Item>
    );
}

export default SelectField;
