import React from 'react';
import {
    Avatar, Button, Drawer, List, Space, Spin,
} from 'antd';
import Search from 'antd/es/input/Search';

import { useGetSubCropTypes } from '../../apis/sub-crop-types';

const getSubCropTypesList = (entities, isLoading, handleSelectionChange) => (
    <div>
        {isLoading ? (
            // Render a loading indicator or message while data is being fetched
            <Spin size="large" />
        ) : (
            // Render the list when data is available
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={entities}
                footer={
                    (
                        <div>
                            <b>الرجاء تحديد نوع المحاصيل</b>
                        </div>
                    )
                }
                header={
                    (
                        <Space direction="vertical">
                            <Space direction="vertical">
                                <Search
                                    placeholder="input search text"
                                    allowClear
                                    style={{
                                        width: 200,
                                    }}
                                />
                            </Space>
                            <Space>
                                <div>
                                    <b>النبات</b>
                                </div>
                            </Space>
                        </Space>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        onClick={() => handleSelectionChange(item)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.image} />}
                            title={<a href={item.url}>{item.name}</a>}
                            description={item.description}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
        )}
    </div>
);

export default function ListSubCropTypes({
    onClose, isOpen, width, handleSelectionChange,
}) {
    const { data: entities, isLoading } = useGetSubCropTypes();

    return (
        <Drawer
            title="إدارة الأصناف/تحديد المحاصيل"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
        >
            {getSubCropTypesList(entities, isLoading, handleSelectionChange)}
        </Drawer>
    );
}
