import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
    Button,
    Form,
    Modal,
    Progress,
    Input,
} from 'antd';

import { useCreateResource } from '../../apis/media';
import StyledDropzone from '../StyledDropzone';

import './styles.scss';

function CreateResourceModal({
    isShown, onClose, onCreated,
}) {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const createResource = useCreateResource();
    const [fileAdded, setFileAdded] = useState(false);

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    };

    const handleCreateResource = async () => {
        setIsCreating(true);

        const data = await createResource.mutateAsync({
            file,
            name,
            onUploadProgress: (progressEvent) => {
                setUploadProgress(progressEvent);
            },
        });
        if (onCreated) {
            onCreated(data);
        }
        setIsCreating(false);
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (!isShown) {
            setName('');
            setFile(null);
            setIsCreating(false);
            setUploadProgress(0); // Reset upload progress when modal is closed
        }
    }, [isShown]);

    useEffect(() => {
        if (!isShown) {
            setName('');
            setFile(null);
            setIsCreating(false);
            setUploadProgress(0);
            setFileAdded(false);
        }
    }, [isShown]);

    return (
        <Modal
            visible={isShown}
            title="إنشاء مصدر جديد"
            onCancel={onClose}
            footer={[
                <Button key="create" type="primary" onClick={handleCreateResource} disabled={isEmpty(name) || isEmpty(file) || isCreating}>
                    {isCreating ? 'تحميل...' : 'ارفع الفيديو'}
                </Button>,
                <Button key="cancel" onClick={onClose}>
                    إلغاء
                </Button>,
            ]}
        >
            <Form
                {...{
                    labelCol: {
                        span: 5,
                    },
                    wrapperCol: {
                        span: 18,
                    },
                    labelAlign: 'left',
                }}
            >
                <Form.Item label="اسم المصدر">
                    <Input
                        label="اسم المصدر"
                        placeholder="المصدر"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </Form.Item>
                <Form.Item label="الفيديو">
                    <StyledDropzone accept={'video/mp4,video/x-m4v,video/*'} onDrop={onDrop} fileAdded={fileAdded} setFileAdded={setFileAdded} />
                </Form.Item>
            </Form>
            {uploadProgress ? (
                <div>
                    <Progress percent={uploadProgress} status="active" />
                </div>
            ) : null}
        </Modal>
    );
}

export default CreateResourceModal;
