import { ConfigProvider } from 'antd';
import { Helmet } from 'react-helmet';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import enUs from 'antd/locale/en_US';

import Routes from './Routes';
import { AuthProvider } from './shared/AuthProvider';
import { ProfilesProvider } from './shared/ProfilesProvider';
import SpecialModalProvider from './shared/SpecialModalProvider';
import './App.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            retry: 0,
        },
    },
});

const theme = {
    token: {
        fontSize: 16,
        colorPrimary: '#00C66B',
        fontFamily: "'Vazirmatn', sans-serif",
        colorSiderBg: '#131313',
        colorSiderText: '#9F8787',
    },
};

const customLocale = {
    ...enUs,
    Transfer: {
        ...enUs.Transfer,
        searchPlaceholder: 'ابحث هنا', // Your custom placeholder
    },
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider theme={theme} direction="rtl" locale={customLocale} componentSize="large">
                <SpecialModalProvider>
                    <AuthProvider>
                        <ProfilesProvider>
                            <Routes />
                        </ProfilesProvider>
                    </AuthProvider>
                    <Helmet
                        titleTemplate="عكارية - %s"
                        defaultTitle="الموقع"
                    />
                </SpecialModalProvider>
            </ConfigProvider>
        </QueryClientProvider>
    );
}

export default App;
