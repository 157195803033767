import {
    Form,
    Button,
    message,
} from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

import { useGetUser, useUpdateUser } from 'apis/account-settings';
import TextField from 'Components/FormFields/TextField';

const schema = yup.object().shape({
    name: yup.string().required('الاسم مطلوب'),
    mobile: yup.string().required('رقم الموبايل مطلوب'),
    email: yup.string().required('البريد الإلكتروني مطلوب').email('البريد الإلكتروني غير صالح'),
});

function BasicSettingsForm({ userUid }) {
    const { data } = useGetUser(userUid);
    const updateUser = useUpdateUser();

    const {
        control, handleSubmit, formState: { errors }, reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (formData) => {
        try {
            await updateUser.mutateAsync({
                data: formData,
                userId: userUid,
            });
            message.success('تم إرسال التعديل بنجاح!');
        } catch (e) {
            message.error('لقد حصل خطأ ما');
        }
    };

    useEffect(() => {
        if (data) {
            reset({
                name: data.name,
                mobile: data.mobile,
                email: data.email,
            });
        } else {
            reset({});
        }
    }, [data]);

    return (
        <Form
            style={{
                maxWidth: '450px',
            }}
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            autocomplete="off"
        >
            <TextField
                label="اسم"
                control={control}
                fieldName="name"
                errors={errors}
            />
            <TextField
                label="رقم الموبايل"
                control={control}
                fieldName="mobile"
                errors={errors}
            />
            <TextField
                label="البريد الإلكتروني"
                control={control}
                fieldName="email"
                errors={errors}
            />
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateUser.isLoading}
                >
                    تحديث المعلومات الشخصية
                </Button>
            </Form.Item>
        </Form>
    );
}

export default BasicSettingsForm;
