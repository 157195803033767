import {
    Button, Dropdown, message, Modal, Space, Select,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';

import SeoMeta from 'Components/SeoMeta';

import FullPestsDrawer from '../FullPestsDrawer';
import { useDeletePest, useGetPests } from '../../apis/pests';
import FullMediaDrawer from '../FullMediaDrawer';
import { PestsFamilies, cities } from '../../shared/constants';
import CreateEditPestsReportDrawer from '../CreateEditPestsReportDrawer';
import MediaSummary from '../Media/MediaSummary';
import { useGetSubCropTypesConditional } from '../../apis/sub-crop-types';
import DynamicTable from '../DynamicTable';

function PestsMgmt({
    cropTypeId,
}) {
    const { data: subCropTypesData, refetch } = useGetSubCropTypesConditional(null, cropTypeId);

    const [subCropTypes, setSubCropTypes] = useState([]);
    const [activeCityId, setActiveCityId] = useState(null);

    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetPests({ cropTypeId: null, subCropTypes, activeCityId });
    const deletePest = useDeletePest();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isIncidentReportOpen, setIsIncidentReportOpen] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);
    const [entry, setEntry] = useState(null);
    // const [searchText, setSearchText] = useState('');

    // Just in case we want to listen to those changes.
    useEffect(() => {
        if (subCropTypesData) {
            const idsOnly = subCropTypesData.map((subCropType) => subCropType.id);
            setSubCropTypes(idsOnly);
            refetchEntities();
        }
    }, [subCropTypesData]);

    useEffect(() => {
        if (cropTypeId) {
            refetch();
        }
    }, [cropTypeId]);

    const handleOnCreateOrUpdate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const handleOnClose = () => {
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const handleOnCloseReport = () => {
        setIsIncidentReportOpen(false);
    };

    const onMediaCreate = () => {
    };

    const doDeletePest = async (id) => {
        try {
            await deletePest.mutateAsync(id);
            message.success('تم الحذف');
            refetchEntities();
        } catch (err) {
            message.error('لقد حصل خطأ ما');
        }
    };

    const openRecord = (record) => {
        setEntry(record);
        setIsDrawerOpen(true);
    };

    const openMedia = (record) => {
        setEntry(record);
        setIsMediaOpen(true);
    };
    console.log('entities', entities);

    const columns = [
        {
            title: 'عائلة الآفات',
            dataIndex: 'family',
            key: 'family',
            render: (text) => {
                const foundElement = find(PestsFamilies, { value: text });
                return foundElement ? foundElement.label : null;
            },
        },
        {
            title: 'اسم الآفة',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Button
                    type="text"
                    onClick={() => openRecord(record)}
                >
                    {text}
                </Button>
            ),
        },
        {
            title: 'الاسم العلمي',
            dataIndex: 'scientificName',
            key: 'scientificName',
        },
        {
            title: 'صور',
            dataIndex: 'images',
            key: 'images',
            render: (text, record) => (
                <MediaSummary entry={record} />
            ),
        },
        {
            title: 'عدد البلاغات',
            dataIndex: 'incidentNumber',
            key: 'incidentNumber',
        },
        {
            title: 'خطر',
            dataIndex: 'dangerDegree',
            key: 'dangerDegree',
        },
        {
            title: 'انتشار',
            dataIndex: 'spread',
            key: 'spread',
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'عرض الملف',
                                onClick: () => {
                                    openRecord(record);
                                },
                            },
                            {
                                key: '2',
                                label: 'وسائط',
                                onClick: () => {
                                    openMedia(record);
                                },
                            },
                            {
                                key: '3',
                                label: 'حذف',
                                onClick: () => {
                                    Modal.confirm({
                                        title: 'هل أنت متأكد من الحذف؟',
                                        okText: 'نعم',
                                        okType: 'danger',
                                        cancelText: 'لا',
                                        onOk() {
                                            doDeletePest(record.id);
                                        },
                                    });
                                },
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    useEffect(() => {
        refetchEntities();
    }, [activeCityId]);

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        searchable
                        searchKeys={['name', 'scientificName']}
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                        toolBarRender={() => [
                            <Select
                                key="city-filter"
                                label="حسب المنطقة"
                                aria-label="حسب المنطقة"
                                showSearch
                                style={{ width: 200 }}
                                onChange={(value) => {
                                    setActiveCityId(value);
                                }}
                                defaultValue={{
                                    label: 'الكل',
                                    value: 'all',
                                }}
                                mode="single"
                                getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                <Select.Option key="all" value={null}>
                                    الكل
                                </Select.Option>
                                {cities.filter((item) => item.parent === null).map((city) => (
                                    <Select.Option key={city.id} value={city.id}>
                                        {city.arabic}
                                    </Select.Option>
                                ))}
                            </Select>,
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    setIsIncidentReportOpen(true);
                                }}
                                type="primary"
                                style={{ background: 'red', borderColor: 'red' }}
                            >
                                إضافة حادثة جديدة
                            </Button>,
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    console.log('Create new');
                                    // actionRef.current?.reload();
                                    setEntry(null);
                                    setIsDrawerOpen(true);
                                }}
                                type="primary"
                            >
                                إنشاء جديد
                            </Button>,
                        ]}
                    />
                </Space>
            </Space>
            <FullPestsDrawer
                width="calc(100%)"
                entry={entry}
                onClose={handleOnClose}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreateOrUpdate}
            />
            <CreateEditPestsReportDrawer
                width="calc(100%)"
                entry={entry}
                onClose={handleOnCloseReport}
                isOpen={isIncidentReportOpen}
                onConfirm={handleOnCreateOrUpdate}
            />
            <FullMediaDrawer
                width="calc(100% - 200px)"
                entry={entry}
                onClose={() => setIsMediaOpen(false)}
                isOpen={isMediaOpen}
                onConfirm={onMediaCreate}
            />
        </>

    );
}

export default PestsMgmt;
