import SeoMeta from 'Components/SeoMeta';

import SubCropTypeRequestsMgmt from '../../../Components/SubCropTypeRequestsMgmt';

function SubCropTypesRequests() {
    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <div>
                <SubCropTypeRequestsMgmt />
            </div>
        </>
    );
}

export default SubCropTypesRequests;
