import {
    message, Progress, Space,
} from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import EditProfile from 'Components/EditProfile';

import UploadableAvatar from '../Media/UploadableAvatar';
import { useUpdateProfile } from '../../apis/profiles';
import { AuthContext } from '../../shared/AuthProvider';

import './styles.scss';

function UserDashboard({ profileId, profileEntry, setSubTitle = () => null }) {
    const [selectedPage, setSelectedPage] = useState(<EditProfile profileId={profileId} />);
    const [selectedItem, setSelectedItem] = useState('BasicDetails');
    const updateProfile = useUpdateProfile();
    const { user } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const menu = [
        {
            key: 'BasicDetails',
            label: 'معلومات المستخدم',
            onClick: () => {
                setSelectedPage(<EditProfile profileId={profileId} />);
                setSelectedItem('BasicDetails');
            },
        },
    ];

    const onUploadCompleted = async (profileData, url) => {
        const newForm = {
            image: url,
        };

        if (profileData && profileId) {
            try {
                await updateProfile.mutateAsync({
                    data: newForm,
                    profileId,
                    userUid: user?.uid,
                });

                message.success('Saved image!');
            } catch {
                message.error('Error!');
            }
        }
        // profiles
        queryClient.invalidateQueries(['profiles']);
    };

    useEffect(() => {
        setSubTitle(menu[0].label);
    }, []);

    return (
        <div className="ProfileDashboard">
            <div className="Sidebar">
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div className="UserDetails">
                        <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                            <div className="Avatar">
                                <UploadableAvatar image={profileEntry?.image} entry={profileEntry} onComplete={onUploadCompleted} />
                            </div>
                            <div className="Name">{profileEntry?.name}</div>
                            <div className="Location">{profileEntry?.address?.city?.arabic}</div>
                            <div className="Email">{profileEntry?.email}</div>
                        </Space>
                    </div>
                    <div className="Menu">
                        <ul>
                            {
                                menu.map((entry) => (
                                    <li key={entry?.key}>
                                        <button
                                            onClick={() => {
                                                entry.onClick();
                                                setSubTitle(entry.label);
                                            }}
                                            type="button"
                                            className={selectedItem === entry.key ? 'selected' : ''}
                                        >
                                            {entry.label}
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="UserDetails">
                        <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                            <div className="Name">مستوى المعلومات</div>
                            <Progress percent={profileEntry.infoPercentage} />
                        </Space>
                    </div>
                </Space>
            </div>
            <div className="MainContent">
                {selectedPage}
            </div>
        </div>
    );
}

export default UserDashboard;
