import React, { useState } from 'react';
import {
    Dropdown, Button, Space, Modal, message, Typography, Progress, Badge, Avatar,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';

import { useGetLands, useDeleteLand } from '../../apis/lands';
import FullGreenhousesDrawer from '../FullGreenhousesDrawer';
import FullLandsDrawer from '../FullLandsDrawer';
// import FullCropsDrawer from '../FullCropsDrawer';
import DynamicTable from '../DynamicTable';

function LandsMgmt({
    profileEntry, profileType,
}) {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetLands(null, profileEntry, profileType);
    const deleteLand = useDeleteLand();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const [isCropDrawerOpen, setIsCropDrawerOpen] = useState(false);
    const [isGreenhouseOpen, setIsGreenhouseOpen] = useState(false);
    const [entry, setEntry] = useState(null);
    // const [selectedLand, setSelectedLand] = useState(null);

    const doDeleteLand = async (id) => {
        try {
            await deleteLand.mutateAsync(id);
            message.success('تم الحذف');
            refetchEntities();
        } catch (err) {
            message.error('لقد حصل خطأ ما');
        }
    };

    // const handleLandActions = (record) => {
    //     console.table(record);
    //     setSelectedLand(record);
    //     setIsCropDrawerOpen(true);
    // };

    // const onCropCreated = (data) => {
    //     console.table(data);
    // };

    // const handleOnClose = () => {
    //     setIsCropDrawerOpen(false);
    // };

    const openLandDetailsPage = (record) => {
        setEntry(record);
        setIsDrawerOpen(true);
    };

    const columns = [
        {
            title: 'اسم الارض/عنوان المنطقة',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ marginRight: '15px' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.image}
                        />
                    </Badge>
                    <Typography.Link
                        style={{
                            marginRight: '5px', fontSize: '18px', color: '#131313', fontWeight: 'bold',
                        }}
                        onClick={() => openLandDetailsPage(record)}
                    >
                        {record.name}
                    </Typography.Link>
                    <div style={{ fontSize: '16px', color: '#6E6893' }}>{record.city}</div>
                </div>
            ),
        },
        {
            title: 'الاسم',
            dataIndex: 'owner',
            key: 'owner',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.photoURL}
                        />
                    </Badge>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>
                            {record.owner?.name}
                        </div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.owner?.email}</div>
                    </div>
                </div>
            ),
        },
        // {
        //     title: 'محصول متوقع',
        //     key: 'expectedYield',
        //     dataIndex: 'expectedYield',
        //     render: (expectedYield) => {
        //         if (expectedYield) {
        //             return `${expectedYield.value} tn`;
        //         }
        //         return 'N/A'; // Or any default text you prefer when the area data is not available
        //     },
        // },
        // {
        //     title: 'نسبة النمو',
        //     key: 'growthPercent',
        //     dataIndex: 'growthPercent',
        //     render: (growthPercent) => (
        //         <div style={{ textAlign: 'center' }}>
        //             <span style={{ fontSize: '16px' }}>
        //                 {growthPercent}
        //             </span>
        //             <Progress size={[100, 15]} percent={growthPercent} />
        //         </div>
        //     ),
        // },
        {
            title: 'مساحة مزروعة',
            dataIndex: 'cultivatedAreaPercent',
            key: 'cultivatedAreaPercent',
            render: (cultivatedAreaPercent) => {
                if (cultivatedAreaPercent) {
                    const percent = Math.floor(cultivatedAreaPercent);
                    return <Progress type="circle" percent={percent} size={[50, 50]} />;
                }
                return <span style={{ color: 'red' }}>N/A</span>;
            },
        },
        {
            title: 'مساحة (دونم)',
            key: 'area',
            render: (text, record) => (
                <span>
                    {record.area}
                   &nbsp; دونم
                </span>
            ),
            // render: (text, record) => (
            //     <Button type="primary" onClick={() => handleLandActions(record)}>
            //         {record.area}
            //        &nbsp; دونم
            //     </Button>
            // ),
        },
        {
            title: 'الدفيئات',
            key: 'greenHouses',
            render: (record) => {
                const greenHouses = record?.greenHouses;
                return greenHouses && Array.isArray(greenHouses) && greenHouses.length > 0 ? (
                    <span>
                        [
                        {greenHouses.length}
                        ]
                    </span>
                ) : (
                    <span>لا توجد دفيئات</span>
                );
            },
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                setEntry(record);
                                setIsDrawerOpen(true);
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                            onClick: () => {
                                Modal.confirm({
                                    title: 'هل أنت متأكد من الحذف؟',
                                    okText: 'نعم',
                                    okType: 'danger',
                                    cancelText: 'لا',
                                    onOk() {
                                        doDeleteLand(record.id);
                                    },
                                });
                            },
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ].filter((column) => (profileEntry ? column.dataIndex !== 'owner' : true));

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <DynamicTable
                    tableType="pro"
                    search={false}
                    searchable
                    searchKeys={['name', 'city', 'selectedUser']}
                    columns={columns}
                    dataSource={entities}
                    rowKey="id"
                    loading={isLoadingEntities}
                    toolBarRender={() => [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                // actionRef.current?.reload();
                                setIsDrawerOpen(true);
                            }}
                            type="primary"
                        >
                            إنشاء أرض جديدة
                        </Button>,
                    ]}
                />
            </Space>
            <FullLandsDrawer
                width="calc(100%)"
                entry={entry}
                onClose={() => {
                    refetchEntities();
                    setIsDrawerOpen(false);
                    setEntry(null);
                }}
                onComplete={() => {
                    refetchEntities();
                    setIsDrawerOpen(false);
                    setEntry(null);
                }}
                isOpen={isDrawerOpen}
                profileEntry={profileEntry}
            />
            <FullGreenhousesDrawer
                width="calc(100% - 200px)"
                onClose={() => setIsGreenhouseOpen(false)}
                isOpen={isGreenhouseOpen}
            />

            {/* <FullCropsDrawer
                width="calc(100%)"
                entry={entry}
                selectedLand={selectedLand}
                onClose={() => handleOnClose()}
                isOpen={isCropDrawerOpen}
                onConfirm={onCropCreated}
            /> */}
        </>
    );
}

export default LandsMgmt;
