import React, { useState, useEffect } from 'react';
import {
    Table,
    Input,
    Space,
} from 'antd';
import { ProTable } from '@ant-design/pro-table';
import { debounce } from 'lodash';

const { Search } = Input;

// searchable only for client side search
function DynamicTable(props) {
    const {
        tableType, light = false, extraHeight = 0, searchable = false, searchKeys = ['name'],
    } = props;
    const tableContainerRef = React.createRef();
    const [filteredData, setFilteredData] = useState(props?.dataSource);

    const calculateExtraHeight = () => {
        if (tableType === 'pro') {
            return 180;
        }
        if (searchable) {
            return 120;
        }
        return 70;
    };

    useEffect(() => {
        const tableElement = tableContainerRef?.current;
        const setTableContainerHeight = () => {
            if (!tableElement) return;
            const tableContainer = tableElement.querySelector('.ant-table');
            const headerAndFooterHeight = calculateExtraHeight();
            if (light) {
                tableContainer.style.maxHeight = '70vh';
                return;
            }
            const viewportHeight = window.innerHeight;
            const tableContainerOffsetTop = tableElement.getBoundingClientRect().top;

            const availableSpace = viewportHeight - tableContainerOffsetTop;
            tableContainer.style.maxHeight = `${availableSpace - headerAndFooterHeight - extraHeight}px`;
            tableContainer.style.overflowY = 'auto';
        };

        setTableContainerHeight();

        window.addEventListener('load', setTableContainerHeight);
        window.addEventListener('resize', setTableContainerHeight);

        return () => {
            window.removeEventListener('load', setTableContainerHeight);
            window.removeEventListener('resize', setTableContainerHeight);
        };
    }, []);

    const debouncedFilter = debounce((value) => {
        const newData = props?.dataSource?.filter((item) => searchKeys.some((key) => item[key]?.toLowerCase().includes(value.toLowerCase())));
        setFilteredData(newData);
    }, 300);

    const handleSearch = (event) => {
        if (!event.target) return;
        const { value } = event.target;
        debouncedFilter(value);
    };

    const renderTable = () => {
        if (tableType === 'pro') {
            if (searchable) {
                return (
                    <ProTable
                        {...props}
                        dataSource={filteredData || props?.dataSource}
                        toolBarRender={() => [
                            <Search placeholder="ابحث" onSearch={handleSearch} onChange={handleSearch} style={{ width: 200 }} />,
                            ...(props?.toolBarRender() || []),
                        ]}
                    />
                );
            }
            return (
                <ProTable
                    {...props}
                    dataSource={props?.dataSource}
                />
            );
        }
        if (searchable) {
            return (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Search placeholder="ابحث" onSearch={handleSearch} onChange={handleSearch} style={{ width: 200 }} />
                    </div>
                    <Table
                        {...props}
                        dataSource={filteredData || props?.dataSource}
                    />

                </Space>

            );
        }
        return <Table {...props} />;
    };

    return (
        <div ref={tableContainerRef}>
            {renderTable()}
        </div>
    );
}

export default DynamicTable;
