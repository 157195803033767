import React, { useState } from 'react';
import {
    Card, DatePicker, Button, message, Typography, List,
} from 'antd';
import { DeleteOutlined, CalendarOutlined, EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

function SeedCard({ phase }) {
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const [items] = useState(['One ']);

    const showDatePicker = () => {
        setDatePickerVisible(true);
    };

    const handleDateChange = (date, dateString) => {
        // Handle date selection here
        message.info(`Selected date: ${dateString}`);
        setDatePickerVisible(false); // Close the DatePicker
    };

    const handleDelete = () => {
        // Handle delete action here
        message.info('Card deleted');
    };

    const handleEdit = () => {
        // Handle edit action here
        message.info('Edit clicked');
    };

    return (
        <Card
            title={phase?.title}
            style={{ width: 300, border: '1px solid #ccc', marginBottom: '20px' }}
            extra={(
                <>
                    <Button icon={<DeleteOutlined />} onClick={handleEdit} />
                    {' '}
&nbsp;
                    <Button icon={<EditOutlined />} onClick={handleDelete} />
                </>
            )}
            bordered={false}
        >
            <div>
                <DatePicker
                    visible={isDatePickerVisible}
                    onChange={handleDateChange}
                    style={{ display: 'none' }}
                />
                <Button icon={<CalendarOutlined />} onClick={showDatePicker} />
                {' '}
                &nbsp;
                <Text>
                    {phase?.date?.start}
                    {' '}
                    -
                    {phase?.date?.end}
                </Text>
            </div>

            {items.length > 0 && (
                <List
                    style={{ marginTop: '20px', height: '100%' }}
                    dataSource={items}
                    renderItem={(item, index) => (
                        <List.Item key={index}>{item}</List.Item>
                    )}
                />
            )}
        </Card>
    );
}

export default SeedCard;
