import { Form, List } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function ListField({
    control, label, placeholder, fieldName, errors, options, renderItem,
}) {
    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <div
                id="scrollableDiv"
                style={{
                    height: 160,
                    overflow: 'auto',
                    // padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <Controller
                    control={control}
                    name={fieldName}
                    render={({ field }) => (
                        <List
                            {...field}
                            placeholder={placeholder}
                            dataSource={options}
                            renderItem={renderItem}
                            bordered
                            // style={{ maxWidth: 300 }}
                        />
                    )}
                />
            </div>
        </Form.Item>
    );
}

export default ListField;
