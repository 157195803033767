import {
    Button, Drawer, Space, Typography,
} from 'antd';
import { useState } from 'react';

import SubCropTypeProfileDashboard from '../SubCropTypeProfileDashboard';
import CreateEditSubCropTypeForm from '../CreateEditSubCropTypeForm';
import WajedModal from '../WajedModal';

const { Text } = Typography;

export default function FullSubCropTypeDrawer({
    onClose, isOpen, width, entry, createFlag, onConfirm, cropType,
}) {
    const [subtitle, setSubtitle] = useState('');
    function getExtraWidgets() {
        return (
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                {/* Title on the right */}
                <div style={{ marginLeft: 'auto' }}>
                    <Text strong>عنوان الصفحة</Text>
                </div>
                {cropType ? (
                    <div>
                        <Text strong>{cropType.name}</Text>
                    </div>
                ) : (
                    <div>&nbsp;</div>
                )}
                <div>
                    <Button onClick={onClose}>إغلاق</Button>
                </div>
            </Space>
        );
    }

    return (
        <div>
            {createFlag ? (
                <Drawer
                    title="إدارة الأصناف"
                    width="calc(50% - 200px)"
                    onClose={onClose}
                    open={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={getExtraWidgets()}
                    destroyOnClose
                    placement="left"
                    closable={false}
                >
                    <CreateEditSubCropTypeForm entry={entry} onComplete={onConfirm} cropType={cropType} />
                </Drawer>
            ) : (
                <WajedModal
                    title="إدارة الأصناف"
                    subTitle={subtitle}
                    width={width}
                    onClose={onClose}
                    isOpen={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={getExtraWidgets()}
                    destroyOnClose
                    placement="left"
                    closable={false}
                    id="full-sub-crop-type-drawer"
                >
                    <SubCropTypeProfileDashboard entry={entry} setSubtitle={setSubtitle} />
                </WajedModal>
            )}
        </div>
    );
}
