import {
    Tabs,
} from 'antd';
import { useContext } from 'react';

import SeoMeta from 'Components/SeoMeta';
import { AuthContext } from 'shared/AuthProvider';

import BasicSettingsForm from './BasicSettingsForm';

function AccountSettings() {
    const { user } = useContext(AuthContext);

    const items = [
        {
            key: 'basic',
            label: 'الإعدادات الأساسية',
            children: <BasicSettingsForm userUid={user?.uid} />,
        },
        {
            key: 'password',
            label: 'كلمة المرور',
            children: 'Content of Tab Pane 2',
        },
    ];

    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <Tabs
                defaultActiveKey="1"
                items={items}
            />
        </>
    );
}

export default AccountSettings;
