import SeoMeta from 'Components/SeoMeta';

import PestsReportsAggregatorMgmt from '../../../Components/PestsReportsAggregatorMgmt';

function PestsAggregator() {
    return (
        <>
            <SeoMeta title="إدارة الآفات" />
            <PestsReportsAggregatorMgmt />
        </>

    );
}

export default PestsAggregator;
