import React, { useContext, useEffect, useState } from 'react';
import {
    Button, Form, Menu, message, Modal, Space, Spin,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import _ from 'lodash';

import TextField from 'Components/FormFields/TextField';
import SelectField from 'Components/FormFields/SelectField';
import DatePickerField from 'Components/FormFields/DatePickerField';
import TextAreaField from 'Components/FormFields/TextAreaField';
import {
    CompaniesFormParts,
    GenderValues, OrganizationTypes,
    ProfileTypes,
    Qualifications,
    countries,
} from 'shared/constants';
import { useGetProfile, useUpdateProfile } from 'apis/profiles';

import { AuthContext } from '../../shared/AuthProvider';
import CreateEditAddressForm from '../CreateEditAddressForm';
import CustomForm from '../CustomForm';
import './styles.scss';

function EditProfileForm({
    onComplete, profileData, profileId, schema, isCompany,
}) {
    const updateProfile = useUpdateProfile();
    const { user } = useContext(AuthContext);

    const defaultValues = {
        ...profileData,
        dateOfBirth: profileData.dateOfBirth ? profileData.dateOfBirth.toDate() : null,
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const handleFinish = async (formData) => {
        // const city = _.find(cities, { id: formData.city });
        // console.table(city);
        // const address = {
        //     id: city.id,
        //     city,
        // };
        // console.table(address);
        // setValue('address', address);
        try {
            await updateProfile.mutateAsync({
                data: formData,
                profileId,
                userUid: user?.uid,
            });

            message.success('Saved profile!');
        } catch {
            message.error('Error!');
            /* empty */
        } finally {
            onComplete?.();
        }
    };

    const [modalOpen, setModalOpen] = useState(false);

    const handleAddressSubmitted = async (address) => {
        setValue('address', address);

        if (profileData && profileId) {
            try {
                await updateProfile.mutateAsync({
                    data: { address },
                    profileId,
                    userUid: user?.uid,
                });

                message.success('Saved profile!');
            } catch {
                message.error('Error!');
                /* empty */
            } finally {
                setModalOpen(false);
            }
        } else {
            setModalOpen(false);
        }
    };

    const layout = {
        labelCol: {
            span: 3,
        },
        wrapperCol: {
            span: 16,
        },
        labelAlign: 'left',
    };

    return (
        <>
            <Modal
                title="عنوان"
                style={{
                    top: 20,
                }}
                open={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
            >
                <CreateEditAddressForm onSubmit={handleAddressSubmitted} entry={profileData?.address} />
            </Modal>
            <CustomForm onFinish={handleSubmit(handleFinish)} {...layout} reset={reset} fullForm editable>
                <TextField
                    label="اسم"
                    control={control}
                    placeholder="أدخل الاسم"
                    fieldName="name"
                    errors={errors}
                />
                <Form.Item label=" " colon={false}>
                    <Button onClick={() => setModalOpen(true)}>العنوان</Button>
                </Form.Item>
                <SelectField
                    label="نوع الملف"
                    control={control}
                    fieldName="profileType"
                    errors={errors}
                    options={ProfileTypes.filter((item) => item.showInForms).map((enty) => ({
                        key: enty.id,
                        value: enty.id,
                        label: enty.name,
                    }))}
                    disabled
                />
                <TextField
                    label="الأسم بالإنجليزي"
                    control={control}
                    placeholder="أدخل الاسم"
                    fieldName="englishName"
                    errors={errors}
                />
                {!isCompany && (
                    <div>
                        <DatePickerField
                            label="تاريخ الميلاد"
                            control={control}
                            fieldName="dateOfBirth"
                            errors={errors}
                        />
                        <SelectField
                            label="مؤهلات"
                            control={control}
                            fieldName="qualifications"
                            errors={errors}
                            mode="multiple"
                            options={Qualifications.map((qual) => ({
                                value: qual.value,
                                label: qual.label,
                            }))}
                        />
                        <SelectField
                            label="جنس"
                            control={control}
                            fieldName="gender"
                            errors={errors}
                            options={GenderValues.map((gender) => ({
                                value: gender.value,
                                label: gender.label,
                            }))}
                        />
                        <TextField
                            label="رقم الهوية"
                            control={control}
                            fieldName="idNumber"
                            errors={errors}
                        />
                        <TextField
                            label="بريد إلكتروني"
                            control={control}
                            fieldName="email"
                            errors={errors}
                        />
                        <TextField
                            label="رقم التليفون"
                            control={control}
                            fieldName="phone"
                            errors={errors}
                        />
                        <TextField
                            label="رقم واتس اب"
                            control={control}
                            fieldName="whatsapp"
                            errors={errors}
                        />
                        <TextAreaField
                            label="ملحوظات"
                            control={control}
                            fieldName="notes"
                            errors={errors}
                        />
                    </div>
                )}
                {isCompany && (
                    <div>
                        <TextField
                            label="مدير المؤسسة"
                            control={control}
                            fieldName="organizationCeo"
                            errors={errors}
                        />
                        <SelectField
                            label="نوع المؤسسة"
                            control={control}
                            fieldName="organizationType"
                            errors={errors}
                            options={OrganizationTypes.map((orgType) => ({
                                value: orgType.value,
                                label: orgType.label,
                            }))}
                        />
                        <DatePickerField
                            label="تاريخ الإنشاء"
                            control={control}
                            fieldName="establishmentDate"
                            errors={errors}
                        />
                        <TextAreaField
                            label="وصف المؤسسسة"
                            control={control}
                            fieldName="description"
                            errors={errors}
                        />
                        <TextField
                            label="المسجل المرخص"
                            control={control}
                            fieldName="registrationNumber"
                            errors={errors}
                        />
                        <SelectField
                            label="بلد المنشأ"
                            control={control}
                            fieldName="organizationCountry"
                            errors={errors}
                            options={countries.map((country) => ({
                                value: country,
                                label: country,
                            }))}
                        />
                    </div>
                )}
            </CustomForm>
        </>
    );
}

function EditContactInformationForm({ onComplete, profileData, profileId }) {
    const updateProfile = useUpdateProfile();
    const { user } = useContext(AuthContext);

    const contactSchema = yup.object().shape({
        contact: yup.string().required(),
        // address: yup.string().required(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(contactSchema),
        defaultValues: profileData?.contact,
    });

    function replaceUndefinedWithNull(obj) {
        const processedObj = {};
        Object.keys(obj).forEach((key) => {
            processedObj[key] = obj[key] === undefined ? null : obj[key];
        });
        return processedObj;
    }

    const handleFinish = async (formData) => {
        const contact = replaceUndefinedWithNull(formData);

        const newProfileData = {
            ...profileData,
            contact,
        };
        try {
            await updateProfile.mutateAsync({
                data: newProfileData,
                profileId,
                userUid: user?.uid,
            });

            message.success('Saved contact information!');
        } catch {
            message.error('Error!');
            /* empty */
        } finally {
            onComplete?.();
        }
    };

    return (
        <CustomForm onFinish={handleSubmit(handleFinish)} reset={reset} fullForm editable>
            <TextField
                label="جهات الاتصال"
                control={control}
                fieldName="contact"
                errors={errors}
            />
            <TextAreaField
                label="العنوان"
                control={control}
                placeholder="أدخل العنوان"
                fieldName="address"
                errors={errors}
            />
            <TextField
                label="بريد إلكتروني"
                control={control}
                fieldName="email"
                errors={errors}
            />
            <TextField
                label="رقم التليفون"
                control={control}
                fieldName="phone"
                errors={errors}
            />
            <TextField
                label="website"
                control={control}
                fieldName="website"
                errors={errors}
            />
            <TextField
                label="facebook"
                control={control}
                fieldName="facebook"
                errors={errors}
            />
            <TextAreaField
                label="ملاحظات"
                control={control}
                placeholder="أدخل ملاحظات"
                fieldName="remarks"
                errors={errors}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={updateProfile.isLoading}>
                    تعديل
                </Button>
            </Form.Item>
        </CustomForm>
    );
}

export default function EditProfile({ onComplete, profileId }) {
    const { data: profileData, isLoading } = useGetProfile(profileId);
    const [isCompany, setIsCompany] = useState(false);
    const [current, setCurrent] = useState(CompaniesFormParts[0].id);

    const organizationSchema = yup.object().shape({
        name: yup.string().required(),
        profileType: yup.string().required(),
        establishmentDate: yup.string().required(),
        englishName: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().matches(/^(02\d{7}|0029\d{9}|29(0|7|9)\d{8})$/, 'رقم الهاتف غير صحيح'),
        idNumber: yup.string().matches(/^\d{2}\s\d{7}\s\d$/, 'رقم الهوية غير صحيح'),
        organizationCeo: yup.string().required(),
        organizationType: yup.string().required(),
        organizationCountry: yup.string().required(),
        description: yup.string().required(),
        registrationNumber: yup.string().required(),
    });

    const personsSchema = yup.object().shape({
        name: yup.string().required(),
        profileType: yup.string().required(),
        englishName: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().matches(/^(02\d{7}|0029\d{9}|29(0|7|9)\d{8})$/, 'رقم الهاتف غير صحيح'),
        idNumber: yup.string().matches(/^\d{1}\s\d{7}\s\d$/, 'رقم الهوية غير صحيح'),
    });

    useEffect(() => {
        if (profileData) {
            // check the type.
            const { profileType } = profileData;
            if (profileType === 'agricultural_institutions' || profileType === 'companies') {
                setIsCompany(true);
            } else {
                setIsCompany(false);
            }
        }
    }, [profileData]);

    const getSchema = () => {
        if (isCompany) {
            return organizationSchema;
        }
        return personsSchema;
    };

    if (isCompany) {
        return (
            <div className="content-wrapper">
                <div>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Menu
                            onClick={(newKey) => { setCurrent(newKey.key); }}
                            selectedKeys={[current]}
                            mode="horizontal"
                            items={CompaniesFormParts.map((entry) => ({ label: entry.name, key: entry.id }))}
                        />
                    </Space>
                </div>
                <div className="form-wrapper">
                    {current === 'information' ? (
                        <EditProfileForm onComplete={onComplete} profileData={profileData} profileId={profileId} schema={getSchema()} isCompany={isCompany} />
                    ) : (
                        <EditContactInformationForm onComplete={onComplete} profileData={profileData} profileId={profileId} />
                    )}
                </div>
            </div>
        );
    }
    if (isLoading) {
        return (
            <Spin tip="يتم تحميل المعلومات" size="large">
                <div className="content" />
            </Spin>
        );
    }

    return (
        <EditProfileForm onComplete={onComplete} profileData={profileData} profileId={profileId} schema={getSchema()} isCompany={isCompany} />
    );
}
