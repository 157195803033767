import React, { useEffect } from 'react';
import {
    Progress,
    Space, Typography,
} from 'antd';

import SeoMeta from '../../SeoMeta';
import CropForm from '../../CreateEditCropForm';

import './style.scss';

export default function CropSteps({
    entry, onComplete, onClose, selectedLand,
}) {
    function getRenderWidget() {
        return <CropForm entry={entry} selectedLand={selectedLand} onClose={onClose} onComplete={onComplete} />;
    }

    // Just in case we want to listen to those changes.
    useEffect(() => {
        console.table(selectedLand);
    }, [selectedLand]);

    const getRemainingArea = () => {
        if (!selectedLand) {
            return 0;
        }
        const { area, cultivatedAreaPercent } = selectedLand;
        // Calculating the cultivated area in square units
        if (cultivatedAreaPercent) {
            const cultivatedArea = (cultivatedAreaPercent / 100) * area;

            // Calculating the remaining area after cultivation
            const remainingArea = area - cultivatedArea;
            return remainingArea;
        }
        return area;
    };

    return (
        <div style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>
            <SeoMeta title="إدراة المحاصيل" />
            <div style={{ padding: '20px', borderTop: '1px solid #3E97FF', marginBottom: '5px' }}>
                <div>
                    {/* Top Boundary */}
                    <div style={{ marginBottom: '50px' }} />

                    {/* Content */}
                    <Space
                        direction="horizontal"
                        className="CropStepsHeader"
                        size={90}
                    >
                        <Typography.Text strong style={{ color: 'blue' }}>
                            {selectedLand?.name}
                        </Typography.Text>
                        <Typography.Text strong style={{ color: 'green' }}>
                            {selectedLand?.city}
                        </Typography.Text>
                        <Typography.Text strong style={{ color: 'red' }}>
                            {`[ ${selectedLand?.area} ]`}
                            <span style={{ fontSize: '16px', color: '#555', paddingRight: '4px' }}>دونم</span>
                        </Typography.Text>
                        <div>
                            <Progress percent={selectedLand?.cultivatedAreaPercent || 0} size={[120, 20]} />
                        </div>
                        <Typography.Text strong style={{ color: 'purple' }}>
                            {`[ ${getRemainingArea()} ]`}
                            <span style={{ fontSize: '16px', color: '#555', paddingRight: '4px' }}>دونم</span>
                        </Typography.Text>
                        <div>
                            <Progress type="circle" percent={selectedLand?.cultivatedAreaPercent || 0} size="small" />
                        </div>
                    </Space>

                    {/* Bottom Boundary */}
                </div>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    {getRenderWidget()}
                </Space>
            </div>
        </div>
    );
}
