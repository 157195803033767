import React from 'react';
import {
    Button, Drawer, List, Progress, Space, Spin,
} from 'antd';

import { useGetLands } from '../../apis/lands';

// const data = Array.from({
//     length: 23,
// }).map((_, i) => ({
//     href: 'https://ant.design',
//     title: `ant design part ${i}`,
//     avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
//     description:
//         'Ant Design, a design language for background applications, is refined by Ant UED Team.',
//     content:
//         'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
// }));

const getProfileLandsList = (entities, isLoading, handleSelectionChange) => (
    <div>
        {isLoading ? (
            // Render a loading indicator or message while data is being fetched
            <Spin size="large" />
        ) : (
            // Render the list when data is available
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={entities}
                footer={
                    (
                        <div>
                            <b>يرجى اختيار الأرض</b>
                        </div>
                    )
                }
                header={
                    (
                        <div>
                            <Space direction="vertical">
                                <Space>
                                    <div>
                                        <b>الارض</b>
                                    </div>
                                    <div>
                                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                    </div>
                                    <div>
                                        <b>النسبة المئوية للحوكمة</b>
                                    </div>
                                </Space>
                            </Space>
                        </div>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        extra={
                            (
                                <Progress type="circle" percent={50} size="small" />
                            )
                        }
                        onClick={() => handleSelectionChange(item)}
                    >
                        <List.Item.Meta
                            title={<a href={item.url}>{item.name}</a>}
                            description={item.description}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
        )}
    </div>
);

export default function ListProfileLands({
    onClose, isOpen, width, handleSelectionChange, profileEntry,
}) {
    const { data: entities, isLoading } = useGetLands(null, profileEntry);

    const getProfileEntryLabel = () => {
        if (profileEntry) {
            return `اختيار الارض ل  ${profileEntry?.name}`;
        }
        return 'اختيار الارض'; // Handle the case where selectedLand is undefined or null
    };

    return (
        <Drawer
            title={getProfileEntryLabel()}
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
        >
            {getProfileLandsList(entities, isLoading, handleSelectionChange)}
        </Drawer>
    );
}
