import {
    message,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useContext } from 'react';

import { useUpdateSubCropType } from '../../apis/sub-crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import CustomForm from '../CustomForm';
import TextAreaField from '../FormFields/TextAreaField';

const schema = yup.object().shape({
});

export default function NotesForm({
    entry, onComplete, onClose,
}) {
    const { user } = useContext(AuthContext);
    const updateSubCropType = useUpdateSubCropType();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            notes: entry?.notes,
        },
    });

    const handleFinish = async (data) => {
        try {
            await updateSubCropType.mutateAsync({
                data: {
                    ...entry,
                    notes: data.notes,
                },
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            console.log('Issue');
            if (onComplete) {
                onComplete(data);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    const layout = {
    };

    return (
        <CustomForm
            {...layout}
            onFinish={handleSubmit(handleFinish)}
            loading={updateSubCropType.isLoading}
            submitText="حفظ"
            reset={reset}
            fullForm
            editable
        >
            <TextAreaField
                fieldName="notes"
                control={control}
                label="ملاحظات"
                error={errors.notes}
            />
        </CustomForm>
    );
}
