import { useMutation } from '@tanstack/react-query';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { firebaseAuth } from 'shared/firebase.init';

export const useSignInWithEmailAndPassword = () => useMutation({
    mutationFn: async (data) => signInWithEmailAndPassword(firebaseAuth, data.email, data.password),
});

export const signup = () => useMutation({
    mutationFn: async (data) => {
        signInWithEmailAndPassword(firebaseAuth, data.email, data.password);
    },
});
