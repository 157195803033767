import {
    Button,
    Drawer,
    Tabs,
} from 'antd';
import React from 'react';

import CreateEditWaterForm from '../CreateEditWaterForm';
import WajedModal from '../WajedModal';
import DrawerContent from '../DrawerContent';

export default function FullWaterSourceDrawer({
    entry, onClose, onSubmit, isOpen, width, land, normalForm = false,
}) {
    const onFormSubmit = (data) => {
        onSubmit?.(data);
    };

    const items = [
        {
            key: '1',
            label: 'معلومات مصدر المياه',
            children: <CreateEditWaterForm land={land} entry={entry} onSubmit={onFormSubmit} />,
        },
        {
            key: '2',
            label: 'المزارعين المنتفعين',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'المحاصيل',
            children: 'Content of Tab Pane 3',
        },
    ];

    return (
        <div>
            {normalForm ? (
                <Drawer
                    title={entry ? 'حفظ' : 'إنشاء'}
                    width={width}
                    onClose={onClose}
                    open={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={<Button onClick={onClose}>إغلاق</Button>}
                    destroyOnClose
                    placement="left"
                    closable={false}
                >
                    {
                        entry ? (
                            <Tabs
                                defaultActiveKey="1"
                                items={items}
                                indicatorSize={(origin) => origin - 16}
                            />
                        ) : (
                            <CreateEditWaterForm onSubmit={onFormSubmit} land={land} />
                        )
                    }
                </Drawer>
            ) : (
                <WajedModal
                    title={`${entry?.name || 'إنشاء'}`}
                    subTitle={null}
                    // width={width}
                    onClose={onClose}
                    open={isOpen}
                    isOpen={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={<Button onClick={onClose}>إغلاق</Button>}
                    destroyOnClose
                    placement="left"
                    closable={false}
                    id="full-lands-drawer"
                >
                    <DrawerContent
                        sideBarContent={null}
                        mainContent={<CreateEditWaterForm onSubmit={onFormSubmit} land={land} entry={entry} />}
                    />

                </WajedModal>
            )}
        </div>

    );
}
