import { Button, Form, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext } from 'react';

import TextField from 'Components/FormFields/TextField';
import SelectField from 'Components/FormFields/SelectField';
import { ProfileTypes } from 'shared/constants';
import { useCreateProfile } from 'apis/profiles';
import { AuthContext } from 'shared/AuthProvider';

export function CreateProfileForm({ onComplete, prefetchedType }) {
    const { user } = useContext(AuthContext);
    const createProfile = useCreateProfile();
    const schema = yup.object().shape({
        name: yup.string().required(),
        profileType: yup.string().required(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            profileType: prefetchedType,
        },
    });

    const handleFinish = async (formData) => {
        try {
            const createElement = await createProfile.mutateAsync({
                data: formData,
                userUid: user?.uid,
            });
            onComplete(createElement);
        } catch {
            /* empty */
        } finally {
            console.log('Something');
        }
    };

    return (
        <Form onFinish={handleSubmit(handleFinish)} layout="vertical" autocomplete="off">
            <TextField
                label="اسم الملف"
                control={control}
                placeholder="أدخل الاسم"
                fieldName="name"
                errors={errors}
            />
            <SelectField
                label="نوع الملف"
                control={control}
                fieldName="profileType"
                errors={errors}
                options={ProfileTypes.filter((item) => item.showInForms).map((enty) => ({
                    key: enty.id,
                    value: enty.id,
                    label: enty.name,
                }))}
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={createProfile.isLoading}>
                    انشاء
                </Button>
            </Form.Item>
        </Form>
    );
}

export default function CreateProfileDrawer({
    onComplete, onClose, isOpen, prefetchedType,
}) {
    return (
        <Drawer
            title="إنشاء ملف جديد"
            width={720}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إلغاء</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <CreateProfileForm
                onComplete={(createElement) => {
                    onClose();
                    onComplete(createElement);
                }}
                prefetchedType={prefetchedType}
            />
        </Drawer>
    );
}
