import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc, updateDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetSubCropTypes = (profiles, cropTypeId, status, offset = 0, queryLimit = 9999) => useQuery(['useGetCropTypes', {
    profiles, cropTypeId, status, queryLimit, offset,
}], async () => {
    const entries = [];

    const subCropTypesRef = collection(firebaseFirestore, DbTableNames.subCropTypes);
    const queryParams = [subCropTypesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (cropTypeId) {
        queryParams.push(where('cropTypeId', '==', cropTypeId));
    }

    if (status) {
        queryParams.push(where('status', 'in', status));
    } else {
        // If status is null, include both null and 'approved'
        queryParams.push(where('status', 'in', ['approved']));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });
    return entries;
});

export const useGetSubCropTypesConditional = (profiles, cropTypeId, offset = 0, queryLimit = 9999) => useQuery(['useGetSubCropTypesConditional', {
    profiles, cropTypeId, queryLimit, offset,
}], async () => {
    const entries = [];

    const subCropTypesRef = collection(firebaseFirestore, DbTableNames.subCropTypes);
    const queryParams = [subCropTypesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (cropTypeId) {
        queryParams.push(where('cropTypeId', '==', cropTypeId));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });
    return entries;
}, {
    enabled: !!cropTypeId,
});

export const useGetSubCropType = (subCropTypeId) => useQuery(
    ['useGetSubCropType', subCropTypeId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.subCropTypes, subCropTypeId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const dataWithId = {
                id: docSnap.id,
                ...docSnap.data(),
            };
            return dataWithId;
        }
        return null;
        // return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!subCropTypeId,
    },
);

export const useCreateSubCropType = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.subCropTypes), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdateSubCropType = () => useMutation({
    mutationFn: async ({ data, subCropTypeId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.subCropTypes, subCropTypeId), updateData);
        return {
            ...data,
        };
    },
});

export const searchSubCropTypes = (queryString) => {
    const results = [].filter((crop) => {
        // Case-insensitive search by family or name
        const familyMatch = crop.family.includes(queryString);
        const nameMatch = crop.name.includes(queryString);
        return familyMatch || nameMatch;
    });
    return results;
};

// import { useQuery } from '@tanstack/react-query';
//
// // We will replace this with the real db.
// const listDataSource = [
//     {
//         id: 1,
//         family: 'خضروات',
//         name: 'طماطم روماني',
//         cropTypeId: 1,
//         cropTypeName: 'طماطم',
//         image1: 'https://images-prod.healthline.com/hlcmsresource/images/AN_images/tomatoes-1296x728-feature.jpg',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjBR-K0CHaTOU9AHqcLVK5xO5JAqosrKldR5CFEM0Mxg&s',
//     },
//     {
//         id: 2,
//         family: 'خضروات',
//         name: 'طماطم شيري',
//         cropTypeId: 1,
//         cropTypeName: 'طماطم',
//         image1: 'https://images-prod.healthline.com/hlcmsresource/images/AN_images/tomatoes-1296x728-feature.jpg',
//         image: 'https://zfloos-production-storage.s3.eu-central-1.amazonaws.com/public/images/1692650224_64e3caf0f208d.jpg',
//     },
//     {
//         id: 3,
//         family: 'خضروات',
//         name: 'طماطم بندورة',
//         cropTypeId: 1,
//         cropTypeName: 'طماطم',
//         image1: 'https://images-prod.healthline.com/hlcmsresource/images/AN_images/tomatoes-1296x728-feature.jpg',
//         image: 'https://e7.pngegg.com/pngimages/408/202/png-clipart-tomato-tomato.png',
//     },
//     {
//         id: 4,
//         family: 'خضروات',
//         name: 'طماطم كيرف',
//         cropTypeId: 1,
//         cropTypeName: 'طماطم',
//         image1: 'https://images-prod.healthline.com/hlcmsresource/images/AN_images/tomatoes-1296x728-feature.jpg',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRplxbAO9WopPEviMunN8UmS7IUwuh0SxkAgmY1JMN_oA&s',
//     },
//     {
//         id: 5,
//         family: 'خضروات',
//         name: 'بطاطس الحلوة',
//         cropTypeId: 2,
//         cropTypeName: 'بطاطس',
//         image: 'https://sc02.alicdn.com/kf/UTB81E5pXlahduJk43Jaq6zM8FXaJ/Fresh-sweet-potato-red-white-potato-sweet.jpg',
//     },
//     {
//         id: 5,
//         family: 'خضروات',
//         name: 'بطاطس حمراء',
//         cropTypeId: 2,
//         cropTypeName: 'بطاطس',
//         image: 'https://watermark.lovepik.com/photo/20211208/large/lovepik-red-potatoes-picture_501582724.jpg',
//     },
//     {
//         id: 5,
//         family: 'خضروات',
//         name: 'بطاطس روسية',
//         cropTypeId: 2,
//         cropTypeName: 'بطاطس',
//         image: 'https://cdn1.img.sputnikarabic.ae/img/103833/12/1038331231_329:0:1592:1263_1280x0_80_0_0_4409f9d58461ac911dad12254b1f67b6.jpg',
//     },
//     {
//         id: 6,
//         family: 'فواكه',
//         name: 'تفاح أحمر',
//         cropTypeId: 3,
//         cropTypeName: 'تفاح',
//         image: 'https://png.pngtree.com/element_our/png/20181129/vector-illustration-of-fresh-red-apple-with-single-leaf-png_248312.jpg',
//     },
//     {
//         id: 6,
//         family: 'فواكه',
//         name: 'تفاح فوجي',
//         cropTypeId: 3,
//         cropTypeName: 'تفاح',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAqY-wwpIKIYBejw4owvg-o0n6LUkQ6TG6uyIQR3brGA&s',
//     },
//     {
//         id: 6,
//         family: 'فواكه',
//         name: 'تفاح رويال غال',
//         cropTypeId: 3,
//         cropTypeName: 'تفاح',
//         image: 'https://media.barakatfresh.ae/media/catalog/product/cache/991eeaa3837399618b43d1cd04e346f4/a/p/apple-royal-gala-1-kg-1kg.jpg',
//     },
//     {
//         id: 7,
//         family: 'حبوب',
//         name: 'قمح دقيق',
//         cropTypeId: 5,
//         cropTypeName: 'قمح',
//         image: 'https://cfn-catalog-prod.tradeling.com/up/60630bee0db4d0001d97123a/1fdad8ff859d07af314911c9287e6eca.jpg',
//     },
//     {
//         id: 7,
//         family: 'حبوب',
//         name: 'قمح صلب',
//         cropTypeId: 5,
//         cropTypeName: 'قمح',
//         image: 'https://sc02.alicdn.com/kf/Uad9ad9d568024a87ada46d7ff770732bd/Wholesale-organic-wheat-grain.jpg',
//     },
//     {
//         id: 7,
//         family: 'حبوب',
//         name: 'قمح فرنسي',
//         cropTypeId: 5,
//         cropTypeName: 'قمح',
//         image: 'https://alrai.com/uploads/images/2021/06/16/288248.jpg',
//     },
//     {
//         id: 8,
//         family: 'حبوب',
//         name: 'أرز طويل الحبة',
//         cropTypeId: 6,
//         cropTypeName: 'أرز',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFku95s4p2dd5kVfk49Dlym0zGWVSS0dQk8l11GLUb&s',
//     },
//     {
//         id: 8,
//         family: 'حبوب',
//         name: 'أرز أحمر',
//         cropTypeId: 6,
//         cropTypeName: 'أرز',
//         image: 'https://watermark.lovepik.com/photo/20211202/large/lovepik-red-rice-picture_501422916.jpg',
//     },
//     {
//         id: 9,
//         family: 'زهور',
//         name: 'زهرة وردية',
//         cropTypeId: 9,
//         cropTypeName: 'ورد',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-aIY0votXzAMihoIgRd1psTVu2vI9uqIPeRYC69_4vw&s',
//     },
//     {
//         id: 9,
//         family: 'زهور',
//         name: 'زهرة توليب',
//         cropTypeId: 9,
//         cropTypeName: 'ورد',
//         image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhKr6HoqJYlXA9Ozmc7aWP39xFRiENhStAKyMls20Y8Q&s',
//     },
//     {
//         id: 9,
//         family: 'زهور',
//         name: 'ورد',
//         cropTypeId: 9,
//         cropTypeName: 'ورد',
//         image: 'https://3.bp.blogspot.com/-PmhdGF0RJjM/VydFTT97bAI/AAAAAAAAUqM/9gIm46cNgKMSvJac25XAAXZkZuO0pA-BwCLcB/s1600/%25D8%25B5%25D9%2588%25D8%25B1%2B%25D9%2588%25D8%25B1%25D8%25AF_1.jpg',
//     },
// ];
//
// export function getCropType(id) {
//     return listDataSource.find((item) => item.id === id);
// }
//
// export function listSubCropTypes() {
//     return listDataSource;
// }
//
// export const searchSubCropTypes = (query) => {
//     const results = listDataSource.filter((crop) => {
//         // Case-insensitive search by family or name
//         const familyMatch = crop.family.includes(query);
//         const nameMatch = crop.name.includes(query);
//         return familyMatch || nameMatch;
//     });
//     return results;
// };
//
// export const listByCorpTypes = (cropTypeId) => useQuery([`cropTypes${cropTypeId}`], async () => listDataSource, {
//     enabled: true,
// });
