import React, { useContext } from 'react';
import {
    Button, Form, message, Space,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AuthContext } from '../../../shared/AuthProvider';
import { useCreatePest, useUpdatePest } from '../../../apis/pests';
import RichTextEditor from '../../RichTextEditor';

function ManagePestFightingForm({
    onComplete, entry, profileId,
}) {
    const { user } = useContext(AuthContext);
    const createPest = useCreatePest();
    const updatePest = useUpdatePest();

    const schema = yup.object().shape({
        content: yup
            .string()
            .required('الرجاء إدخال اسم الآفة'),
    });
    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            fightingWays: entry?.fightingWays || '',
        },
    });

    const handleFinish = async (data) => {
        try {
            if (!entry) {
                await createPest.mutateAsync({
                    data,
                    profileId,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            } else {
                await updatePest.mutateAsync({
                    data,
                    pestId: entry.id,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            onComplete(data);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit(handleFinish)}>
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <RichTextEditor
                    label="طرق مكافحة الآفات"
                    control={control}
                    placeholder="أدخل طرق مكافحة الآفات"
                    fieldName="fightingWays"
                    errors={errors}
                />
            </Space>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={createPest.isLoading || updatePest.isLoading}
                >
                    {entry?.id ? 'حفظ' : 'انشاء'}
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ManagePestFightingForm;
