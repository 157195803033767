import React from 'react';
import {
    Button, Form, Space,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PlusOutlined } from '@ant-design/icons';

import TextField from '../FormFields/TextField';
import DateRangeRegular from '../FormFields/DateRangeRegular';
import PropertyField from '../FormFields/PropertyField';

const schema = yup.object().shape({
    name: yup.string().required('اسم المرحلة مطلوب'),
});

function CreateEditLandPhaseForm({ selectedEntry, onClose, onConfirm }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: selectedEntry?.name,
        },
    });

    const handleAdd = (data) => {
        if (onConfirm) {
            onConfirm(data);
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Form layout="vertical" onFinish={handleSubmit(handleAdd)} autocomplete="off">
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <TextField
                    label="اسم المرحلة"
                    control={control}
                    fieldName="name"
                    errors={errors}
                />
                <DateRangeRegular
                    label="فترة"
                    control={control}
                    errors={errors}
                    fieldName="period"
                    placeholder="فترة"
                />
                <Form.List name="fertilizers" initialValue={['']}>
                    {(fields, { add, remove }) => (
                        <div>
                            {fields.map((field, index) => (
                                <PropertyField
                                    key={field.key}
                                    removeFunction={() => remove(field.name)}
                                    field={field}
                                    fieldName1={`fertName${index}`}
                                    errors={errors}
                                    control={control}
                                    fieldName2={`fertQuantity${index}`}
                                    label1="سماد"
                                    label2="كمية"
                                    placeholder1="اسم"
                                    placeholder2="كمية / باللتر"
                                />
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()} // Add a new input field
                                    icon={<PlusOutlined />}
                                >
                                    اضافة سماد
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" size="small" htmlType="submit">
                        إضافة
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
}

export default CreateEditLandPhaseForm;
