import { Outlet, NavLink } from 'react-router-dom';
import {
    Space,
} from 'antd';

import './styles.scss';

const links = [
    {
        title: 'الكل',
        path: 'lands',
    },
    {
        title: 'الأفراد',
        path: 'people-lands',
    },
    {
        title: 'التجار',
        path: 'merchants-lands',
    },
    {
        title: 'مؤسسات زراعية',
        path: 'agricultural_institutions-lands',
    },
    // {
    //     title: 'شركات',
    //     path: 'companies-lands',
    // },
];

function LandsManagement() {
    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <nav className="Nav">
                    {links.map((entry) => (
                        <NavLink
                            key={entry.path}
                            to={`/lands-management/${entry.path}`}
                            className={({ isActive }) => (isActive ? 'IsActive' : '')}
                        >
                            {entry.title}
                        </NavLink>
                    ))}
                </nav>
                <Outlet />
            </Space>
        </div>
    );
}

export default LandsManagement;
