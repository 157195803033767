import React, { useState } from 'react';
import { Tabs } from 'antd';

import ImagesContainer from './ImagesContainer';
import LinksContainer from './LinksContainer';
import VideosContainer from './VideosContainer';

const { TabPane } = Tabs;

function Media({ entry, onUploadCompleted }) {
    const [activeTab, setActiveTab] = useState('1');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div style={{
            overflow: 'auto',
            height: '100%',
        }}
        >
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="صور" key="1">
                    <ImagesContainer entry={entry} onUploadCompletedExtra={onUploadCompleted} />
                </TabPane>
                <TabPane tab="الروابط" key="2">
                    <LinksContainer entry={entry} />
                </TabPane>
                <TabPane tab="أشرطة فيديو" key="3">
                    <VideosContainer entry={entry} onUploadCompletedExtra={onUploadCompleted} />
                </TabPane>
            </Tabs>
        </div>

    );
}
export default Media;
