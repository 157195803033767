import { Helmet } from 'react-helmet';

function SeoMeta({ title }) {
    return (
        <Helmet
            title={title}
        />
    );
}

export default SeoMeta;
