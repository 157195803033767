import {
    message, Form, Space, Typography,
} from 'antd';
import React, { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext, useState, useEffect } from 'react';
// import Search from 'antd/es/input/Search';

import TextField from 'Components/FormFields/TextField';
import SelectField from 'Components/FormFields/SelectField';
import NumberField from 'Components/FormFields/NumberField';
import { cities, GovControlType, LandOwnerShipType } from 'shared/constants';

import { useCreateLand, useUpdateLand } from '../../apis/lands';
import { AuthContext } from '../../shared/AuthProvider';
import ListUsers from '../ListUsers';
import AutoCompleteField from '../FormFields/AutoCompleteField';
import { ProfilesContext } from '../../shared/ProfilesProvider';
// import AutoCompleteField from '../FormFields/AutoCompleteField';
import CustomForm from '../CustomForm';
import FilteredCities from '../FilteredCities';

const _ = require('lodash');

const controlAuthorityOptions = ['A', 'B', 'C'];
const surroundingStreetsOptions = ['شوارع أسفلتية', 'شوارع زراعية'];

export default function CreateEditLandBasicsForm({
    entry, onComplete, profileId, profileEntry, submitText,
}) {
    const { user } = useContext(AuthContext);
    const createLand = useCreateLand();
    const updateLand = useUpdateLand();
    const [isChooseUsersOpen, setIsChooseUsersOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const { profilesList } = useContext(ProfilesContext);

    const schema = yup.object().shape({
        name: yup.string().required('الرجاء إدخال الاسم').label('الاسم'),
        city: yup.string().required('الرجاء إدخال المدينة').label('المدينة'),
        governorate: yup.string().required('الرجاء إدخال المحافظة').label('المحافظة'),
        receptacle: yup.string().required('الرجاء إدخال اسم الحوض').label('الحوض'),
        landNumber: yup.string().required(),
        area: yup.number().required(),
        gps: yup.string().label('الإحداثيات GPS'),
    });

    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name,
            city: entry?.city,
            governorate: entry?.governorate,
            receptacle: entry?.receptacle,
            landNumber: entry?.landNumber,
            area: entry?.area,
            controlAuthority: entry?.controlAuthority,
            roads: entry?.roads,
            gps: entry?.gps,
            ownershiptType: entry?.ownershiptType,
            govControl: entry?.govControl,
            selectedUser: entry?.owner?.name,
        },
    });

    /**
     * Just trying to replace things that are undefined with
     * a null values
     * @param obj
     * @returns {*}
     */
    function replaceUndefinedWithNull(obj) {
        if (obj) {
            const processedObj = {};
            Object.keys(obj).forEach((key) => {
                processedObj[key] = obj[key] === undefined ? null : obj[key];
            });
            return processedObj;
        }
        return null;
    }

    // const onSearch = (value, _e, info) => console.log(info?.source, value);

    // This will go with adding a new land for a profile.
    useEffect(() => {
        if (profileEntry) {
            setSelectedUser(profileEntry?.name);
            setValue('selectedUser', profileEntry?.name);
            setValue('owner', replaceUndefinedWithNull(profileEntry));
            setValue('profileType', profileEntry.profileType);
        }
    }, [profileEntry]);

    const handleFinish = async (data) => {
        console.table(data);
        const totalCityObject = _.find(cities, { id: data.city });
        setValue('full-city', totalCityObject);
        console.log('Selected user');
        console.table(selectedUser);

        console.table(data);
        try {
            if (entry) {
                await updateLand.mutateAsync({
                    data,
                    landId: entry?.id,
                    userUid: user?.uid,
                });
            } else {
                await createLand.mutateAsync({
                    data,
                    profileId,
                    userUid: user?.uid,
                });
            }
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
        } finally {
            onComplete(data);
        }
    };

    // const handleShowUsers = () => {
    //     setIsChooseUsersOpen(true);
    // };

    const closeUserSelection = () => {
        setIsChooseUsersOpen(false);
    };

    const handleSelectionChange = (record) => {
        if (record) {
            console.table(record);
            setSelectedUser(record?.name);
            setValue('selectedUser', record.name);
            setValue('owner', record);
            setValue('profileType', record.profileType);
        }
        setIsChooseUsersOpen(false);
    };

    const handleOwnerChanged = (value, record) => {
        if (record) {
            setSelectedUser(record?.name);
            const { userObject } = record;
            setValue('selectedUser', userObject.name);
            setValue('owner', userObject);
            setValue('profileType', userObject.profileType);
        }
        setIsChooseUsersOpen(false);
    };

    const layout = {
        labelCol: {
            span: entry ? 4 : 8,
        },
        wrapperCol: {
            span: 16,
        },
        labelAlign: 'left',
    };

    return (
        <CustomForm
            form={form}
            id="landCreateForm"
            onFinish={handleSubmit(handleFinish)}
            reset={reset}
            loading={createLand.isLoading || updateLand.isLoading}
            {...layout}
            fullForm={Boolean(entry)}
            submitText={submitText}
            editable={Boolean(entry)}
        >
            {!entry ? (
                <>

                    <Form.Item label="اسم المزارع">
                        <Space direction="horizontal">
                            <AutoCompleteField
                            // label="اسم المزارع"
                                control={control}
                                errors={errors}
                                options={profilesList}
                                onSelect={handleOwnerChanged}
                                placeholder="اسم المزارع"
                                fieldName="selectedUser"
                            />
                        </Space>
                    </Form.Item>
                    <ListUsers isOpen={isChooseUsersOpen} onClose={closeUserSelection} handleSelectionChange={handleSelectionChange} />
                </>
            ) : (
                <Form.Item label="المالك:">
                    <Space direction="horizontal">
                        <Typography.Text>
                            {entry?.owner?.name}
                        </Typography.Text>
                    </Space>
                </Form.Item>
            )}

            <SelectField
                search
                label="نوع الملكية"
                control={control}
                fieldName="ownershiptType"
                errors={errors}
                options={LandOwnerShipType}
            />

            <TextField
                label="اسم الارض"
                placeholder="الرجاء إدخال اسم الأرض"
                control={control}
                fieldName="name"
                errors={errors}
            />
            <FilteredCities control={control} errors={errors} watch={watch} setValue={setValue} />
            <TextField
                label="رقم الحوض"
                placeholder="الرجاء إدخال رقم الحوض"
                control={control}
                fieldName="receptacle"
                errors={errors}
            />
            <TextField
                label="رقم قطعة الارض"
                control={control}
                fieldName="landNumber"
                errors={errors}
            />
            <NumberField
                label="مساحة القطعة بالدونم"
                control={control}
                fieldName="area"
                errors={errors}
            />
            <SelectField
                search
                label="تصنيف الأرض الحكومي"
                control={control}
                fieldName="govControl"
                errors={errors}
                options={GovControlType}
            />
            <SelectField
                search
                label="تصنيف الارض السياسي"
                control={control}
                fieldName="controlAuthority"
                errors={errors}
                options={controlAuthorityOptions.map((option) => ({
                    key: option,
                    value: option,
                    label: option,
                }))}
            />
            <SelectField
                search
                label="شوارع محيطة"
                control={control}
                fieldName="roads"
                errors={errors}
                mode="multiple"
                options={surroundingStreetsOptions.map((option) => ({
                    key: option,
                    value: option,
                    label: option,
                }))}
            />
            <TextField
                label="GPS"
                control={control}
                fieldName="gps"
                errors={errors}
            />
        </CustomForm>
    );
}
