import React, { useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
    NotificationOutlined, UserOutlined, MenuOutlined, SettingOutlined,
} from '@ant-design/icons';
import {
    Breadcrumb, Layout, Menu, theme, Avatar, Space, Button, Dropdown,
} from 'antd';

import { AuthContext } from 'shared/AuthProvider';

import './style.scss';

const { Header, Content, Sider } = Layout;

function DashboardLayout({ children, withBreadcrumb, withPadding }) {
    const {
        token: { colorSiderBg },
    } = theme.useToken();
    const { signOut, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState('');
    const menuItems = [
        {
            key: 'users',
            icon: React.createElement(UserOutlined),
            label: 'إدارة المستخدمين',
            children: [
                {
                    key: 'users-users',
                    label: 'المستخدمين',
                    onClick: () => {
                        navigate('/users-management/users');
                        setSelectedKey('users');
                    },
                },
                {
                    key: 'users-profiles',
                    label: 'الملفات',
                    onClick: () => {
                        navigate('/users-management/profiles');
                        setSelectedKey('users');
                    },
                },
            ],
        },
        {
            key: 'lands',
            icon: React.createElement(NotificationOutlined),
            label: 'إدارة الأراضي',
            children: [
                {
                    key: 'lands-lands',
                    label: 'الأراضي',
                    onClick: () => {
                        navigate('/lands-management/lands');
                        setSelectedKey('lands');
                    },
                },
            ],
        },
        // {
        //     key: 'crops',
        //     icon: React.createElement(NotificationOutlined),
        //     label: 'إدارة المحاصيل',
        //     children: [
        //         {
        //             key: 'crops-list',
        //             label: 'المحاصيل',
        //             onClick: () => {
        //                 navigate('/crops-management/crops');
        //             },
        //         },
        //         {
        //             key: 'pests-list',
        //             label: 'الآفات',
        //             onClick: () => {
        //                 navigate('/crops-management/pests');
        //             },
        //         },
        //     ],
        // },
        {
            key: 'crops-dashboard',
            icon: React.createElement(NotificationOutlined),
            label: 'إدارة المحاصيل',
            children: [
                {
                    key: 'crops-dashboard',
                    label: 'المحاصيل',
                    onClick: () => {
                        navigate('/crops-dashboard-management/crops-dashboard-crop-types');
                        setSelectedKey('crops-dashboard');
                    },
                },
            ],
        },
        {
            key: 'pests',
            icon: React.createElement(NotificationOutlined),
            label: 'الآفات الزراعية',
            children: [
                {
                    key: 'pests',
                    label: 'الآفات الزراعية',
                    onClick: () => {
                        navigate('/pests-management/pests-dashboard');
                        setSelectedKey('pests');
                    },
                },
            ],
        },
        {
            key: 'crop-types',
            icon: React.createElement(NotificationOutlined),
            label: 'المعجم الزراعي',
            children: [
                {
                    key: 'crop-types-list',
                    label: 'أنواع الأصناف',
                    onClick: () => {
                        navigate('/crop-types-management/types');
                        setSelectedKey('crop-types');
                    },
                },
                {
                    key: 'sub-crop-types-list',
                    label: 'الأصناف',
                    onClick: () => {
                        navigate('/crop-types-management/subtypes');
                        setSelectedKey('crop-types');
                    },
                },
                {
                    key: 'requests',
                    label: 'طلبات',
                    onClick: () => {
                        navigate('/crop-types-management/subtypesRequests');
                        setSelectedKey('crop-types');
                    },
                },
                {
                    key: 'engineers-requests',
                    label: 'طلبات المهندسين',
                    onClick: () => {
                        navigate('/crop-types-management/subtypesReviews');
                        setSelectedKey('crop-types');
                    },
                },
            ],
        },
        {
            key: 'water',
            icon: React.createElement(NotificationOutlined),
            label: 'إدارة المياه',
            children: [
                {
                    key: 'sources',
                    label: 'المياه',
                    onClick: () => {
                        navigate('/water-management/sources');
                        setSelectedKey('water');
                    },
                },
            ],
        },
    ];

    const pathKeyMap = {
        'users-management': 'users',
        'lands-management': 'lands',
        'crops-management': 'crops',
        'crops-dashboard-management': 'crops-dashboard',
        'pests-management': 'pests',
        'crop-types-management': 'crop-types',
        'water-management': 'water',
    };

    const [collapsed, setCollapsed] = useState(false);

    const items = [
        {
            key: 'account-settings',
            label: <Link to="/account-settings">الإعدادات</Link>,
        },
        {
            key: 'logout',
            label: 'تسجيل الخروج',
            onClick: () => { signOut(); },
        },
    ];

    const mainContentStyles = withPadding ? {
        padding: '15px',
    } : {};

    return (
        <Layout>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 6px #00000036',
                    zIndex: 1,
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ marginLeft: 'auto' }}>
                        <Space>
                            <Button
                                type="link"
                                icon={<MenuOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                            />
                            <Avatar shape="square" src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                            <div>{user?.displayName || user?.email}</div>
                        </Space>
                    </div>
                    <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <Button
                            type="link"
                            icon={<SettingOutlined />}
                        />
                    </Dropdown>
                </div>
            </Header>
            <Layout style={{
                background: '#F4F4F4',
                height: 'calc(100vh - 64px)' /* 64px is the header height */,
            }}
            >
                <Sider
                    width={200}
                    style={{
                        backgroundColor: colorSiderBg,
                    }}
                    // collapsible
                    collapsed={collapsed}
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[pathKeyMap[location.pathname.split('/')[1]]]}
                        defaultOpenKeys={[pathKeyMap[location.pathname.split('/')[1]]]}
                        className="SideBar"
                        items={menuItems.map((item) => ({
                            ...item,
                            className: 'sideBarMenuItem',
                            ok: selectedKey,
                        }))}
                        style={{
                            backgroundColor: colorSiderBg,
                        }}
                        // items={menuItems.map((item) => ({
                        //     // key: index + 1, // Or some other unique identifier
                        //     // label: item.label,
                        //     // icon: item.icon,
                        //     // path: item.path, // Assuming your items have a 'path' property
                        //     // children: item.children,
                        //     // onClick: item.onClick,
                        //     ...item,
                        //     selectedKeys: [pathKeyMap[location.pathname.split('/')[1]]],
                        // }))}
                        // theme="dark"
                    />
                </Sider>
                <Layout style={mainContentStyles}>
                    {
                        withBreadcrumb && (
                            <Breadcrumb
                                style={{
                                    margin: '16px 0',
                                }}
                            >
                                <Breadcrumb.Item>الرئيسية</Breadcrumb.Item>
                                <Breadcrumb.Item>المستخدمين</Breadcrumb.Item>
                            </Breadcrumb>
                        )
                    }
                    <Content
                        className="ContentWrapper"
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}
export default DashboardLayout;
