import { useState, useContext, useEffect } from 'react';
import { message, Space } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import SubCropTypeMgmt from '../SubCropTypeMgmt';
import PestsMgmt from '../PestsMgmt';
import Media from '../Media';
import UploadableAvatar from '../Media/UploadableAvatar';
import { useUpdateCropType } from '../../apis/crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import DrawerContent from '../DrawerContent';
import './styles.scss';

function CropTypeProfileDashboard({ cropTypeId, entry, setSubtitle = () => null }) {
    const queryClient = useQueryClient();

    const [selectedPage, setSelectedPage] = useState(
        <SubCropTypeMgmt cropTypeId={cropTypeId} cropType={entry} />,
    );
    const { user } = useContext(AuthContext);
    const updateCropType = useUpdateCropType();
    const [selectedItem, setSelectedItem] = useState('CropTypeBasicDetails');

    const menu = [
        {
            key: 'CropTypeBasicDetails',
            label: 'أصناف',
            onClick: () => {
                setSelectedPage(<SubCropTypeMgmt cropTypeId={cropTypeId} cropType={entry} />);
                setSelectedItem('CropTypeBasicDetails');
            },
        },
        {
            key: 'PestsInformation',
            label: 'آفات',
            onClick: () => {
                setSelectedPage(<PestsMgmt cropTypeId={cropTypeId} />);
                setSelectedItem('PestsInformation');
            },
        },
        {
            key: 'CropTypeImagesAndLinks',
            label: 'الصور والروابط',
            onClick: () => {
                setSelectedPage(<Media cropTypeId={cropTypeId} />);
                setSelectedItem('CropTypeImagesAndLinks');
            },
        },
    ];

    const onUploadCompleted = async (cropType, url) => {
        const newForm = {
            image: url,
        };

        try {
            await updateCropType.mutateAsync({
                data: newForm,
                cropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        }
        queryClient.invalidateQueries(['useGetCropTypes']);
    };

    useEffect(() => {
        setSubtitle(menu[0].label);
    }, []);

    return (
        <div className="CropTypeProfileDashboard">
            <DrawerContent
                sideBarContent={(
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div className="UserDetails">
                            <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                                <div className="Avatar">
                                    <UploadableAvatar
                                        image={entry?.image}
                                        entry={entry}
                                        onComplete={onUploadCompleted}
                                    />
                                </div>
                                <div className="Name">{entry?.name}</div>
                            </Space>
                        </div>
                        <div className="Menu">
                            <ul>
                                {menu.map((cropType) => (
                                    <li id={cropType.id}>
                                        <button
                                            onClick={() => {
                                                cropType.onClick();
                                                setSubtitle(cropType.label);
                                            }}
                                            type="button"
                                            className={
                                                selectedItem === cropType.key ? 'selected' : ''
                                            }
                                        >
                                            {cropType.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Space>
                )}
                mainContent={selectedPage}
            />
        </div>
    );
}

export default CropTypeProfileDashboard;
