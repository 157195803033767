import { UserOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import {
    Button, Drawer, List, Space, Spin,
} from 'antd';
// import Search from 'antd/es/input/Search';
// import { useGetProfiles } from '../../apis/profiles';

const IconText = function IconText({ icon: Icon, text }) {
    return (
        <Space>
            {Icon && React.createElement(Icon)}
            {text}
        </Space>
    );
};

const getCropsList = (entities, isLoading, handleSelectionChange) => (
    <div>
        {isLoading ? (
            // Render a loading indicator or message while data is being fetched
            <Spin size="large" />
        ) : (
            // Render the list when data is available
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={entities}
                footer={
                    (
                        <div>
                            <b>الرجاء تحديد نوع المحاصيل</b>
                        </div>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        actions={[
                            <IconText icon={UserOutlined} text={item.parent} key="list-vertical-like-o" />,
                        ]}
                        onClick={() => handleSelectionChange(item)}
                    >
                        {item.content}
                    </List.Item>
                )}
            />
        )}
    </div>
);

export default function ListPlantedCrops({
    onClose, isOpen, width, handleSelectionChange, cropsList,
}) {
    // const { data: profiles, isLoading } = useGetProfiles([], 0, 5);
    const mappedProfiles = useMemo(() => cropsList?.map((entry) => ({
        id: entry.cityDetails?.id,
        key: entry.cityDetails?.id,
        name: entry.cityDetails?.fullCity?.arabic,
        age: 32,
        address: 'البيرة - بيتين',
        subscription: {
            state: 'active',
            expirationDate: '01.02.2023',
        },
        parent: entry.cityDetails?.fullCity?.parent,
    })), [cropsList]);

    return (
        <Drawer
            title="إدارة الأراضي/اختيار مالك الأرض"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
        >
            {getCropsList(mappedProfiles, false, handleSelectionChange)}
        </Drawer>
    );
}
