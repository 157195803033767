import {
    Button, Space, Badge, Avatar, Dropdown, Modal, message,
} from 'antd';
import React, { useState } from 'react';
import { find } from 'lodash';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';

import SeoMeta from 'Components/SeoMeta';

import { useGetCropTypes } from '../../apis/crop-types';
import FullCropTypeDrawer from '../FullCropTypeDrawer';
import { sampleCropTypes } from '../../shared/constants';
import DynamicTable from '../DynamicTable';

function CropTypeMgmt() {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetCropTypes();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [entry, setEntry] = useState(null);
    const [createFlag, setCreateFlag] = useState(false);

    const handleOnCreate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
    };

    const openForCreate = () => {
        setCreateFlag(true);
        setIsDrawerOpen(true);
    };

    const openForEdit = () => {
        setCreateFlag(false);
        setIsDrawerOpen(true);
    };

    const doDeleteCropType = async (id) => {
        // try {
        //     await deleteLand.mutateAsync(id);
        message.success(id);
        message.success('تم الحذف');
        //     refetchEntities();
        // } catch (err) {
        //     message.error('لقد حصل خطأ ما');
        // }
    };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.image}
                        />
                    </Badge>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{text}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'العائلة',
            dataIndex: 'family',
            key: 'family',
            render: (text) => {
                const foundCropType = find(sampleCropTypes, { id: parseInt(text, 10) });
                return foundCropType ? foundCropType.name : null;
            },
        },
        {
            title: 'المساحة المزروعة',
            dataIndex: 'plantedArea',
            key: 'plantedArea',
            // render: (text) => {
            //     const foundCropType = find(sampleCropTypes, { id: parseInt(text, 10) });
            //     return foundCropType ? foundCropType.name : null;
            // },
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                setEntry(record);
                                openForEdit();
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                            onClick: () => {
                                Modal.confirm({
                                    title: 'هل أنت متأكد من الحذف؟',
                                    okText: 'نعم',
                                    okType: 'danger',
                                    cancelText: 'لا',
                                    onOk() {
                                        doDeleteCropType(record.id);
                                    },
                                });
                            },
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة الأصناف" />
            <FullCropTypeDrawer
                width="calc(100%)"
                entry={entry}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreate}
                onClose={() => setIsDrawerOpen(false)}
                createFlag={createFlag}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <DynamicTable
                    tableType="pro"
                    searchable
                    searchKeys={['name']}
                    search={false}
                    columns={columns}
                    dataSource={entities}
                    rowKey="id"
                    loading={isLoadingEntities}
                    toolBarRender={() => [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                // actionRef.current?.reload();
                                openForCreate();
                            }}
                            type="primary"
                        >
                            إنشاء جديد
                        </Button>,
                    ]}
                />
            </Space>
        </>

    );
}

export default CropTypeMgmt;
