import {
    Button, Dropdown, message, Modal, Space,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
// import { find } from 'lodash';

import SeoMeta from 'Components/SeoMeta';

import FullPestsDrawer from '../FullPestsDrawer';
import { useDeletePest } from '../../apis/pests';
import FullMediaDrawer from '../FullMediaDrawer';
import { useGetPestsReports } from '../../apis/pest-reports';
import DynamicTable from '../DynamicTable';

function PestsReportsMgmt() {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetPestsReports();
    const deletePest = useDeletePest();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);
    const [entry, setEntry] = useState(null);

    const handleOnCreateOrUpdate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const handleOnClose = () => {
        setIsDrawerOpen(false);
        setEntry(null);
    };

    const onMediaCreate = () => {
    };

    const doDeletePest = async (id) => {
        try {
            await deletePest.mutateAsync(id);
            message.success('تم الحذف');
            refetchEntities();
        } catch (err) {
            message.error('لقد حصل خطأ ما');
        }
    };

    const columns = [
        {
            title: 'اسم الآفة',
            dataIndex: 'farmerName',
            key: 'farmerName',
        },
        {
            title: 'اسم الآفة',
            dataIndex: 'pest',
            key: 'pest',
            render: (text) => (
                text.pestName
            ),
        },
        {
            title: 'اسم الآفة',
            dataIndex: 'land',
            key: 'land',
            render: (text) => (
                text.arabic
            ),
        },
        {
            title: 'انتشار',
            dataIndex: 'spread',
            key: 'spread',
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'حذف',
                                onClick: () => {
                                    Modal.confirm({
                                        title: 'هل أنت متأكد من الحذف؟',
                                        okText: 'نعم',
                                        okType: 'danger',
                                        cancelText: 'لا',
                                        onOk() {
                                            doDeletePest(record.id);
                                        },
                                    });
                                },
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                        toolBarRender={() => [
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    setIsDrawerOpen(true);
                                }}
                                type="primary"
                            >
                                إنشاء جديد
                            </Button>,
                        ]}
                    />
                </Space>
            </Space>
            <FullPestsDrawer
                width="calc(100%)"
                entry={entry}
                onClose={handleOnClose}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreateOrUpdate}
            />
            <FullMediaDrawer
                width="calc(100% - 200px)"
                entry={entry}
                onClose={() => setIsMediaOpen(false)}
                isOpen={isMediaOpen}
                onConfirm={onMediaCreate}
            />
        </>

    );
}

export default PestsReportsMgmt;
