// ProfilesContext.js
import React, { createContext, useMemo } from 'react';

import { useListEngineers, useListProfiles } from '../apis/profiles';

const ProfilesContext = createContext();

function ProfilesProvider({ children }) {
    const { data: profiles, isLoading: isAllProfilesLoading } = useListProfiles();
    const { data: people, isLoading: isPeopleLoading } = useListProfiles(['people'], 0);
    const { data: companies, isLoading: isCompaniesLoading } = useListProfiles(['companies'], 0);
    const { data: merchants, isLoading: isMerchantsLoading } = useListProfiles(['merchants'], 0);
    const { data: engineers, isLoading: isEngineersLoading } = useListEngineers(['merchants'], 0);

    const mapEntriesToOptions = (entries) => entries?.map((entry) => ({
        id: entry.id,
        key: entry.id,
        value: entry.name,
        label: entry.name,
        item: entry,
    }));

    const mapEntriesToOptionsUsers = (entries) => entries?.map((entry) => ({
        id: entry.id,
        key: entry.id,
        value: entry.displayName,
        label: entry.displayName,
        item: entry,
    }));

    const peopleList = useMemo(() => mapEntriesToOptions(people), [people]);
    const peopleListForFilters = useMemo(
        () => people?.map((entry) => ({
            key: entry.id,
            id: entry.id,
            label: entry.name,
            value: entry.id,
        })),
        [people],
    );

    const companiesList = useMemo(() => mapEntriesToOptions(companies), [companies]);
    const merchantsList = useMemo(() => mapEntriesToOptions(merchants), [merchants]);
    const engineersList = useMemo(() => mapEntriesToOptionsUsers(engineers), [engineers]);

    const renderProfile = (entry) => {
        let color;
        let text;
        switch (entry.profileType) {
        case 'people':
            color = 'blue';
            text = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        &nbsp;
                        <b><i>شخص</i></b>
                        &nbsp;
                    </div>
                    <div>{entry.name}</div>
                </div>
            );
            break;
        case 'companies':
            color = 'green';
            text = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        &nbsp;
                        <i>شركة</i>
                        &nbsp;
                    </div>
                    <div>{entry.name}</div>
                </div>
            );
            break;
        case 'merchants':
            color = 'orange';
            text = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        &nbsp;
                        <b><i>تاجر</i></b>
                        &nbsp;
                    </div>
                    <div>{entry.name}</div>
                </div>
            );
            break;
        default:
            color = 'black';
            text = entry.name;
        }

        return (
            <div key={entry.id} style={{ display: 'flex', justifyContent: 'space-between', color }}>
                {text}
            </div>
        );
    };

    const profilesList = useMemo(
        () => profiles?.map((entry) => ({
            key: entry.id,
            id: entry.id,
            label: renderProfile(entry),
            value: entry.name,
            userObject: entry,
        })),
        [profiles],
    );

    const value = useMemo(() => ({
        peopleList,
        profilesList,
        peopleListForFilters,
        companiesList,
        merchantsList,
        engineersList,
        isLoading: isPeopleLoading || isCompaniesLoading || isMerchantsLoading || isEngineersLoading || isAllProfilesLoading,
    }), [profilesList, peopleList, peopleListForFilters, companiesList, merchantsList, isPeopleLoading, isCompaniesLoading, isMerchantsLoading, isEngineersLoading, isAllProfilesLoading, engineersList]);

    return <ProfilesContext.Provider value={value}>{children}</ProfilesContext.Provider>;
}

export { ProfilesContext, ProfilesProvider };
