import React, {
    useState, createContext, useMemo, useContext,
} from 'react';

const SpecialModalContext = createContext({
    activeModals: {},
    activateModal: () => {},
    destroyModal: () => {},
});

export default function SpecialModalProvider({ children }) {
    const [activeModals, setActiveModals] = useState(0);

    const activateModal = (id) => {
        if (activeModals[id]) return;
        setActiveModals({
            ...activeModals,
            [id]: {
                level: Object.keys(activeModals).length + 1,
            },
        });
    };

    const destroyModal = (id) => {
        if (!activeModals[id]) return;
        const newActiveModals = { ...activeModals };
        delete newActiveModals[id];
        setActiveModals(newActiveModals);
    };

    const contextValue = useMemo(() => ({ activeModals, activateModal, destroyModal }), [activeModals, activateModal, destroyModal]);

    return (
        <SpecialModalContext.Provider value={contextValue}>
            {children}
        </SpecialModalContext.Provider>
    );
}

export const useSpecialModalContext = () => useContext(SpecialModalContext);
