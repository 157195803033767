import { useQuery, useMutation } from '@tanstack/react-query';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

// eslint-disable-next-line import/prefer-default-export
export const useGetUser = (userId) => useQuery(['users', userId], async () => {
    const docRef = doc(firebaseFirestore, DbTableNames.users, userId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
}, {
    enabled: !!userId,
});

export const useUpdateUser = () => useMutation({
    mutationFn: async ({
        data,
        userId,
    }) => {
        await setDoc(doc(firebaseFirestore, DbTableNames.users, userId), data);
        return {
            ...data,
        };
    },
});
