import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, limit, startAfter, getDocs, query, doc, getDoc, addDoc, updateDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetPestsReportsAggregator = (offset = 0, queryLimit = 9999) => useQuery(['useGetPestsReportsAggregator', {
    queryLimit, offset,
}], async () => {
    const entries = [];

    const pestsRef = collection(firebaseFirestore, DbTableNames.pestsReportsAggregator);
    const queryParams = [pestsRef];

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetPestReportAggregator = (pestReportId) => useQuery(
    ['useGetPestReportAggregator', pestReportId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.pestsReportsAggregator, pestReportId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!pestReportId,
    },
);

export const useCreatePestReportAggregator = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.pestsReportsAggregator), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdatePestReportAggregator = () => useMutation({
    mutationFn: async ({ data, aggId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.pestsReportsAggregator, aggId), updateData);
        return {
            ...data,
        };
    },
});
