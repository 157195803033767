import {
    message,
    Space,
} from 'antd';
import { useState, useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import EditSubCropTypeInfo from '../EditSubCropTypeInfo';
import SubCropTypePestsMgmt from '../SubCropTypePestsMgmt';
import Media from '../Media';
import SoilForm from '../SoilForm';
import Phases from '../../Pages/CropsManagement/Components/Phases';
import NotesForm from '../NotesForm';
import UploadableAvatar from '../Media/UploadableAvatar';
import EditSubCropTypeReview from '../EditSubCropTypeReview';
import { useUpdateSubCropType } from '../../apis/sub-crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import { ProfilesContext } from '../../shared/ProfilesProvider';
import DrawerContent from '../DrawerContent';
import './styles.scss';

function SubCropTypeProfileDashboard({ entry, setSubtitle = () => null }) {
    const queryClient = useQueryClient();
    const updateSubCropType = useUpdateSubCropType();
    const { user, isEngineer } = useContext(AuthContext);
    const { engineersList } = useContext(ProfilesContext);
    const [isReviewer, setIsReviewer] = useState(false);

    const onComplete = async (formData) => {
        console.table(formData);
        console.table(engineersList);
        queryClient.invalidateQueries(['useGetCropTypes']);
        queryClient.invalidateQueries(['useGetPests']);
    };

    useEffect(() => {
        const { request } = entry;
        if (request) {
            console.log('you are ');
            console.log(isEngineer);
            const { engineer } = request;
            if (engineer) {
                if (engineer.id === user.uid) {
                    console.log('we have got an owner');
                    setIsReviewer(true);
                } else {
                    setIsReviewer(true);
                }
            }
        }
    }, [entry]);

    const onUploadCompleted = async (subCropType, url) => {
        const newForm = {
            image: url,
        };

        try {
            await updateSubCropType.mutateAsync({
                data: newForm,
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        }
        queryClient.invalidateQueries(['useGetCropTypes']);
    };

    const [selectedPage, setSelectedPage] = useState(<EditSubCropTypeInfo entry={entry} subCropTypeId={entry?.id} onComplete={onComplete} />);
    const [selectedItem, setSelectedItem] = useState('CropTypeInformation');

    const menu = [
        {
            key: 'CropTypeInformation',
            label: 'معلومات',
            onClick: () => {
                setSelectedPage(<EditSubCropTypeInfo entry={entry} subCropTypeId={entry?.id} onComplete={onComplete} />);
                setSelectedItem('CropTypeInformation');
            },
        },
        {
            key: 'PestsInformation',
            label: 'آفات',
            onClick: () => {
                console.log('entry', entry);
                setSelectedPage(<SubCropTypePestsMgmt entry={entry} subCropTypeId={entry?.id} onComplete={onComplete} />);
                setSelectedItem('PestsInformation');
            },
        },
        {
            key: 'soil',
            label: 'التربة',
            onClick: () => {
                setSelectedPage(<SoilForm entry={entry} subCropTypeId={entry?.id} onComplete={onComplete} />);
                setSelectedItem('soil');
            },
        },
        {
            key: 'phases',
            label: 'المراحل',
            onClick: () => {
                setSelectedPage(<Phases entry={entry} subCropTypeId={entry?.id} onComplete={onComplete} />);
                setSelectedItem('phases');
            },
        },
        {
            key: 'CropTypeImagesAndLinks',
            label: 'الصور والروابط',
            onClick: () => {
                setSelectedPage(<Media entry={entry} subCropTypeId={entry?.id} />);
                setSelectedItem('CropTypeImagesAndLinks');
            },
        },
        ({
            key: 'reviewRequest',
            label: 'مراجعة',
            hidden: !isReviewer,
            onClick: () => {
                setSelectedPage(<EditSubCropTypeReview entry={entry} subCropTypeId={entry?.id} />);
                setSelectedItem('reviewRequest');
            },
        }),
        ({
            key: 'notes',
            label: 'ملاحظات',
            onClick: () => {
                setSelectedPage(<NotesForm entry={entry} subCropTypeId={entry?.id} />);
                setSelectedItem('notes');
            },
        }),
    ];

    useEffect(() => {
        setSubtitle(menu[0].label);
    }, []);

    return (
        <div className="SubCropTypeProfileDashboard">
            <DrawerContent
                sideBarContent={(
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div className={`UserDetails ${entry?.status !== 'approved' ? 'NotApproved' : ''}`}>
                            <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                                <div className="Avatar">
                                    <UploadableAvatar image={entry?.image} entry={entry} onComplete={onUploadCompleted} />
                                </div>
                                <div className="Name">{entry?.name}</div>
                                {entry?.status && <div className="Status">{entry?.status}</div>}
                            </Space>
                        </div>
                        <div className="Menu">
                            <ul>
                                {
                                    menu.map((item) => {
                                        if (item.hidden) {
                                            return null;
                                        }
                                        return (
                                            <li key={item.key}>
                                                <button
                                                    onClick={() => {
                                                        item.onClick();
                                                        setSubtitle(item.label);
                                                    }}
                                                    type="button"
                                                    className={selectedItem === item.key ? 'selected' : ''}
                                                >
                                                    {item.label}
                                                </button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </Space>
                )}
                mainContent={selectedPage}
            />
        </div>
    );
}

export default SubCropTypeProfileDashboard;
