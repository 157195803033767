import React, {
    useState, useEffect, useMemo,
} from 'react';
import {
    Transfer, message, Button, Space,
} from 'antd';

import { batchUpdatePests } from '../../apis/pests';

function PestsTransfer({
    entry, allPests, onComplete,
}) {
    // const { user } = useContext(AuthContext);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    // const updateSubCropType = useUpdateSubCropType();
    // const updatePest = batchUpdatePests();

    const loadedEntities = useMemo(() => {
        if (allPests) {
            const newData = allPests.map((entity) => ({
                key: entity.id,
                title: entity.name,
                description: entity.content,
            }));

            return newData;
        }

        return [];
    }, [allPests]);

    useEffect(() => {
        // Getting all of the subcroptypes here.
        if (!allPests) {
            return;
        }
        const newData = allPests.map((entity) => ({
            key: entity.id,
            title: entity.name,
            description: entity.content,
        }));

        const filteredData = allPests.filter((pest) => newData.some(() => pest.subCropTypesIds && pest.subCropTypesIds.includes(entry.id)));

        setTargetKeys(filteredData.map((pest) => pest.id));
    }, [entry, allPests]);

    const getFilteredPests = (selectedPestIds) => (allPests ? allPests.filter((pest) => selectedPestIds.includes(pest.id)) : []);

    const handlePestsChanged = async () => {
        const newArray = targetKeys.map((key1) => {
            const { key, title, description } = loadedEntities.find((item) => item.key === key1);
            return { content: description, id: key, name: title };
        });
        const pests = newArray;
        const pestIds = pests.map((pest) => pest.id);

        const filteredPests = getFilteredPests(pestIds);

        const updatedPests = filteredPests.map((pest) => {
            if (pest.subCropTypesIds && !pest.subCropTypesIds.includes(entry?.id)) {
                // If subCropTypesIds array exists and subCropTypeId is not already in it, add it
                return { ...pest, subCropTypesIds: [...pest.subCropTypesIds, entry?.id] };
            }

            if (!pest.subCropTypesIds) {
                // If subCropTypesIds array doesn't exist, create a new array with cropTypeId
                return { ...pest, subCropTypesIds: [entry?.id] };
            }
            return { ...pest };
        });

        const combinedData = pestIds.map((pestId) => {
            const matchingNewData = updatedPests.find((data) => data.id === pestId);
            return { pestId, newData: matchingNewData };
        });

        try {
            // await updateSubCropType.mutateAsync({
            //     data: { subCropTypesIds },
            //     subCropTypeId: entry?.id,
            //     userUid: user?.uid,
            // });
            // await updatePest.mutate({
            //     pestIds,
            //     newData: updatedPests,
            //     userUid: user?.uid,
            // });

            await batchUpdatePests(combinedData);

            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            console.log('Issue');
            if (onComplete) {
                onComplete();
            }
        }
    };

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        console.log('targetKeys:', nextTargetKeys);
        console.log('direction:', direction);
        console.log('moveKeys:', moveKeys);
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        console.log('sourceSelectedKeys:', sourceSelectedKeys);
        console.log('targetSelectedKeys:', targetSelectedKeys);
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    const onScroll = (direction, e) => {
        console.log('direction:', direction);
        console.log('target:', e.target);
    };
    return (
        <div>
            <Space
                direction="horizontal"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Transfer
                    dataSource={loadedEntities}
                    titles={['Source', 'Target']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    onScroll={onScroll}
                    render={(item) => item.title}
                    rowKey={(item) => item.key}
                    showSearch
                    listStyle={{
                        width: '300px',
                        height: '300px',
                    }}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handlePestsChanged}
                    // loading={updatePest.isLoading}
                >
                    تعديل
                </Button>
            </Space>
        </div>
    );
}

export default PestsTransfer;
