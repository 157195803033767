import React from 'react';
import {
    Avatar, Button, Drawer, List, Spin,
} from 'antd';

import { useGetWaterSources } from '../../apis/water-sources';

// const data = Array.from({
//     length: 23,
// }).map((_, i) => ({
//     href: 'https://ant.design',
//     title: `ant design part ${i}`,
//     avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
//     description:
//         'Ant Design, a design language for background applications, is refined by Ant UED Team.',
//     content:
//         'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
// }));

const getWaterSourcesList = (entities, isLoading, handleSelectionChange) => (
    <div>
        {isLoading ? (
            // Render a loading indicator or message while data is being fetched
            <Spin size="large" />
        ) : (
            // Render the list when data is available
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={entities}
                footer={
                    (
                        <div>
                            <b>الرجاء تحديد نوع المحاصيل</b>
                        </div>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        // actions={[
                        //     <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                        //     <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        // ]}
                        onClick={() => handleSelectionChange(item)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.image} />}
                            title={<a href={item.url}>{item.name}</a>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        )}
    </div>
);

export default function ListWaterSources({
    onClose, isOpen, width, handleSelectionChange,
}) {
    const {
        data: entities,
        isLoading,
    } = useGetWaterSources(null, null);
    return (
        <Drawer
            title="إدارة الأصناف/تحديد نوع المحاصيل"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
        >
            {getWaterSourcesList(entities, isLoading, handleSelectionChange)}
        </Drawer>
    );
}
