/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    useState, useEffect, useRef, useMemo,
} from 'react';
import ReactDOM from 'react-dom';

// import { CloseSquareFilled } from '@ant-design/icons';
// import { Button, Image } from 'antd'; // Import the CSS file for styling
import { useSpecialModalContext } from '../../shared/SpecialModalProvider';
import './styles.scss';

function Modal({
    isOpen, onClose, children, title, subTitle, width, onOpenFinished, scrollableContent = true, id,
}) {
    const [isMounted, setIsMounted] = useState(false);
    const [isVisible, setIsVisible] = useState(isOpen);
    const { activeModals, activateModal, destroyModal } = useSpecialModalContext();

    const modalRef = useRef(null);

    useEffect(() => {
        // check if another modal is open
        setIsMounted(true);
        setIsVisible(isOpen);

        if (isOpen) {
            activateModal(id);
            // Lock scrolling on the body when the modal is open
            document.body.style.overflow = 'hidden';
        } else {
            // Restore scrolling on the body when the modal is closed
            document.body.style.overflow = 'auto';
            destroyModal(id);
        }

        return () => {
            setIsMounted(false);
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const onAnimationEnd = () => {
        if (!isOpen) {
            setIsVisible(false);
        }

        if (isOpen) {
            if (onOpenFinished) {
                onOpenFinished();
            }
        }
    };

    const level = useMemo(() => activeModals[id]?.level || 1, [activeModals, id]);

    const hasModalOnTop = useMemo(() => {
        const keys = Object.keys(activeModals);
        return keys.some((key) => activeModals[key].level > level);
    }, [activeModals, level]);

    // const headerStyle = {
    //     textAlign: 'center',
    //     // color: '#fff',
    //     height: 32,
    //     paddingInline: 48,
    //     lineHeight: '64px',
    //     // backgroundColor: '#4096ff',
    // };
    // const layoutStyle = {
    //     borderRadius: 8,
    //     overflow: 'hidden',
    //     width: 'calc(50% - 8px)',
    //     maxWidth: 'calc(50% - 8px)',
    //     textAlign: 'left',
    // };

    // const renderHeader = () => (
    //     <div className="Header__Float">
    //         <div className="LeftButtons">
    //             <Button id="button1">Button 1</Button>
    //             <Button id="button2">Button 2</Button>
    //         </div>
    //         <div className="RightButtons">
    //             <Image src="your-image-url.png" alt="Image Button" />
    //             <Button id="activateButton">Activate</Button>
    //         </div>
    //     </div>
    // );

    const modalContent = (
        <div
            className={`WajedModal ${isOpen ? 'fadeIn' : 'fadeOut'} ${isVisible ? 'visible' : ''} ${hasModalOnTop ? 'hasModalOnTop' : ''}`}
            onAnimationEnd={onAnimationEnd}
            ref={modalRef}
            style={{ width, paddingRight: `${200 * (activeModals[id]?.level || 1)}px` }} // Add this line to set the width
        >
            <div className="WajedModal-overlay" />
            <div className="WajedModal-body" style={{ width: `calc(100vw - ${200 * (activeModals[id]?.level || 1)}px)` }}>
                <div className="WajedModal-body__Header">
                    <div className={`Header__First ${level !== 1 ? 'not_title' : ''}`}>
                        <button
                            type="button"
                            onClick={() => {
                                onClose();
                                destroyModal(id);
                            }}
                        >
                            X
                        </button>
                        {level === 1 && <div>{title}</div>}
                    </div>
                    <div className="Header__Second">{subTitle}</div>
                </div>
                <div className="WajedModal-body__Content">{children}</div>
            </div>
            <div className={`WajedModal-body__Content ${scrollableContent ? 'Scroll__Enabled' : ''}`}>{children}</div>
        </div>
    );

    return isMounted && isVisible ? ReactDOM.createPortal(modalContent, document.body) : null;
}

export default Modal;
