import {
    message,
    Space,
} from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import UploadableAvatar from '../Media/UploadableAvatar';
import CreateEditLandBasicsForm from '../CreateEditLandBasicsForm';
import EditLandSoilForm from '../EditLandSoilForm';
import Sources from '../WaterMgmt';
import Crops from '../../Pages/CropsManagement/Crops';
import GreenhouseForm from '../GreenhouseForm';
import { useGetLand, useUpdateLand } from '../../apis/lands';
import { AuthContext } from '../../shared/AuthProvider';
import DrawerContent from '../DrawerContent';
import './styles.scss';

function LandProfileDashboard({
    entry, onComplete, profileEntry, setSubTitle = () => null,
}) {
    const queryClient = useQueryClient();
    const [land, setLand] = useState(entry);
    const [selectedPage, setSelectedPage] = useState(<CreateEditLandBasicsForm entry={land} onComplete={onComplete} profileEntry={profileEntry} fullForm />);
    const updateLand = useUpdateLand();
    const { user } = useContext(AuthContext);
    const [selectedItem, setSelectedItem] = useState('info');

    const { data, refetch: reloadLand } = useGetLand(entry?.id);

    const menu = [
        {
            id: 'info',
            title: 'معلومات',
            onClick: () => {
                setSelectedPage(<CreateEditLandBasicsForm entry={land} onComplete={onComplete} profileEntry={profileEntry} fullForm />);
                setSelectedItem('info');
            },
        },
        {
            id: 'attributes',
            title: 'التربة',
            onClick: () => {
                setSelectedPage(<EditLandSoilForm entry={land} onComplete={onComplete} fullForm />);
                setSelectedItem('attributes');
            },
        },
        {
            id: 'waterSources',
            title: 'مصادر المياه',
            onClick: () => {
                setSelectedPage(<Sources land={land} onComplete={onComplete} />);
                setSelectedItem('waterSources');
            },
        },
        {
            id: 'crops',
            title: 'المحاصيل',
            onClick: () => {
                setSelectedPage(<Crops entry={land} onComplete={onComplete} />);
                setSelectedItem('crops');
            },
        },
        {
            id: 'greenHouse',
            title: 'الدفيئات',
            onClick: () => {
                setSelectedPage(<GreenhouseForm entry={land} onComplete={reloadLand} />);
                setSelectedItem('greenHouse');
            },
        },
    ];

    const onUploadCompleted = async (subCropType, url) => {
        const newForm = {
            image: url,
        };

        try {
            await updateLand.mutateAsync({
                data: newForm,
                landId: land?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        }
        queryClient.invalidateQueries(['useGetLands']);
    };

    useEffect(() => {
        setSubTitle(menu.find((item) => item.id === selectedItem).title);
    }, [selectedItem]);

    useEffect(() => {
        if (entry) {
            setLand(entry);
        }
    }, [entry]);

    useEffect(() => {
        if (data) {
            setLand(data);
        }
    }, [data]);

    return (
        <div className="LandProfileDashboard">
            <DrawerContent
                sideBarContent={(
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div className="UserDetails">
                            <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                                <div className="Avatar">
                                    <UploadableAvatar image={land?.image} entry={land} onComplete={onUploadCompleted} />
                                </div>
                                <div className="Name">{land?.name}</div>
                            </Space>
                        </div>
                        <div className="Menu">
                            <ul>
                                {
                                    menu.map((item) => (
                                        <li key={item?.id}>
                                            <button
                                                onClick={() => {
                                                    item.onClick();
                                                    setSubTitle(item.title);
                                                }}
                                                type="button"
                                                className={selectedItem === item.id ? 'selected' : ''}
                                            >
                                                {item.title}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Space>
                )}
                mainContent={selectedPage}
            />
        </div>
    );
}

export default LandProfileDashboard;
