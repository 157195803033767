import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

function StyledDropzone({
    accept, onDrop: onDropProp, fileAdded, setFileAdded,
}) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept,
        onDrop: (acceptedFiles) => {
            if (onDropProp) {
                onDropProp(acceptedFiles);
            }
            if (acceptedFiles.length > 0) {
                setFileAdded(true); // Update state after file is added
            }
        },
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(fileAdded ? {
            backgroundColor: '#e8f5e9',
            color: '#4caf50',
            borderColor: '#4caf50',
            borderStyle: 'solid',
        } : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
        fileAdded, // Include fileAdded in the dependency array
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>اسحب وأفلت الملفات هنا أو انقر لتحديد الملفات</p>
            </div>
        </div>
    );
}

export default StyledDropzone;
