import React from 'react';
import { Button, Drawer } from 'antd';

import CropDetailsProfileDashboard from '../CropDetailsProfileDashboard';

export default function FullCropsDetailsDrawer({
    onClose, isOpen, width, entry, onConfirm, selectedLand,
}) {
    return (
        <Drawer
            title="إدارة المحاصيل/أضف محصول"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            { entry ? (
                <CropDetailsProfileDashboard entry={entry} onComplete={onConfirm} selectedLand={selectedLand} />
            ) : (
                <div>يمكنك فقط إضافة محاصيل من الأراضي وليس من هنا.</div>
            )}
        </Drawer>
    );
}
