import SeoMeta from 'Components/SeoMeta';
import LandsMgmt from 'Components/LandsMgmt';

function Lands({ profileType }) {
    return (
        <>
            <SeoMeta title="إدارة الأراضي" />
            <LandsMgmt profileType={profileType} />
        </>

    );
}

export default Lands;
