import {
    Space,
} from 'antd';
import React, { useState } from 'react';

import './styles.scss';

import UploadableAvatar from '../Media/UploadableAvatar';
import EditLandSoilForm from '../EditLandSoilForm';
import Sources from '../WaterMgmt';
import CropTypes from '../../Pages/CropTypeManagment/CropTypes';

function CropDetailsProfileDashboard({
    entry, onComplete,
}) {
    const [selectedPage, setSelectedPage] = useState(<CropTypes entry={entry} onComplete={onComplete} profileEntry={entry} />);

    const menu = [
        {
            id: 'info',
            title: 'اصناف',
            onClick: () => {
                setSelectedPage(<CropTypes entry={entry} onComplete={onComplete} profileEntry={entry} />);
            },
        },
        {
            id: 'attributes',
            title: 'المزارعين المنتعين',
            onClick: () => {
                setSelectedPage(<EditLandSoilForm entry={entry} onComplete={onComplete} />);
            },
        },
        {
            id: 'waterSources',
            title: 'التجار',
            onClick: () => {
                setSelectedPage(<Sources entry={entry} onComplete={onComplete} />);
            },
        },
    ];

    return (
        <div className="CropTypeProfileDashboard">
            <div className="Sidebar">
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div className="UserDetails">
                        <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                            <div className="Avatar">
                                <UploadableAvatar image={entry?.image} />
                            </div>
                            <div className="Name">{entry?.name}</div>
                        </Space>
                    </div>
                    <div className="Menu">
                        <ul>
                            {
                                menu.map((item) => (
                                    <li>
                                        <button
                                            onClick={item.onClick}
                                            type="button"
                                        >
                                            {item.title}
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </Space>
            </div>
            <div className="MainContent">
                {selectedPage}
            </div>
        </div>
    );
}

export default CropDetailsProfileDashboard;
