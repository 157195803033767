import React from 'react';
import {
    Button, Space, DatePicker, Form,
} from 'antd';
import { Controller } from 'react-hook-form';
// import * as dayjs from 'dayjs';
import { get } from 'lodash';

import TextField from '../TextField';

const { RangePicker } = DatePicker;

function DateRangeWithAddButton({
    errors, control, entry,
}) {
    return (
        <>
            <div>
                {entry?.seasons?.map((season) => (
                    <div key={`${season.startDate}-${season.endDate}`}>
                        <p>
                            <strong>Start Date:</strong>
                            {season.startDate}
                            <strong>End Date:</strong>
                            {season.endDate}
                        </p>
                        {/* You can add more details or components here */}
                    </div>
                ))}
            </div>
            <Form.List
                name="seasons"
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({
                            key, name,
                        }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <TextField control={control} fieldName={`season${name}`} label="الموسم" placeholder="الموسم" errors={errors} />
                                <Form.Item
                                    name={`plantingDate${name}`}// Adjust this line
                                    label="تاريخ الزراعة"
                                    validateStatus={get(errors, `plantingDate${name}`) ? 'error' : ''}
                                    help={get(errors, `plantingDate${name}`)?.message}
                                >
                                    <Controller
                                        control={control}
                                        name={`plantingDate${name}`} // Match the name prop to the form field name
                                        render={({ field }) => (
                                            <RangePicker
                                                {...field}
                                                onChange={(dates) => {
                                                    field.onChange(dates); // Update the value in React Hook Form
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Item>
                                <Button type="danger" onClick={() => remove(name)}>
                                    Remove
                                </Button>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                أضف موسم
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
}

export default DateRangeWithAddButton;
