import {
    Dropdown, Button, Badge, Avatar, Space, message, Typography,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';

import {
    useGetAuthUsers,
    useUpdateAuthUserAdmin,
    useUpdateAuthUserEngineer,
} from 'apis/auth-users';
import SeoMeta from 'Components/SeoMeta';
import CreateUserModal from 'Components/CreateUserModal';
import DynamicTable from 'Components/DynamicTable';

import SubscriptionWidget from '../Components/SubscriptionWidget';
import FullUserDrawer from '../../../Components/FullUserDrawer';

function Users() {
    const [isCreateUserModalOpen, setIsCreateUserOpenModal] = useState(false);
    const { data: usersData, isLoading, refetch: refetchAuthUsers } = useGetAuthUsers();
    const updateAuthUserAdmin = useUpdateAuthUserAdmin();
    // const updateAuthUserAdminTemp = useUpdateAuthUserAdminTemp();
    const updateAuthUserEngineer = useUpdateAuthUserEngineer();

    const callUpdateEngineer = (record, engineer) => ({
        uid: record.uid,
        isEngineer: engineer,
        displayName: record.name,
        email: record.email,
    });

    const authUsers = useMemo(() => {
        const users = usersData?.users;
        if (!users || users.length === 0) {
            return [];
        }
        return users?.map((entry) => ({
            uid: entry.uid,
            key: entry.uid,
            name: entry.displayName,
            age: 32,
            address: 'نابلس - حسام',
            subscription: {
                state: 'ended',
                expirationDate: '01.08.2023',
            },
            email: entry.email,
            photoURL: entry.photoURL,
            customClaims: entry.customClaims,
        }));
    }, [usersData]);
    //
    // useEffect(async () => {
    //     // useUpdateAuthUserAdmin
    //     await updateAuthUserAdminTemp.mutateAsync({
    //         uid: 'EnMtKnHvUMgsInb5DWa118Vas313',
    //         isAdmin: true,
    //     });
    // }, [usersData]);

    const [entryToEdit, setEntryToEdit] = useState();
    const [isFullProfileDrawerOpen, setIsFullProfileDrawerOpen] = useState(false);

    const showRecordDetails = (record) => {
        console.table(record);
        setEntryToEdit(record);
        setIsFullProfileDrawerOpen(true);
    };

    const columns = useMemo(() => [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.photoURL}
                        />
                    </Badge>
                    <Typography.Link
                        style={{
                            marginRight: '5px', fontSize: '18px', color: '#131313', fontWeight: 'bold',
                        }}
                        onClick={() => showRecordDetails(record)}
                    >
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{text}</div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.email}</div>
                    </Typography.Link>
                </div>
            ),
        },
        {
            title: 'العنوان',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'مدير',
            dataIndex: 'customClaims',
            key: 'customClaims',
            render: (text) => {
                if (text?.admin && text?.engineer) {
                    return <span>مدير ومهندس</span>;
                }
                if (text?.admin) {
                    return <span>مدير</span>;
                }
                if (text?.engineer) {
                    return <span>مهندس</span>;
                }
                return '';
            },
        },
        {
            title: 'الاشتراك',
            key: 'subscription',
            dataIndex: 'subscription',
            render: (entry) => <SubscriptionWidget subscription={entry} />,
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            // eslint-disable-next-line no-unused-vars
            render: (value, record) => {
                const items = [
                    {
                        key: '1',
                        label: 'عرض الملف',
                        onClick: async () => {
                            showRecordDetails(record);
                        },
                    },
                    {
                        key: '2',
                        label: 'حذف',
                    },
                ];

                if (record?.customClaims?.admin) {
                    items.push({
                        key: '3',
                        label: 'حذف كمدير',
                        onClick: async () => {
                            await updateAuthUserAdmin.mutateAsync({
                                uid: record.uid,
                                isAdmin: false,
                            });
                            refetchAuthUsers();
                            message.success('تمت العملية بنجاح');
                        },
                    });
                } else {
                    items.push({
                        key: '4',
                        label: 'تعيين كمدير',
                        onClick: async () => {
                            await updateAuthUserAdmin.mutateAsync({
                                uid: record.uid,
                                isAdmin: true,
                            });
                            refetchAuthUsers();
                            message.success('تمت العملية بنجاح');
                        },
                    });
                }

                if (record?.customClaims?.engineer) {
                    items.push({
                        key: '5',
                        label: 'حذف كمهندس',
                        onClick: async () => {
                            await updateAuthUserEngineer.mutateAsync({ data: callUpdateEngineer(record, false) });
                            refetchAuthUsers();
                            message.success('تمت العملية بنجاح');
                        },
                    });
                } else {
                    items.push({
                        key: '6',
                        label: 'تعيين كمهندس',
                        onClick: async () => {
                            await updateAuthUserEngineer.mutateAsync({ data: callUpdateEngineer(record, true) });
                            refetchAuthUsers();
                            message.success('تمت العملية بنجاح');
                        },
                    });
                }
                return (
                    <Dropdown menu={{ items }}>
                        <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ], [authUsers]);

    return (
        <>
            <SeoMeta title="إدارة المستخدمين" />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Button
                    type="primary"
                    onClick={() => {
                        setIsCreateUserOpenModal(true);
                    }}
                >
                    إنشاء جديد sdf
                </Button>
                <DynamicTable columns={columns} dataSource={authUsers} loading={isLoading} />
            </Space>
            <CreateUserModal
                isOpen={isCreateUserModalOpen}
                onClose={() => {
                    setIsCreateUserOpenModal(false);
                }}
                onComplete={() => {}}
            />
            <FullUserDrawer
                isOpen={isFullProfileDrawerOpen}
                onClose={() => setIsFullProfileDrawerOpen(false)}
                entry={entryToEdit}
                width="calc(100%)"
            />
        </>
    );
}

export default Users;
