import React from 'react';
import { Button } from 'antd';

import CropSteps from '../CreateCrop/CropSteps';
import WajedModal from '../WajedModal';

export default function FullCropsDrawer({
    onClose, isOpen, width, entry, onConfirm, selectedLand, level,
}) {
    const getSelectedLandTitle = () => {
        if (selectedLand) {
            return `دارة المحاصيل/أضف محصول ل  ${selectedLand?.name}`;
        }
        return 'إدارة المحاصيل/أضف محصول'; // Handle the case where selectedLand is undefined or null
    };
    return (
        <WajedModal
            title="إدارة الأراضي"
            subTitle={getSelectedLandTitle()}
            width={width}
            onClose={onClose}
            open={isOpen}
            isOpen={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
            level={level}
            id="full-crops-drawer"
        >
            <CropSteps entry={entry} selectedLand={selectedLand} onComplete={onConfirm} />
        </WajedModal>
    );
}
