// components/PrivateRoute.js
import { Spin } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'shared/AuthProvider';

function PrivateRoute({ children }) {
    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);

    // If the authentication state is still loading, show a loading indicator or any other UI
    if (loading) {
        return (
            <div style={{
                padding: '100px 20px',
                textAlign: 'center',
            }}
            >
                <Spin
                    tip="Loading"
                    size="large"
                >
                    <div
                        className="content"
                        style={{
                            padding: '50px',
                            borderRadius: '4px',
                        }}
                    />
                </Spin>
            </div>
        );
    }

    // If the user is not logged in, redirect to the login page
    if (!user) {
        navigate('/login');
        return null;
    }

    // Render the children (protected content) if the user is logged in
    return children;
}

export default PrivateRoute;
