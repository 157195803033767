import {
    Button, Modal, Form,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from 'Components/FormFields/TextField';
import SelectField from 'Components/FormFields/SelectField';
import {
    cities, Gender, GenderName, AcademicQualifications, AcademicQualificationsNames,
} from 'shared/constants';

export default function CreateUserModal({
    isOpen, onComplete, entry, onClose,
}) {
    const schema = yup.object().shape({
        name: yup.string().required(),
        gender: yup.string().required(),
        dob: yup.string().required(),
        city: yup.string().required(),
        address: yup.string().required(),
        email: yup.string().email().required(),
        mobile: yup.string().required(),
        whatsApp: yup.string().required(),
        academicQualification: yup.string().required(),
        password: yup.string().required(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name,
            gender: entry?.gender,
            dob: entry?.dob,
            city: entry?.city,
            address: entry?.address,
            email: entry?.email,
            mobile: entry?.mobile,
            whatsApp: entry?.whatsApp,
            academicQualification: entry?.academicQualification,
        },
    });

    const handleFinish = () => {
        /*         if (data.id) {
            updateLocation.mutateAsync(data);
        } else {
            createLocation.mutateAsync(data);
        } */
        onComplete();
        onClose();
    };

    return (
        <Modal
            title="إنشاء مستخدم جديد"
            open={isOpen}
            onCancel={onClose}
            footer={null}
            destroyOnClose
        >
            <Form onFinish={handleSubmit(handleFinish)} layout="vertical">
                <TextField
                    label="الاسم الكامل"
                    control={control}
                    placeholder="أدخل الاسم"
                    fieldName="name"
                    errors={errors}
                />
                <SelectField
                    label="الجنس"
                    control={control}
                    fieldName="gender"
                    errors={errors}
                    options={Object.keys(Gender).map((genderEntry) => ({
                        key: genderEntry,
                        value: genderEntry,
                        label: GenderName[genderEntry],
                    }))}
                />
                <SelectField
                    label="المدينة/القرية"
                    control={control}
                    fieldName="city"
                    errors={errors}
                    search
                    options={cities.map((city) => ({
                        key: city.id,
                        value: city.id,
                        label: city.arabic,
                    }))}
                />
                <TextField
                    label="العنوان بالتفاصيل"
                    control={control}
                    fieldName="address"
                    errors={errors}
                />
                <TextField
                    label="البريد الإلكتروني"
                    control={control}
                    fieldName="email"
                    errors={errors}
                />
                <TextField
                    label="الموبايل"
                    control={control}
                    fieldName="mobile"
                    errors={errors}
                />
                <TextField
                    label="رقم الواتس اب"
                    control={control}
                    fieldName="whatsApp"
                    errors={errors}
                />
                <SelectField
                    label="المؤهل العلمي"
                    control={control}
                    fieldName="academicQualification"
                    errors={errors}
                    options={Object.keys(AcademicQualifications).map((item) => ({
                        key: item,
                        value: item,
                        label: AcademicQualificationsNames[item],
                    }))}
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {entry?.id ? 'حفظ' : 'انشاء'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
