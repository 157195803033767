import {
    Image,
    Space,
} from 'antd';

import { useGetMediaSummary } from '../../../apis/media';

function MediaSummary({
    entry,
}) {
    const { data: entities } = useGetMediaSummary(null, entry?.id);

    return (
        <Space>
            {entities ? (
                <>
                    {/* Render the first element */}
                    {entities && (
                        <div
                            style={{ position: 'relative', textAlign: 'center' }}
                        >
                            <Image
                                width={100}
                                height={80}
                                src={entities.firstEntry?.url}
                                style={{ zIndex: 1 }} // Ensure the image is behind the text
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 0,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1.2em',
                                    pointerEvents: 'none',
                                }}
                            >
                                {entities.docCount}
                            </p>
                        </div>
                    )}
                </>
            ) : (
                <p>No images found</p>
            )}
        </Space>
    );
}

export default MediaSummary;
