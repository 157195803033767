import SeoMeta from 'Components/SeoMeta';

import SubCropTypeReviewsMgmt from '../../../Components/SubCropTypeReviewsMgmt';

function SubCropTypesReviews() {
    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <div>
                <SubCropTypeReviewsMgmt />
            </div>
        </>
    );
}

export default SubCropTypesReviews;
