import {
    Button, Space, Badge, Avatar, Dropdown, Modal, message, Typography, Tag,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
// import { find } from 'lodash';

import SeoMeta from 'Components/SeoMeta';

import FullSubCropTypeRequestDrawer from '../FullSubCropTypeRequestDrawer';
import DynamicTable from '../DynamicTable';
import { useGetRequests } from '../../apis/requests';
import FullSubCropTypeManageDrawer from '../FullSubCropTypeManageDrawer';

function SubCropTypeRequestsMgmt({
    onComplete, cropTypeId, cropType,
}) {
    const { data: entities, isLoading: isLoadingEntities, refetch: refetchEntities } = useGetRequests(null, null, 'subCropType', ['new', 'rejected', 'approved', 'assigned']);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAssignDrawerOpen, setIsAssignDrawerOpen] = useState(false);
    const [createFlag, setCreateFlag] = useState(false);
    const [entry, setEntry] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);

    useEffect(() => {
        refetchEntities();
    }, [cropTypeId]);

    const openForEdit = () => {
        setCreateFlag(false);
        setIsAssignDrawerOpen(true);
    };

    const handleOnCreate = (type) => {
        refetchEntities();
        setEntry(type);
        setIsDrawerOpen(false);
        openForEdit();
    };

    const openForCreate = () => {
        setCreateFlag(true);
        setIsDrawerOpen(true);
    };

    const selectAndOpen = (record) => {
        setCreateFlag(false);
        setEntry(record);
        setIsDrawerOpen(true);
    };

    const doDeleteSubCropType = async (id) => {
        // try {
        //     await deleteLand.mutateAsync(id);
        message.success(id);
        message.success('تم الحذف');
        //     refetchEntities();
        // } catch (err) {
        //     message.error('لقد حصل خطأ ما');
        // }
    };

    const entitySelected = (record) => {
        const { entityId } = record;
        setSelectedRequest(record);
        setEntry(entityId);
    };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'entryName',
            key: 'entryName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record.image}
                        />
                    </Badge>
                    <div style={{ marginRight: '15px' }}>
                        <Typography.Text onClick={() => selectAndOpen(record)}>
                            {text}
                        </Typography.Text>
                    </div>
                </div>
            ),
        },
        {
            title: 'بادئ الطلب',
            dataIndex: 'initiator',
            key: 'initiator',
        },
        {
            title: 'ملاحظات للمهندس الزراعي',
            dataIndex: 'engRemarks',
            key: 'engRemarks',
        },
        {
            title: 'المهندس الزراعي المسؤول',
            dataIndex: 'engineer',
            key: 'engineer.displayName',
        },
        {
            title: 'الردود',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                let color = '';
                let label = '';
                switch (text) {
                case 'approved':
                    color = 'green';
                    label = 'مصدق عليه';
                    break;
                case 'new':
                    color = 'yellow';
                    label = 'جديد';
                    break;
                case 'assigned':
                    color = 'volcano';
                    label = 'مُكَلَّف';
                    break;
                case 'underreview':
                    color = 'lightgreen';
                    label = 'قيد المراجعة';
                    break;
                case 'rejected':
                    color = 'lightgreen';
                    label = 'مرفوض';
                    break;
                default:
                    color = 'geekblue';
                    break;
                }
                return (
                    <Tag color={color} key={text}>
                        {label}
                    </Tag>
                );
            },
        },
        // {
        //     title: 'العائلة',
        //     dataIndex: 'family',
        //     key: 'family',
        //     render: (text) => {
        //         const foundCropType = find(sampleCropTypes, { id: parseInt(text, 10) });
        //         return foundCropType ? foundCropType.name : null;
        //     },
        // },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                entitySelected(record);
                                openForEdit();
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                            onClick: () => {
                                Modal.confirm({
                                    title: 'هل أنت متأكد من الحذف؟',
                                    okText: 'نعم',
                                    okType: 'danger',
                                    cancelText: 'لا',
                                    onOk() {
                                        doDeleteSubCropType(record.id);
                                    },
                                });
                            },
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const showCreateNewRequest = () => {
        setIsDrawerOpen(true);
    };

    return (
        <>
            <SeoMeta title="إدارة الأصناف" />
            <FullSubCropTypeRequestDrawer
                width="calc(100%)"
                entry={entry}
                request={selectedRequest}
                isOpen={isDrawerOpen}
                onConfirm={handleOnCreate}
                onClose={() => setIsDrawerOpen(false)}
                cropTypeId={cropTypeId}
                onComplete={onComplete}
                createFlag={createFlag}
                cropType={cropType}
            />
            <FullSubCropTypeManageDrawer
                width="calc(100%)"
                entry={entry}
                request={selectedRequest}
                isOpen={isAssignDrawerOpen}
                onConfirm={handleOnCreate}
                onClose={() => setIsAssignDrawerOpen(false)}
                cropTypeId={cropTypeId}
                onComplete={onComplete}
                createFlag={createFlag}
                cropType={cropType}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <div>
                    <Button onClick={showCreateNewRequest}>إنشاء طلب جديد</Button>
                </div>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                        pagination={{
                            pageSize: 10,
                        }}
                        toolBarRender={() => [
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    openForCreate();
                                }}
                                type="primary"
                            >
                                إنشاء
                            </Button>,
                        ]}
                    />
                </Space>
            </Space>
        </>

    );
}

export default SubCropTypeRequestsMgmt;
