import React, { useEffect, useState, useContext } from 'react';
import { LoadingOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Modal, Upload, message, Dropdown, Button, Popconfirm, Typography, Image,
} from 'antd';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Timestamp } from 'firebase/firestore';
import * as dayjs from 'dayjs';

import { firebaseStorage } from '../../../shared/firebase.init';
import { useCreateMedia, useGetMedia } from '../../../apis/media';
import { AuthContext } from '../../../shared/AuthProvider';
import DynamicTable from '../../DynamicTable';

// const getBase64 = (file) => new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
// });

function ImagesContainer({ entry, onUploadCompletedExtra, onComplete }) {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage] = useState('');
    const [previewTitle] = useState('');

    const { data: fileListData, isLoading: isLoadingEntities } = useGetMedia(null, entry?.id);

    const [fileList, setFileList] = useState([]);

    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [percent, setPercent] = useState(0);

    const createMedia = useCreateMedia();
    const { user } = useContext(AuthContext);

    const onUploadCompleted = async (url) => {
        try {
            if (entry && url) {
                const data = {
                    entryId: entry.id,
                    url,
                    name: 'Later',
                    status: 'uploaded',
                    description: '',
                };

                await createMedia.mutateAsync({
                    data,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            console.log('Done');
            if (onComplete) {
                onComplete(entry, url);
            }
        }
    };

    const handleUpload = () => {
        if (!file) {
            alert('Please upload an image first!');
        }

        const storageRef = ref(firebaseStorage, `/files/${file.name}`);
        console.table(storageRef);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
        console.log('Reached here');

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const percentOfUpload = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                );

                // update progress
                setPercent(percentOfUpload);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setLoading(false);
                    setImageUrl(url);
                    console.log(url);
                    console.log(imageUrl);
                    console.log(percent);
                    onUploadCompleted(url);
                    if (onUploadCompletedExtra) {
                        onUploadCompletedExtra(url);
                    }
                });
            },
        );
    };

    const handleChange = (info) => {
        // if (info.file.status === 'uploading') {
        //     setLoading(true);
        //     return;
        // }
        setFile(info.file.originFileObj);
    };

    useEffect(() => {
        if (entry) {
            console.log('Media and entry available ');
        }
    }, [entry]);

    const handleCancel = () => setPreviewOpen(true);

    // const handlePreview = async (uploadedFile) => {
    //     if (!uploadedFile.url && !file.preview) {
    //         /* eslint-disable no-param-reassign */
    //         uploadedFile.preview = await getBase64(uploadedFile.originFileObj);
    //     }
    //     setPreviewImage(uploadedFile.url || uploadedFile.preview);
    //     setPreviewOpen(true);
    //     setPreviewTitle(uploadedFile.name || uploadedFile.url.substring(uploadedFile.url.lastIndexOf('/') + 1));
    // };

    // const handleChange = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    //     if (onUploadCompleted) {
    //         onUploadCompleted(newFileList);
    //     }
    // };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    useEffect(() => {
        if (entry && fileListData && fileListData.length > 0) {
            console.log('viola');
            console.table(fileListData[0]);
            setFileList(fileListData);
        }
    }, [fileListData]);

    // const getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // };

    const beforeUpload = (uploadFile) => {
        const isJpgOrPng = uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = uploadFile.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined style={{ fontSize: '24px' }} /> : <PlusOutlined style={{ fontSize: '24px' }} />}
            <div
                style={{
                    marginTop: 8,
                    fontSize: '14px',
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleView = (record) => {
        console.table(record);
    };

    const handleEdit = (record) => {
        console.table(record);
    };

    const handleDelete = (record) => {
        console.table(record);
    };

    const handleLinkClick = (record) => {
        console.table(record);
    };

    const convertToDate = (timestamp) => {
        if (timestamp instanceof Timestamp) {
            const dateObject = timestamp.toDate();
            const formattedDate = dayjs(dateObject).format('YYYY-MM-DD');
            return formattedDate;
        }
        if (typeof timestamp === 'number') {
            // Assuming the number is in milliseconds
            const dateObject = new Date(timestamp * 1000);
            const formattedDate = dayjs(dateObject).format('YYYY-MM-DD');
            return formattedDate;
        }

        return null;
    };

    const columns = [
        {
            title: 'المصدر',
            dataIndex: 'url',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                        width={100}
                        height={80}
                        src={record.url}
                    />
                </div>
            ),
        },
        {
            title: 'معلومات الملف',
            dataIndex: 'name',
            render: (text, record) => (
                <div style={{ marginRight: '15px' }}>
                    <Typography.Link
                        style={{
                            marginRight: '5px', fontSize: '22px', color: '#131313', fontWeight: 'bold',
                        }}
                        onClick={() => handleLinkClick(record)}
                    >
                        {record.name}
                    </Typography.Link>
                    <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.description}</div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <Button type="danger" style={{ marginRight: '10px' }} onClick={() => handleEdit(record)}>
                            تعديل
                        </Button>
                        <span style={{ color: '#6E6893' }}>|</span>
                        <Button type="danger" style={{ marginRight: '10px' }} onClick={() => handleView(record)}>
                            عرض
                        </Button>
                        <span style={{ color: '#6E6893' }}> | </span>
                        <Popconfirm
                            title="هل أنت متأكد من حذف هذا العنصر؟"
                            onConfirm={() => handleDelete(record)}
                            okText="نعم"
                            cancelText="لا"
                        >
                            <Button type="danger">حذف</Button>
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
        {
            title: 'الناشر',
            dataIndex: 'publisher',
        },
        {
            title: 'تاريخ النشر',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <div>
                    {convertToDate(createdAt)}
                </div>
            ),
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            // eslint-disable-next-line no-unused-vars
            render: (value, record) => {
                const items = [
                    {
                        key: '1',
                        label: 'حفظ',
                        onClick: () => {
                        },
                    },
                    {
                        key: '2',
                        label: 'حذف',
                        onClick: async () => {
                            Modal.confirm({
                                title: 'هل أنت متأكد من الحذف؟',
                                okText: 'نعم',
                                okType: 'danger',
                                cancelText: 'لا',
                                onOk() {
                                },
                            });
                        },
                    },
                ];
                return (
                    <Dropdown menu={{ items }}>
                        <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                {console.log('fileList', fileList)}
                {fileList !== null ? (
                    <DynamicTable
                        tableType="pro"
                        search={false}
                        columns={columns}
                        dataSource={fileList}
                        rowKey="id"
                        loading={isLoadingEntities}
                        toolBarRender={() => [
                            <Upload
                                name="avatar"
                                showUploadList={false}
                                listType="picture-card"
                                className="avatar-uploader"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {fileList?.length >= 8 ? null : uploadButton}
                            </Upload>,
                        ]}
                        extraHeight={50}
                        // light
                    />
                ) : (
                    <p>لا يوجد شيء.</p>
                )}
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
}
export default ImagesContainer;
