import React, { useState } from 'react';
import {
    DatePicker, Form,
} from 'antd';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

const { RangePicker } = DatePicker;

function DateRangeRegular({
    control, errors, placeholder, fieldName, label,
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName} // Match the name prop to the form field name
                errors={errors}
                render={({ field }) => (
                    <RangePicker
                        {...field}
                        renderExtraFooter={() => placeholder}
                        onOpenChange={(open) => setIsOpen(open)}
                        popupStyle={
                            // a work around to avoid date picker bug (always active even after close)
                            isOpen
                                ? {
                                    display: null,
                                } : {
                                    display: 'none',
                                }
                        }
                    />
                )}

            />
        </Form.Item>
    );
}

export default DateRangeRegular;
