import './styles.scss';
import { Space } from 'antd';
import { NavLink, Outlet } from 'react-router-dom';

const links = [
    {
        title: 'أنواع الأصناف',
        path: 'types',
    },
    {
        title: 'الأصناف',
        path: 'subtypes',
    },
    {
        title: 'طلبات',
        path: 'subtypesRequests',
    },
    {
        title: 'طلبات المهندسين',
        path: 'subtypesReviews',
    },

];

function CropTypeManagement() {
    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <nav className="Nav">
                    {links.map((entry) => (
                        <NavLink
                            key={entry.path}
                            to={`/crop-types-management/${entry.path}`}
                            className={({ isActive }) => (isActive ? 'IsActive' : '')}
                        >
                            {entry.title}
                        </NavLink>
                    ))}
                </nav>
                <Outlet />
            </Space>
        </div>
    );
}

export default CropTypeManagement;
