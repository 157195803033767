import React from 'react';
import { Button, Drawer } from 'antd';

import PhaseForm from '../CreateEditLandPhaseForm';

export default function FullGreenhousesDrawer({
    onClose, onConfirm, isOpen, width, entry,
}) {
    return (
        <Drawer
            title="إدارة الدفيئات/أضف دفيئات"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <PhaseForm entry={entry} onClose={onClose} onConfirm={onConfirm} />
        </Drawer>
    );
}
