import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc, deleteDoc, updateDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetWaterSources = (profiles, lands, offset = 0, queryLimit = 9999) => useQuery(['useGetWaterSources', {
    profiles, lands, queryLimit, offset,
}], async () => {
    const entries = [];

    const sourcesRef = collection(firebaseFirestore, DbTableNames.waterSources);
    const queryParams = [sourcesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    if (lands && lands.length > 0) {
        queryParams.push(where('lands', 'array-contains-any', lands));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetWaterSource = (sourceId) => useQuery(
    ['useGetWaterSource', sourceId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.waterSources, sourceId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!sourceId,
    },
);

export const useCreateWaterSource = () => useMutation({
    mutationFn: async ({ data, userUid }) => {
        const createdData = {
            ...data,
            createdAt: dayjs().unix(),
            createdBy: userUid,
            admins: [userUid],
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.waterSources), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdateWaterSource = () => useMutation({
    mutationFn: async ({ data, waterSourceId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        console.table(data);
        await updateDoc(doc(firebaseFirestore, DbTableNames.waterSources, waterSourceId), updateData);
        return {
            ...data,
        };
    },
});

export const useDeleteWaterSource = () => useMutation({
    mutationFn: async (id) => {
        try {
            await deleteDoc(doc(firebaseFirestore, DbTableNames.waterSources, id));
        } catch (e) {
            console.error('An error has happened', id, e);
        }
    },
});
