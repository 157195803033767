import React, { useEffect, useContext } from 'react';
import {
    Button, Form, message,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from '../FormFields/TextField';
import { sampleCropTypes } from '../../shared/constants';
import SelectField from '../FormFields/SelectField';
import TextAreaField from '../FormFields/TextAreaField';
import { AuthContext } from '../../shared/AuthProvider';
import { useCreateCropType } from '../../apis/crop-types';

const schema = yup.object().shape({
    name: yup.string().required('العائلة مطلوبة'),
});

export default function CropTypeForm({
    entry, onComplete, onClose, profileId,
}) {
    const { user } = useContext(AuthContext);
    const createCropType = useCreateCropType();

    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name,
        },
    });

    const handleFinish = async (data) => {
        try {
            await createCropType.mutateAsync({
                data,
                profileId,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
        } finally {
            if (onComplete) {
                onComplete(data);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    // Use useEffect to reset the form when entry changes
    useEffect(() => {
        reset({
            name: entry?.name || '',
        });
    }, [entry]);

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit(handleFinish)} autocomplete="off">
            <SelectField
                search
                label="العائلة"
                control={control}
                fieldName="family"
                errors={errors}
                options={sampleCropTypes.map((crop) => ({
                    key: crop.id,
                    value: crop.id,
                    label: crop.name,
                }))}
            />
            <TextField
                label="نوع صنف"
                control={control}
                errors={errors}
                placeholder="نوع صنف"
                fieldName="name"
                buttonText="نوع صنف جديد +"
            />
            <TextAreaField
                label="مميزات"
                control={control}
                errors={errors}
                placeholder="مميزات"
                fieldName="features"
            />
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {entry?.id ? 'حفظ' : 'انشاء'}
                </Button>
            </Form.Item>
        </Form>
    );
}
