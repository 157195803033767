import { useMemo } from 'react';

import SelectField from 'Components/FormFields/SelectField';
import { cities } from 'shared/constants';

export default function FilteredCities({
    control, errors, watch, setValue,
}) {
    const governorate = watch('governorate');
    const filteredCities = useMemo(() => cities.filter((city) => city.parent === governorate), [governorate]);

    return (
        <>
            <SelectField
                search
                label="المحافظة"
                control={control}
                fieldName="governorate"
                errors={errors}
                onChange={(value) => {
                    setValue('governorate', value);
                    setValue('city', null);
                }}
                options={cities
                    .filter((city) => city.parent === null)
                    .map((city) => ({
                        key: city.id,
                        value: city.id,
                        label: city.arabic,
                    }))}
            />
            <SelectField
                search
                label="المدينة/القرية"
                control={control}
                fieldName="city"
                errors={errors}
                options={filteredCities
                    .filter((city) => city.parent !== null)
                    .map((city) => ({
                        key: city.id,
                        value: city.id,
                        label: city.arabic,
                    }))}
            />
        </>

    );
}
