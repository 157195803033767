import SeoMeta from 'Components/SeoMeta';

import PestsMgmt from '../../../Components/PestsMgmt';

function Pests() {
    return (
        <>
            <SeoMeta title="إدارة الآفات" />
            <PestsMgmt />
        </>

    );
}

export default Pests;
