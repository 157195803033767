import React from 'react';
import { Button, Drawer } from 'antd';

import Media from '../Media';

export default function FullMediaDrawer({
    onClose, isOpen, width, entry, onConfirm,
}) {
    return (
        <Drawer
            title="إدارة الوسائط/أضف وسائط"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <Media onClose={onClose} entry={entry} onComplete={onConfirm} />
        </Drawer>
    );
}
