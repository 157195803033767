import SeoMeta from 'Components/SeoMeta';

import CropTypeMgmt from '../../../Components/CropTypeMgmt';

function SubCropTypeManagement() {
    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <div>
                <CropTypeMgmt />
            </div>
        </>
    );
}

export default SubCropTypeManagement;
