import React, { useContext } from 'react';
import {
    message, Space, Spin,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as dayjs from 'dayjs';

import SelectField from 'Components/FormFields/SelectField';
import { plantFamily } from 'shared/constants';

import DateRangeWithAddButton from '../FormFields/DateRangeWithAddButton';
import NumberField from '../FormFields/NumberField';
import { useUpdateSubCropType, useGetSubCropType } from '../../apis/sub-crop-types';
import TextAreaField from '../FormFields/TextAreaField';
import { AuthContext } from '../../shared/AuthProvider';
import CustomForm from '../CustomForm';
import SwitchField from '../FormFields/SwitchField';

function SubCropTypeInfoForm({
    entry, onComplete, onClose,
}) {
    const schema = yup.object().shape({
        family: yup.string().required('العائلة مطلوبة').label('العائلة'),
        minDays: yup.string().required('العائلة مطلوبة').label('العائلة'),
        maxDays: yup.string().required('العائلة مطلوبة').label('العائلة'),
        isHidden: yup.boolean(),
    });
    const updateSubCropType = useUpdateSubCropType();
    const { user } = useContext(AuthContext);

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            family: entry?.family,
            features: entry?.features,
            minDays: entry?.minDays,
            maxDays: entry?.maxDays,
            isHidden: Boolean(entry?.isHidden),
        },
    });

    const handleFinish = async (formData) => {
        const allValues = getValues();
        const plantingDateFields = Object.keys(allValues).filter((key) => key.startsWith('plantingDate'));
        const seasonFields = Object.keys(allValues).filter((key) => key.startsWith('season'));

        seasonFields.map((key) => ({
            [key]: key,
        }));

        const seasonsArray = plantingDateFields.map((key) => {
            const startDate = allValues[key]?.[0] ? dayjs(allValues[key][0]).format('YYYY-MM-DD') : null;
            const endDate = allValues[key]?.[1] ? dayjs(allValues[key][1]).format('YYYY-MM-DD') : null;

            if (startDate && endDate) {
                return {
                    startDate,
                    endDate,
                };
            }
            return null;
        }).filter((season) => season !== null);
        const newForm = { ...formData };

        if (entry.seasons) {
            const updatedSeasonsArray = [...seasonsArray, ...entry.seasons];
            setValue('seasons', updatedSeasonsArray);
            newForm.seasons = updatedSeasonsArray;
        } else {
            setValue('seasons', seasonsArray);
            newForm.seasons = seasonsArray;
        }

        // Remove all plantingDate fields from the form
        plantingDateFields.forEach((field) => {
            setValue(field, null);
            newForm[field] = null;
        });

        seasonFields.forEach((field) => {
            setValue(field, null);
            newForm[field] = null;
        });

        try {
            await updateSubCropType.mutateAsync({
                data: newForm,
                subCropTypeId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        } finally {
            if (onComplete) {
                onComplete(formData);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    return (
        <CustomForm
            layout="vertical"
            onFinish={handleSubmit(handleFinish)}
            loading={updateSubCropType.isLoading}
            submitText={entry?.id ? 'حفظ' : 'انشاء'}
            editable={entry?.id}
            reset={reset}
            fullForm
        >
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <SelectField
                    search
                    label="العائلة"
                    control={control}
                    fieldName="family"
                    errors={errors}
                    options={plantFamily}
                />
                <Space
                    direction="horizontal"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <div>فترة الزراعة</div>
                    <NumberField
                        label="من"
                        control={control}
                        errors={errors}
                        placeholder="الحد الأدنى لعدد الأيام"
                        fieldName="minDays"
                    />
                    <NumberField
                        label="الى"
                        control={control}
                        errors={errors}
                        placeholder="الحد الأقصى لعدد الأيام"
                        fieldName="maxDays"
                    />
                </Space>
            </Space>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <TextAreaField
                    label="مميزات"
                    control={control}
                    errors={errors}
                    placeholder="مميزات"
                    fieldName="features"
                />
                <DateRangeWithAddButton errors={errors} control={control} entry={entry} />
            </Space>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                {/* <Form.Item label="مخفي">
                    <Switch control={control} fieldName="isHidden" />
                </Form.Item> */}

                <SwitchField control={control} label="مخفي" placeholder="" fieldName="isHidden" errors={errors} setValue={setValue} />

            </Space>
        </CustomForm>
    );
}

export default function EditSubCropTypeInfo({ entry, onComplete, subCropTypeId }) {
    const { data: subCropTypeData, isLoading } = useGetSubCropType(subCropTypeId);

    if (isLoading) {
        return (
            <Spin tip="يتم تحميل المعلومات" size="large">
                <div className="content" />
            </Spin>
        );
    }

    return (
        <SubCropTypeInfoForm entry={entry} onComplete={onComplete} subCropTypeData={subCropTypeData} />
    );
}
