import React, { useState, useRef } from 'react';
import {
    Button, Form,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';

import './styles.scss';

function FromBar(props) {
    const {
        editMode, editable, fullForm, submitText, loading, onReset, onEditToggle, handleSubmit,
    } = props;

    if (!fullForm) {
        return null;
    }

    if (!editable) {
        return (
            <div className="form-bar">
                <Button type="primary" htmlType="submit" loading={loading} onClick={handleSubmit}>
                    {submitText || 'حفظ'}
                </Button>
            </div>
        );
    }

    return (
        <div className="form-bar">
            {editMode ? (
                <div className="bar-actions">
                    <Button onClick={onReset} danger>إلغاء</Button>

                    <Button type="primary" htmlType="submit" loading={loading} onClick={handleSubmit}>
                        {submitText || 'حفظ'}
                    </Button>
                </div>
            ) : (
                <Button onClick={onEditToggle}><EditOutlined /></Button>
            )}
        </div>
    );
}

function CustomForm({
    children, reset, loading, fullForm, submitText, layout, editable, ...rest
}) {
    const [editMode, setEditMode] = useState(!editable);
    const formRef = useRef(null);

    const handleSubmit = () => {
        formRef.current.validateFields()
            .then(() => {
                formRef.current.submit();
            })
            .catch((error) => {
                console.log('Validation error:', error);
            });
    };

    const layoutOverride = {
        labelCol: {
            span: 3,
        },
        wrapperCol: {
            span: 16,
        },
        labelAlign: 'left',
    };

    const onEditToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setEditMode(!editMode);
    };

    const onReset = (e) => {
        e.preventDefault();
        e.stopPropagation();
        reset();
        setEditMode(false);
    };

    const parsedLayout = typeof layout === 'string' ? { layout } : layout;

    return (
        <>
            <FromBar
                editMode={editMode}
                editable={editable}
                fullForm={fullForm}
                submitText={submitText}
                loading={loading}
                onReset={onReset}
                onEditToggle={onEditToggle}
                handleSubmit={handleSubmit}
            />

            <div className="scrollable-area">
                {fullForm ? (
                    <Form {...(parsedLayout || layoutOverride)} {...rest} disabled={!editMode} ref={formRef} autocomplete="off">
                        {children}
                    </Form>
                ) : (
                    <Form {...(parsedLayout)} {...rest} disabled={!editMode} ref={formRef} autocomplete="off">
                        {children}
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {submitText || 'حفظ'}
                        </Button>
                    </Form>
                )}

            </div>
        </>
    );
}

export default CustomForm;
