import React, { useState, useContext, useEffect } from 'react';
import {
    Button, message, Space,
} from 'antd';

import PestForm from '../CreateEditPestForm';
// import ImagesContainer from '../Media/ImagesContainer';
import { useCreateMedia } from '../../apis/media';
import { AuthContext } from '../../shared/AuthProvider';
import ManagePestInformation from '../CreateEditPestForm/ManagePestInformation';
import ManagePestFightingForm from '../CreateEditPestForm/ManagePestFightingForm';
import WajedModal from '../WajedModal';
import UploadableAvatar from '../Media/UploadableAvatar';
import DrawerContent from '../DrawerContent';
import './styles.scss';
import Media from '../Media';

export default function FullPestsDrawer({
    onClose, isOpen, width, entry, onConfirm, onComplete,
}) {
    const createMedia = useCreateMedia();
    const { user } = useContext(AuthContext);
    const [subtitle, setSubtitle] = useState('');

    const onUploadCompleted = async (url) => {
        try {
            if (entry && url) {
                const data = {
                    entryId: entry.id,
                    url,
                    name: 'Later',
                    status: 'uploaded',
                    description: '',
                };

                await createMedia.mutateAsync({
                    data,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            if (onComplete) {
                onComplete();
            }
        }
    };

    const [selectedItem, setSelectedItem] = useState('addPest');
    const [selectedPage, setSelectedPage] = useState(<PestForm onClose={onClose} entry={entry} onComplete={onConfirm} />);

    // Just in case we want to listen to those changes.
    useEffect(() => {
        if (entry) {
            setSelectedPage(<PestForm onClose={onClose} entry={entry} onComplete={onConfirm} />);
            setSelectedItem('addPest');
        } else {
            setSelectedPage(<PestForm onClose={onClose} entry={null} onComplete={onConfirm} />);
        }
    }, [entry]);

    const menu = [
        {
            key: 'addPest',
            label: 'الكل',
            onClick: () => {
                setSelectedPage(<PestForm onClose={onClose} entry={entry} onComplete={onConfirm} />);
                setSelectedItem('addPest');
            },
        },
        {
            key: 'information',
            label: 'معلومات',
            onClick: () => {
                setSelectedPage(<ManagePestInformation onClose={onClose} entry={entry} onComplete={onConfirm} />);
                setSelectedItem('information');
            },
        },
        {
            key: 'fight',
            label: 'مكافحه',
            onClick: () => {
                setSelectedPage(<ManagePestFightingForm onClose={onClose} entry={entry} onComplete={onConfirm} />);
                setSelectedItem('fight');
            },
        },
        {
            key: 'media',
            label: 'وسائط الآفات',
            onClick: () => {
                setSelectedPage(<Media entry={entry} onUploadCompleted={onUploadCompleted} />);
                setSelectedItem('media');
                // setSelectedPage(<ImagesContainer entry={entry} onUploadCompleted={onUploadCompleted} />);
                // setSelectedItem('media');
            },
        },
    ];

    useEffect(() => {
        setSubtitle(menu[0].label);
    }, []);

    console.log('entry::', entry);

    return (
        <WajedModal
            title="إدارة الآفات"
            subTitle={subtitle}
            width={width}
            onClose={onClose}
            isOpen={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
            id="full-pests-drawer"
        >
            <DrawerContent
                sideBarContent={(
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div className="UserDetails">
                            <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                                <div className="Avatar">
                                    <UploadableAvatar image={entry?.image} entry={entry} onComplete={onUploadCompleted} />
                                </div>
                                <div className="Name">{entry?.name}</div>
                            </Space>
                        </div>
                        <div className="Menu">
                            <ul>
                                {
                                    menu.map((item) => (
                                        <li key={item?.key}>
                                            <button
                                                onClick={() => {
                                                    item.onClick();
                                                    setSubtitle(item.label);
                                                }}
                                                type="button"
                                                className={selectedItem === item.key ? 'selected' : ''}
                                            >
                                                {item.label}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Space>
                )}
                mainContent={selectedPage}
            />
        </WajedModal>
    );
}
