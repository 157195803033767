import SeoMeta from 'Components/SeoMeta';

import CropsDashboardMgmt from '../../../Components/CropsDashboardMgmt';

function Crops() {
    return (
        <>
            <SeoMeta title="إدارة الأراضي" />
            <CropsDashboardMgmt />
        </>

    );
}

export default Crops;
