import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc, updateDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetRequests = (profiles, entityId, type, status, offset = 0, queryLimit = 9999) => useQuery(['useGetRequests', {
    profiles, entityId, type, status, queryLimit, offset,
}], async () => {
    const entries = [];

    const subCropTypesRef = collection(firebaseFirestore, DbTableNames.requests);
    const queryParams = [subCropTypesRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (entityId) {
        queryParams.push(where('entityId', '==', entityId));
    }

    if (type) {
        queryParams.push(where('type', '==', type));
    }

    if (status) {
        queryParams.push(where('status', 'in', status));
    } else {
        // If status is null, include both null and 'approved'
        queryParams.push(where('status', 'in', ['approved']));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });
    return entries;
});

export const useGetRequest = (requestId) => useQuery(
    ['useGetRequest', requestId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.requests, requestId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!requestId,
    },
);

export const useCreateRequest = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.requests), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdateRequest = () => useMutation({
    mutationFn: async ({ data, requestId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.requests, requestId), updateData);
        return {
            ...data,
        };
    },
});
