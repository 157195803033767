import { message } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    OwnerShipTypes, waterSources, Saltiness,
} from 'shared/constants';
import TextField from 'Components/FormFields/TextField';
import NumberField from 'Components/FormFields/NumberField';
import SelectField from 'Components/FormFields/SelectField';
import TextAreaField from 'Components/FormFields/TextAreaField';

import { AuthContext } from '../../shared/AuthProvider';
import { useCreateWaterSource, useUpdateWaterSource } from '../../apis/water-sources';
import CustomForm from '../CustomForm';
import FilteredCities from '../FilteredCities';

export default function CreateEditWaterForm({ onSubmit, entry, land }) {
    const { user } = useContext(AuthContext);
    const createWater = useCreateWaterSource();
    const udateWaterSource = useUpdateWaterSource();
    const schema = yup.object().shape({
        name: yup.string().required(),
        type: yup.string().required(),
        ownershipType: yup.string().required(),
        influx: yup.string().required(),
        owner: yup.string().required(),
        saltiness: yup.string().required(),
        notes: yup.string(),
        city: yup.string().required('الرجاء إدخال المدينة').label('المدينة'),
        governorate: yup.string().required('الرجاء إدخال المحافظة').label('المحافظة'),
    });
    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name,
            type: entry?.type ? parseInt(entry.type, 10) : null,
            ownershipType: entry?.ownershipType,
            influx: entry?.influx,
            owner: entry?.owner,
            saltiness: entry?.saltiness ? parseInt(entry.saltiness, 10) : null,
            city: entry?.city,
            notes: entry?.notes,
        },
    });

    const handleFinish = async (data) => {
        const lands = [];
        if (land) {
            lands.push(land.id);
        }

        const newData = { ...data, lands };

        try {
            if (entry?.id) {
                await udateWaterSource.mutateAsync({
                    newData,
                    userUid: user?.uid,
                    waterSourceId: entry?.id,
                });
            } else {
                await createWater.mutateAsync({
                    data: newData,
                    userUid: user?.uid,
                });
            }
            message.success('تم الحفظ');
        } catch (e) {
            message.error('لقد حصل خطأ ما');
        } finally {
            onSubmit?.(data);
        }
    };

    return (
        <CustomForm form={form} layout="vertical" onFinish={handleSubmit(handleFinish)} fullForm editable={entry?.id} submitText={entry?.id ? 'حفظ' : 'إضافة'} reset={reset}>
            <TextField label="اسم المصدر" control={control} fieldName="name" errors={errors} />
            <SelectField
                search
                label="نوع المصدر"
                control={control}
                fieldName="type"
                errors={errors}
                options={waterSources.map((source) => ({
                    key: source.id,
                    value: source.id,
                    label: source.name,
                }))}
            />
            <SelectField
                search
                label="ملكية المصدر"
                control={control}
                fieldName="ownershipType"
                errors={errors}
                options={OwnerShipTypes.map((ownerShip) => ({
                    key: ownerShip.id,
                    value: ownerShip.id,
                    label: ownerShip.name,
                }))}
            />
            <TextField label="اسم المالك" control={control} fieldName="owner" errors={errors} />
            <NumberField
                label="كمية الضخ"
                control={control}
                fieldName="influx"
                errors={errors}
                addonAfter="كوب/ساعة"
            />
            <SelectField
                label="الملوحة"
                control={control}
                fieldName="saltiness"
                errors={errors}
                options={Saltiness.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.name,
                }))}
            />
            <FilteredCities control={control} errors={errors} watch={watch} setValue={setValue} />
            <TextAreaField label="الملاحظات" control={control} fieldName="notes" errors={errors} />
            {/* <Form.Item>
                <Button
                    type="primary"
                    size="small"
                    htmlType="sشubmit"
                    loading={createWater.isLoading || udateWaterSource.isLoading}
                >
                    { entry?.id ? 'حفظ' : 'إضافة'}
                </Button>
            </Form.Item> */}
        </CustomForm>
    );
}
