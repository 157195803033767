import {
    Button, Form, Drawer, List, Avatar, Checkbox,
    Space,
} from 'antd';
import React, { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    useContext, useState, useMemo,
} from 'react';
// import SelectField from 'Components/FormFields/SelectField';
// import { cities } from 'shared/constants';
// import _ from 'lodash';

import { AuthContext } from 'shared/AuthProvider';

import { ProfilesContext } from '../../shared/ProfilesProvider';
import AutoCompleteField from '../FormFields/AutoCompleteField';
import ListField from '../FormFields/ListField';
import { useGetLands } from '../../apis/lands';
import { useGetPests, useUpdatePest } from '../../apis/pests';
import { useCreatePestReport } from '../../apis/pest-reports';

import DangerPercentageSelect from './DangerPercentageSelect';

export function CreatePestReportForm({ onComplete, pestsList }) {
    const { user } = useContext(AuthContext);
    const { profilesList } = useContext(ProfilesContext);
    const [selectedProfile, setSelectedProfile] = useState(null);

    const { data: profileLands } = useGetLands(null, selectedProfile);
    const [selectedPest, setSelectedPest] = useState(null);
    const [selectedLands, setSelectedLands] = useState([]);

    const createPestReport = useCreatePestReport();
    const updatePest = useUpdatePest();
    const schema = yup.object().shape({
        // name: yup.string().required(),
        // profileType: yup.string().required(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const updatePestCities = async (citiesNames) => {
        const data = {
            cities: citiesNames,
        };

        await updatePest.mutateAsync({
            data,
            pestId: selectedPest.id,
            userUid: user?.uid,
        });
    };

    const handleFinish = async (formData) => {
        const newForm = { ...formData };
        newForm.selectedPestId = selectedPest.id;
        newForm.selectedProfileId = selectedProfile.id;
        newForm.selectedProfile = selectedProfile;

        const { id: pId, label: pestName } = selectedPest;
        newForm.pest = { pId, pestName };

        try {
            // create a promise all to create pests reports whe multi areas are selected
            const promises = [];
            selectedLands.forEach((land) => {
                const newFormCopy = { ...newForm };
                newFormCopy.selectedLandId = land.id;
                const { 'full-city': fullCity } = land;
                newFormCopy.land = fullCity;
                promises.push(createPestReport.mutateAsync({
                    data: newFormCopy,
                    userUid: user?.uid,
                }));
            });
            const createElement = await Promise.all(promises);
            await updatePestCities(selectedLands.map((land) => land['full-city'].parent));

            // create the pest report for the selected land

            // const createElement = await createPestReport.mutateAsync({
            //     data: newForm,
            //     userUid: user?.uid,
            // });
            if (onComplete) {
                onComplete(createElement);
            }
        } catch {
            /* empty */
        }
    };

    const onSelect = (value, option) => {
        const { id, key } = option;
        const elements = {
            id, key,
        };
        if (option) {
            setSelectedProfile(elements);
        }
    };

    const handleCheckboxChange = (item) => {
        if (selectedLands.some((land) => land.id === item.id)) {
            setSelectedLands(selectedLands.filter((land) => land.id !== item.id));
        } else {
            setSelectedLands([...selectedLands, item]);
        }
        console.table(item);

        // also find the aggregator to update it once we h
    };

    const onPestSelected = (value, option) => {
        setSelectedPest(option);
    };

    const onLandSelected = (item) => {
        setSelectedLands([...selectedLands, item]);
    };

    const renderItem = (item) => (
        <List.Item>
            <List.Item.Meta
                avatar={<Avatar src={item.image} />}
                title={(
                    <div>
                        <Checkbox
                            onChange={() => handleCheckboxChange(item)}
                            checked={selectedLands?.some((land) => land.id === item.id)}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <a href="https://ant.design">{item.name}</a>
                    </div>
                )}
                description={item['full-city']?.arabic}
            />
        </List.Item>
    );

    return (
        <Form onFinish={handleSubmit(handleFinish)} layout="vertical" autocomplete="off">
            <Space direction="vertical" style={{ width: '100%' }}>
                <AutoCompleteField
                    label="اسم المزارع"
                    control={control}
                    errors={errors}
                    options={profilesList}
                    // handleSearch={handleSubCropTypesSearch}
                    onSelect={onSelect}
                    placeholder="اسم المزارع"
                    fieldName="farmerName"
                />
                <ListField
                    label="قطعة الارض"
                    control={control}
                    errors={errors}
                    options={profileLands}
                    renderItem={renderItem}
                    handleSearch={onLandSelected}
                    placeholder="قطعة الارض"
                    fieldName="land"
                />
                <AutoCompleteField
                    label="اسم الآفة"
                    control={control}
                    errors={errors}
                    options={pestsList}
                    // handleSearch={handleSubCropTypesSearch}
                    onSelect={onPestSelected}
                    placeholder="اسم الآفة"
                    fieldName="pest"
                />
                <Form.Item label="نسبة الخطر">
                    <DangerPercentageSelect name="spread" control={control} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={createPestReport.isLoading}>
                        انشاء
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
}

export default function CreateEditPestsReportDrawer({ onComplete, onClose, isOpen }) {
    const { data: pets } = useGetPests({});
    const memPests = useMemo(
        () => pets?.map((entry) => ({
            key: entry.id,
            id: entry.id,
            label: entry.name,
            value: entry.name,
            item: entry,
        })),
        [pets],
    );
    return (
        <Drawer
            title="إنشاء حادثة جديدة"
            width={720}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إلغاء</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <CreatePestReportForm
                pestsList={memPests}
                onComplete={(createElement) => {
                    onClose();
                    onComplete(createElement);
                }}
            />
        </Drawer>
    );
}
