import SeoMeta from 'Components/SeoMeta';

import CropTypesAggregationMgmt from '../../../Components/CropTypesAggregationMgmt';

function CropTypesAggregation() {
    return (
        <>
            <SeoMeta title="إدارة الأراضي" />
            <CropTypesAggregationMgmt />
        </>

    );
}

export default CropTypesAggregation;
