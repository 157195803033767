import { Button, Drawer } from 'antd';

import GreenhouseMgmt from '../GreenhouseMgmt';

export default function FullGreenhousesDrawer({
    onClose, isOpen, width, entry,
}) {
    return (
        <Drawer
            title="إدارة الدفيئات/أضف دفيئات"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <GreenhouseMgmt entry={entry} />
        </Drawer>
    );
}
