import { find } from 'lodash';
import React, { useState, useContext } from 'react';
import {
    Button, Space, Dropdown, Modal, message,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';

import FullWaterSourceDrawer from 'Components/FullWaterSourceDrawer';
import SeoMeta from 'Components/SeoMeta';
import { useGetWaterSources, useDeleteWaterSource, useUpdateWaterSource } from 'apis/water-sources';
import {
    cities, OwnerShipTypes, waterSources, Saltiness,
} from 'shared/constants';
import DynamicTable from 'Components/DynamicTable';

import ListWaterSources from '../ListWaterSources';
import { AuthContext } from '../../shared/AuthProvider';

function WaterMgmt({
    land,
}) {
    const deleteWaterSource = useDeleteWaterSource();
    const updateWaterSource = useUpdateWaterSource();
    const { user } = useContext(AuthContext);

    const getLands = () => {
        if (land) {
            return [land.id];
        }
        return null;
    };

    const {
        data: entities,
        isLoading: isLoadingEntities,
        refetch: refetchEntities,
    } = useGetWaterSources(null, getLands());
    const [isWaterDrawerOpen, setIsWaterDrawerOpen] = useState(false);
    const [isWaterSourceSelectionOpen, setIsWaterSourceSelectionOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState();
    // isOpen={isWaterSourceSelectionOpen} onClose={closeWaterSourceSelectionOpen} handleSelectionChange={handleWaterSourceSelection

    const closeWaterSourceSelectionOpen = () => {
        setIsWaterSourceSelectionOpen(false);
    };

    const handleFinish = async (source) => {
        let { lands } = source;
        if (!lands) {
            lands = [];
        }
        lands.push(land.id);

        const sourceData = { ...source };
        if (sourceData.beneficiaries) {
            // Increment beneficiaries if it exists
            const value = sourceData.beneficiaries;
            sourceData.beneficiaries = value + 1;
        } else {
            // Create beneficiaries with a value of one if it doesn't exist
            sourceData.beneficiaries = 1;
        }

        sourceData.lands = lands;

        try {
            if (land && source) {
                await updateWaterSource.mutateAsync({
                    data: { ...sourceData },
                    waterSourceId: source?.id,
                    userUid: user?.uid,
                });
            }
            message.success('تم الحفظ');
        } catch (e) {
            message.error('لقد حصل خطأ ما');
        } finally {
            // onSubmit?.(data);
        }
    };

    const handleWaterSourceSelection = async (source) => {
        await handleFinish(source);
        closeWaterSourceSelectionOpen();
    };

    const detachFromLand = async (source) => {
        const sourceData = { ...source };

        const { lands } = source;
        if (!lands) {
            sourceData.lands = [];
        } else {
            sourceData.lands = lands.filter((landId) => landId !== land.id);
        }
        if (sourceData.beneficiaries) {
            // Increment beneficiaries if it exists
            const value = sourceData.beneficiaries;
            sourceData.beneficiaries = value - 1;
        } else {
            // Create beneficiaries with a value of one if it doesn't exist
            sourceData.beneficiaries = 1;
        }

        try {
            if (land && source) {
                await updateWaterSource.mutateAsync({
                    data: { ...sourceData },
                    waterSourceId: source?.id,
                    userUid: user?.uid,
                });
            }
            message.success('تم الحفظ');
        } catch (e) {
            message.error('لقد حصل خطأ ما');
        } finally {
            // onSubmit?.(data);
        }
    };

    const doDeleteWater = async (id) => {
        try {
            await deleteWaterSource.mutateAsync(id);
            message.success('تم الحذف');
            refetchEntities();
        } catch (err) {
            message.error('لقد حصل خطأ ما');
        }
    };

    const columns = [
        {
            title: 'اسم المصدر',
            dataIndex: 'name',
            render: (value, record) => (
                <Button
                    type="text"
                    onClick={() => {
                        setEntryToEdit(record);
                        setIsWaterDrawerOpen(true);
                    }}
                >
                    {value}
                </Button>
            ),
        },
        {
            title: 'نوع المصدر',
            dataIndex: 'type',
            render: (value) => find(waterSources, (entry) => entry.id?.toString() === value)?.name,
        },
        {
            title: 'الموقع',
            dataIndex: 'city',
            render: (value) => find(cities, { id: value })?.arabic,
        },
        {
            title: 'الملكية',
            dataIndex: 'ownershipType',
            render: (value) => find(OwnerShipTypes, { id: value })?.name,
        },
        {
            title: 'اسم المالك',
            dataIndex: 'owner',
        },
        {
            title: 'كمية الضخ (بالكوب)',
            dataIndex: 'influx',
        },
        {
            title: 'عدد المستفيدين',
            dataIndex: 'beneficiaries',
        },
        {
            title: 'الملوحة',
            dataIndex: 'saltiness',
            render: (value) => find(Saltiness, (entry) => entry.id?.toString() === value)?.name,
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            // eslint-disable-next-line no-unused-vars
            render: (value, record) => {
                const items = [
                    {
                        key: '1',
                        label: 'حفظ',
                        onClick: () => {
                            setEntryToEdit(record);
                            setIsWaterDrawerOpen(true);
                        },
                    },
                    {
                        key: '2',
                        label: 'حذف',
                        onClick: async () => {
                            Modal.confirm({
                                title: 'هل أنت متأكد من الحذف؟',
                                okText: 'نعم',
                                okType: 'danger',
                                cancelText: 'لا',
                                onOk() {
                                    doDeleteWater(record.id);
                                },
                            });
                        },
                    },
                    {
                        key: '3',
                        label: 'فصل مصدر المياه عن الأرض',
                        onClick: async () => {
                            Modal.confirm({
                                title: 'هل أنت متأكد من الحذف؟',
                                okText: 'نعم',
                                okType: 'danger',
                                cancelText: 'لا',
                                onOk() {
                                    detachFromLand(record);
                                },
                            });
                        },
                    },
                ];
                return (
                    <Dropdown menu={{ items }}>
                        <Button type="text" icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    const onSubmit = async () => {
        refetchEntities();
        setIsWaterDrawerOpen(false);
        setEntryToEdit(null);
    };

    const onCloseCreateWaterSource = () => {
        setIsWaterDrawerOpen(false);
        setEntryToEdit(null);
    };

    return (
        <>
            <SeoMeta title="مصادر المياه" />
            <div style={{ overflow: 'auto', height: '100%' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <DynamicTable
                        tableType="pro"
                        searchable
                        search={false}
                        columns={columns}
                        dataSource={entities}
                        rowKey="id"
                        loading={isLoadingEntities}
                        toolBarRender={() => [
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    setIsWaterSourceSelectionOpen(true);
                                }}
                                type="primary"
                            >
                                إضافة من القائمة
                            </Button>,
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    // actionRef.current?.reload();
                                    setIsWaterDrawerOpen(true);
                                }}
                                type="primary"
                            >
                                اضف جديد
                            </Button>,
                        ]}
                    />
                </Space>
                <ListWaterSources isOpen={isWaterSourceSelectionOpen} onClose={closeWaterSourceSelectionOpen} handleSelectionChange={handleWaterSourceSelection} />
            </div>
            <FullWaterSourceDrawer
                width="calc(100% - 200px)"
                entry={entryToEdit}
                land={land}
                isOpen={isWaterDrawerOpen}
                onSubmit={onSubmit}
                onClose={onCloseCreateWaterSource}
            />
        </>
    );
}

export default WaterMgmt;
