import React from 'react';
import './styles.scss';

function DrawerContent({
    mainContent, sideBarContent,
}) {
    return (
        <div className="LandProfileDashboard">
            {sideBarContent && (
                <div className="Sidebar">
                    {sideBarContent}
                </div>
            )}
            <div className={`MainContent ${!sideBarContent ? 'fullWidth' : ''}`}>
                {mainContent}
            </div>
        </div>
    );
}

export default DrawerContent;
