import React, { useState, useEffect } from 'react';
import {
    Space,
} from 'antd';

import SeoMeta from 'Components/SeoMeta';

function PestInformationMgmt({
    selectedPest,
}) {
    const [name, setName] = useState(selectedPest?.id);
    const [fight, setFight] = useState(selectedPest?.fight);

    useEffect(() => {
        if (selectedPest) {
            setName(selectedPest.name);
            setFight(selectedPest.fight);
        }
    }, [selectedPest]);

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <div>
                    {name}
                </div>
                {fight}
            </Space>
        </>

    );
}

export default PestInformationMgmt;
