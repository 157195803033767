import React, { useState, useContext, useEffect } from 'react';
import {
    message,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from '../FormFields/TextField';
import SelectField from '../FormFields/SelectField';
import { PestsFamilies } from '../../shared/constants';
import { AuthContext } from '../../shared/AuthProvider';
import { useCreatePest, useUpdatePest } from '../../apis/pests';
import CustomForm from '../CustomForm';

function PestForm({
    onComplete, entry, profileId,
}) {
    const { user } = useContext(AuthContext);
    const createPest = useCreatePest();
    const updatePest = useUpdatePest();
    const [subCropTypes, setSubCropTypes] = useState([]);

    const schema = yup.object().shape({
        name: yup
            .string()
            .required('الرجاء إدخال اسم الآفة'),
        scientificName: yup
            .string()
            .required('الرجاء إدخال الاسم العلمي للآفة'),
    });
    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name || '',
            scientificName: entry?.scientificName,
            family: entry?.family,
        },
    });

    useEffect(() => {
        if (entry) {
            if (entry.subCropTypes) {
                setSubCropTypes(entry.subCropTypes);
            } else {
                setSubCropTypes([]);
            }
        }
    }, [entry]);

    const handleFinish = async (data) => {
        try {
            // setValue('subCropType', selectedSubCropType);
            setValue('subCropTypes', subCropTypes);
            const idsOnly = subCropTypes.map((subCropType) => subCropType.id);
            setValue('subCropTypesIds', idsOnly);

            if (!entry) {
                await createPest.mutateAsync({
                    data,
                    profileId,
                    userUid: user?.uid,
                });
                message.success('تم إنشاء الآفات بنجاح');
            } else {
                await updatePest.mutateAsync({
                    data,
                    pestId: entry.id,
                    userUid: user?.uid,
                });
                message.success('تم بنجاح');
            }
        } catch (e) {
            message.error('Error!');
        } finally {
            onComplete(data);
        }
    };

    return (
        <CustomForm
            form={form}
            // layout="vertical"
            onFinish={handleSubmit(handleFinish)}
            loading={createPest.isLoading || updatePest.isLoading}
            reset={reset}
            submitText={entry?.id ? 'حفظ' : 'انشاء'}
            editable={Boolean(entry?.id)}
            fullForm
        >
            <TextField
                label="اسم الآفة"
                control={control}
                placeholder="أدخل اسم الآفة"
                fieldName="name"
                errors={errors}
            />
            <TextField
                label="الاسم العلمي"
                control={control}
                placeholder="أدخل الاسم العلمي"
                fieldName="scientificName"
                errors={errors}
            />
            <SelectField
                search
                label="عائلة الآفات"
                control={control}
                fieldName="family"
                errors={errors}
                options={PestsFamilies}
            />

        </CustomForm>
    );
}

export default PestForm;
