import React from 'react';
import { Select, Progress } from 'antd';
import { red, green } from '@ant-design/colors';
import { useController } from 'react-hook-form';

const { Option } = Select;

function DangerPercentageSelect({ name, control }) { // Pass name and control
    const { field } = useController({ name, control });

    const getProgressColor = (percentage) => {
        if (percentage <= 33) {
            return green[6];
        } if (percentage <= 66) {
            return '#faad14'; // Orange
        }
        return red[5];
    };

    const renderOption = (percentage) => (
        <div>
            {/* <span style={{ marginRight: 8 }}>{percentage}</span> */}
            <Progress
                percent={percentage.split('%')[0]}
                steps={10}
                strokeColor={`${getProgressColor(percentage)}`}
                success={{ percent: 0 }}
                // strokeColor={[green[6], green[6], red[5]]}

            />
        </div>
    );

    return (
        <Select
            {...field} // Spread the field properties from useController
            defaultValue="0"
            style={{ width: 250 }}
        >
            <Option value="0">{renderOption('0%', 0)}</Option>
            <Option value="25">{renderOption('25%', 25)}</Option>
            <Option value="50">{renderOption('50%', 50)}</Option>
            <Option value="75">{renderOption('75%', 75)}</Option>
            <Option value="100">{renderOption('100%', 100)}</Option>
        </Select>
    );
}

export default DangerPercentageSelect;
