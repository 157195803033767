import { Form, AutoComplete, Input } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function AutoCompleteField({
    control, label, placeholder, fieldName, errors, options, handleSearch, onSelect,
}) {
    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <AutoComplete
                        {...field}
                        placeholder={placeholder}
                        onSearch={handleSearch}
                        onSelect={(value, option) => onSelect(value, option)}
                        options={options}
                    >
                        <Input.Search size="large" placeholder={placeholder} enterButton />
                    </AutoComplete>
                )}
            />
        </Form.Item>
    );
}

export default AutoCompleteField;
