import { UserOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import {
    Avatar, Button, Drawer, List, Space, Spin,
} from 'antd';
import Search from 'antd/es/input/Search';

// import _ from 'lodash';
//
// import { useListProfiles } from '../../apis/profiles';
// import { ProfileTypes } from '../../shared/constants';
// import {useContext} from "@types/react";
import { ProfilesContext } from '../../shared/ProfilesProvider';

const IconText = function IconText({ icon: Icon, text }) {
    return (
        <Space>
            {Icon && React.createElement(Icon)}
            {text}
        </Space>
    );
};

const getUsersList = (entities, isLoading, handleSelectionChange) => (
    <div>
        {isLoading ? (
            // Render a loading indicator or message while data is being fetched
            <Spin size="large" />
        ) : (
            // Render the list when data is available
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 5,
                }}
                dataSource={entities}
                footer={
                    (
                        <div>
                            <b>الرجاء تحديد نوع المحاصيل</b>
                        </div>
                    )
                }
                header={
                    (
                        <div>
                            <Space direction="vertical">
                                <Space direction="vertical">
                                    <Search
                                        placeholder="input search text"
                                        allowClear
                                        style={{
                                            width: 200,
                                        }}
                                    />
                                </Space>
                                <Space>
                                    <div>
                                        <b>المستخدم</b>
                                    </div>
                                </Space>
                            </Space>
                        </div>
                    )
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        actions={[
                            <IconText icon={UserOutlined} text={item.type} key="list-vertical-like-o" />,
                        ]}
                        extra={
                            (
                                <div>Active</div>
                            )
                        }
                        onClick={() => handleSelectionChange(item.value)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.photoURL} />}
                            title={<a href={item.url}>{item.label}</a>}
                            description={item.description}
                        />
                        {item.content}
                    </List.Item>
                )}
            />
        )}
    </div>
);

export default function ListUsers({
    onClose, isOpen, width, handleSelectionChange,
}) {
    const { peopleList, isLoading } = useContext(ProfilesContext);

    // const { data: profiles, isLoading } = useListProfiles([], 0);

    // const mappedProfiles = useMemo(() => profiles?.map((entry) => ({
    //     id: entry.id,
    //     key: entry.id,
    //     name: entry.name,
    //     type: _.find(ProfileTypes, { id: entry.profileType })?.name,
    //     profileType: entry.profileType,
    // })), [profiles]);

    return (
        <Drawer
            title="إدارة الأراضي/اختيار مالك الأرض"
            width={width}
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
        >
            {getUsersList(peopleList, isLoading, handleSelectionChange)}
        </Drawer>
    );
}
