import SeoMeta from 'Components/SeoMeta';

import PestsReportsMgmt from '../../../Components/PestsReportsMgmt';

function PestsReports() {
    return (
        <>
            <SeoMeta title="إدارة الآفات" />
            <PestsReportsMgmt />
        </>

    );
}

export default PestsReports;
