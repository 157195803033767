import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Progress, Space } from 'antd';
import dayjs from 'dayjs';

const { Meta } = Card;

function CropsCardView({
    dataSource,
}) {
    const calculatePassPercent = (plantingDate, plantingExpectedEndDate) => {
        if (!plantingDate || !plantingExpectedEndDate) {
            return 0;
        }

        // Calculate the percentage of time passed
        const now = dayjs();
        const totalDuration = dayjs(plantingExpectedEndDate.toDate()).diff(plantingDate.toDate());
        const timePassed = now.diff(plantingDate.toDate());
        const growthPercent = Math.round((timePassed / totalDuration) * 100);
        return growthPercent;
    };

    const daysLeftForCrop = (plantingDate, plantingExpectedEndDate) => {
        if (!plantingDate || !plantingExpectedEndDate) {
            return 0;
        }

        const now = dayjs();
        const daysLeft = dayjs(plantingExpectedEndDate.toDate()).diff(now, 'day');

        return daysLeft;
    };

    const renderCard = (item) => (
        <Card
            key={item?.id} // Assuming each item in the dataSource has a unique id
            style={{ width: '100%' }}
            actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
            ]}
        >
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Space
                    direction="horizontal"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Meta
                            title={item?.subCropTypeName || 'Crop name'}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>زراعه مفتوحه</div>
                            <div>
                                <span style={{ color: 'blue' }}>
                                    [
                                    {item.plantingDetails && item.plantingDetails.cityDetails
                                        ? item.plantingDetails.cityDetails.plantedArea
                                        : 'N/A'}
                                    ]
                                </span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>محاصيل المقدرة</div>
                            <div>
                                <span style={{ color: 'blue' }}>
                                    [
                                    {item?.estimatedYield}
                                    ]
                                </span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>يوما للحصاد</div>
                            <div>
                                <span style={{ color: 'blue' }}>
                                    [
                                    {daysLeftForCrop(item.plantingDate, item.plantingExpectedEndDate)}
                                    ]
                                </span>
                            </div>
                        </div>

                    </Space>
                    <Space><div>&nbsp;</div></Space>
                    <Space
                        direction="vertical"
                        size="left"
                        style={{ display: 'flex' }}
                    >
                        <Progress type="circle" percent={calculatePassPercent(item.plantingDate, item.plantingExpectedEndDate)} size={[100, 100]} />
                    </Space>
                </Space>
                <Space>
                    <div style={{ display: 'flex', width: '300px', justifyContent: 'space-between' }}>
                        <Progress percent={calculatePassPercent(item.plantingDate, item.plantingExpectedEndDate)} />
                    </div>
                </Space>
            </Space>
            {/* Rest of your card content */}
        </Card>
    );

    const renderCardPairs = () => {
        if (!dataSource || dataSource.length === 0) {
            return <p>No data available</p>;
        }

        const pairs = [];
        for (let i = 0; i < dataSource.length; i += 2) {
            const card1 = dataSource[i];
            const card2 = dataSource[i + 1];

            pairs.push(
                <div key={`card-pair-${i}`} style={{ display: 'flex', gap: '16px' }}>
                    {renderCard(card1)}
                    {renderCard(card2)}
                </div>,
            );
        }

        return pairs;
    };

    return (
        <div>
            {renderCardPairs()}
        </div>
    );
}

export default CropsCardView;
