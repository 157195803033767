import * as dayjs from 'dayjs';
import {
    useQuery, useMutation,
} from '@tanstack/react-query';
import {
    doc, getDoc, updateDoc, collection, addDoc, where, limit, startAfter, getDocs, query, deleteDoc,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetLands = (profiles, owner, profileType, offset = 0, queryLimit = 9999) => useQuery(['useGetLands', {
    profiles, owner, profileType, queryLimit, offset,
}], async () => {
    const entries = [];

    const landsRef = collection(firebaseFirestore, DbTableNames.lands);
    const queryParams = [landsRef];

    if (profiles && profiles.length > 0) {
        queryParams.push(where('profiles', 'in', profiles));
    }

    if (profileType) {
        queryParams.push(where('profileType', '==', profileType));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    if (owner) {
        queryParams.push(where('owner.id', '==', owner.id));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetLand = (landId) => useQuery(
    ['useGetLand', landId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.lands, landId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!landId,
    },
);

export const useUpdateLand = () => useMutation({
    mutationFn: async ({ data, landId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.lands, landId), updateData);
        return {
            ...data,
        };
    },
});

export const useCreateLand = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.lands), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useDeleteLand = () => useMutation({
    mutationFn: async (id) => {
        await deleteDoc(doc(firebaseFirestore, DbTableNames.lands, id));
    },
});
