import React from 'react';
import { Button } from 'antd';

import WajedModal from '../WajedModal';
import CropAggregationDashboard from '../CropAggregationDashboard';

import './styles.scss';

export default function FullCropTypesAggregationMgmt({
    onClose, isOpen, width, entry, onComplete, profileEntry,
}) {
    const onOpenFinished = () => {
        console.log('Opened');
    };

    const [subTitle, setSubTitle] = React.useState('');

    return (
        <WajedModal
            title="إدارة الأراضي"
            subTitle={subTitle}
            width={width}
            onClose={onClose}
            open={isOpen}
            isOpen={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>إغلاق</Button>}
            destroyOnClose
            placement="left"
            onOpenFinished={onOpenFinished}
            closable={false}
            id="full-crops-drawer-aggregation"
        >
            <CropAggregationDashboard entry={entry} onComplete={onComplete} profileEntry={profileEntry} setSubTitle={setSubTitle} />
        </WajedModal>
    );
}
