import React, { useEffect, useContext, useState } from 'react';
import {
    Button, Form, message, Space, Switch,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from '../FormFields/TextField';
import TextAreaField from '../FormFields/TextAreaField';
import { useCreateSubCropType } from '../../apis/sub-crop-types';
import { AuthContext } from '../../shared/AuthProvider';
import ListCropTypes from '../ListCropTypes';

const schema = yup.object().shape({
    name: yup.string().required('العائلة مطلوبة'),
    cropType: yup.string().required('العائلة مطلوبة'),
});

export default function CreateEditSubCropTypeForm({
    entry, onComplete, onClose, profileId, cropType,
}) {
    const { user } = useContext(AuthContext);
    const createSubCropType = useCreateSubCropType();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedCropType, setSelectedCropType] = useState(null);

    const {
        form,
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: entry?.name,
            cropType: entry?.cropType,
        },
    });

    const handleFinish = async (data) => {
        let createdSubCropType;
        try {
            if (!cropType) {
                setValue('cropTypeId', selectedCropType.id);
                setValue('cropTypeName', selectedCropType.name);
                setValue('family', selectedCropType.family);
            }
            createdSubCropType = await createSubCropType.mutateAsync({
                data,
                profileId,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
        } finally {
            if (onComplete) {
                onComplete(createdSubCropType);
            }

            if (onClose) {
                onClose();
            }
        }
    };

    // Use useEffect to reset the form when entry changes
    useEffect(() => {
        reset({
            name: entry?.name || '',
        });
    }, [entry]);

    useEffect(() => {
        if (cropType) {
            setValue('cropType', cropType.name);
            setValue('cropTypeId', cropType.id);
            setValue('cropTypeName', cropType.name);
            setValue('family', cropType.family);
        }
    }, [cropType]);

    const handleAction = () => {
        setIsDrawerOpen(true);
    };

    const closeSelection = () => {
        setIsDrawerOpen(false);
    };

    const handleSelectionChange = (record) => {
        setSelectedCropType(record);
        setValue('cropType', record.name);
        setIsDrawerOpen(false);
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit(handleFinish)} autocomplete="off">
            <Space direction="horizontal">
                {!cropType ? (
                    <div>
                        <TextField
                            label="نوع صنف"
                            control={control}
                            fieldName="cropType"
                            errors={errors}
                        />
                        <Button type="primary" onClick={handleAction}>
                            اضغط هنا
                        </Button>
                    </div>
                ) : (
                    <TextField
                        label="نوع صنف"
                        control={control}
                        fieldName="cropType"
                        errors={errors}
                        disabled="true"
                    />
                )}
            </Space>
            <TextField
                label="صنف"
                control={control}
                errors={errors}
                placeholder="صنف"
                fieldName="name"
                buttonText="صنف جديد +"
            />
            <ListCropTypes isOpen={isDrawerOpen} onClose={closeSelection} handleSelectionChange={handleSelectionChange} />
            <TextAreaField
                label="مميزات"
                control={control}
                errors={errors}
                placeholder="مميزات"
                fieldName="features"
            />
            <Form.Item label="مخفي">
                <Switch control={control} fieldName="isHidden" defaultValue={false} />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={createSubCropType.isLoading}
                >
                    انشاء
                </Button>
            </Form.Item>
        </Form>
    );
}
