import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    Typography,
    Form,
    Input,
    Button,
    Row,
    Col,
    Checkbox,
    message,
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import {
    GoogleOutlined,
    FacebookOutlined,
} from '@ant-design/icons';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { firebaseAuth } from 'shared/firebase.init';
import { AuthContext } from 'shared/AuthProvider';
import { afterLoginRedirectUrl } from 'shared/constants';
import SeoMeta from 'Components/SeoMeta';
import { useSignInWithEmailAndPassword } from 'apis/auth';

const { Title } = Typography;
const schema = yup.object().shape({
    email: yup.string().required('البريد الإلكتروني مطلوب').email('البريد الإلكتروني غير صالح'),
    password: yup.string().required('كلمة المرور مطلوبة').min(6, 'كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل'),
});

function Login() {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const signInWithEmailAndPassword = useSignInWithEmailAndPassword();

    // If the user is already logged in, redirect them to the dashboard page
    if (user) {
        navigate(afterLoginRedirectUrl); // Replace with your desired route for authenticated users
        return null;
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onLogin = async (data) => {
        try {
            await signInWithEmailAndPassword.mutateAsync(data);
            message.success('Logged in successfully!');
            navigate(afterLoginRedirectUrl);
        } catch {
            message.error('Login failed. Please check your email and password.');
        }
    };

    const onGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(firebaseAuth, provider);
            message.success('Logged in with Google successfully!');
            navigate(afterLoginRedirectUrl);
        } catch (error) {
            message.error('Google login failed. Please try again.');
        }
    };

    return (
        <Row justify="center" align="middle" style={{ height: '80vh' }}>
            <SeoMeta title="تسجيل الدخول" />
            <Col xs={24} md={12} lg={8}>
                <Title level={2} className="text-center mb-4">
                    تسجيل الدخول
                </Title>
                <Form layout="vertical" onFinish={handleSubmit(onLogin)} autocomplete="off">
                    <Form.Item
                        label="البريد الإلكتروني"
                        validateStatus={errors.email ? 'error' : ''}
                        help={errors.email?.message}
                    >
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Input
                                    type="email"
                                    placeholder="أدخل البريد الإلكتروني"
                                    {...field}
                                />
                            )}
                        />
                    </Form.Item>
                    <Form.Item label="كلمة المرور" validateStatus={errors.password ? 'error' : ''} help={errors.password?.message}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <Input.Password placeholder="أدخل كلمة المرور" {...field} />}
                        />
                    </Form.Item>
                    <div style={{ marginBottom: '16px' }}>
                        <Checkbox onChange={() => { }}>
                            احفظ الحساب
                        </Checkbox>
                        <Link style={{ float: 'left' }} to="/forget-password">
                            هل نسيت كلمة المرور؟
                        </Link>
                    </div>
                    <Button type="primary" htmlType="submit" loading={signInWithEmailAndPassword.isLoading} block>
                        {signInWithEmailAndPassword.isLoading ? 'جارٍ تسجيل الدخول...' : 'تسجيل الدخول'}
                    </Button>
                </Form>
                <div style={{ marginTop: '32px', fontSize: '16px' }}>
                    <span style={{ marginLeft: '8px' }}>طرقٌ أخرى</span>
                    <GoogleOutlined onClick={onGoogleLogin} style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '21px' }} />
                    <FacebookOutlined style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '21px' }} />
                </div>
            </Col>
        </Row>
    );
}

export default Login;
