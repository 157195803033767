import React from 'react';
import { Button, Drawer } from 'antd';

import CropTypeForm from '../CropTypeForm';
import CropTypeProfileDashboard from '../CropTypeProfileDashboard';
import WajedModal from '../WajedModal';

export default function FullCropTypeDrawer({
    onClose,
    isOpen,
    width,
    entry,
    onConfirm,
    createFlag,
}) {
    const [subtitle, setSubtitle] = React.useState('');
    return (
        <div>
            {createFlag ? (
                <Drawer
                    title="إدارة الأصناف/أضف صنف نباتات"
                    width="500px"
                    onClose={onClose}
                    open={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={<Button onClick={onClose}>إغلاق</Button>}
                    destroyOnClose
                    placement="left"
                    closable={false}
                >
                    <CropTypeForm entry={entry} onComplete={onConfirm} />
                </Drawer>
            ) : (
                <WajedModal
                    title="إدارة الأصناف/النباتات"
                    subTitle={subtitle}
                    width={width}
                    onClose={onClose}
                    open={isOpen}
                    isOpen={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={<Button onClick={onClose}>إغلاق</Button>}
                    destroyOnClose
                    placement="left"
                    closable={false}
                    id="full-crop-type-drawer"
                >
                    <CropTypeProfileDashboard
                        cropTypeId={entry?.id}
                        entry={entry}
                        setSubtitle={setSubtitle}
                    />
                </WajedModal>
            )}
        </div>
    );
}
