import React, { useState, useEffect } from 'react';
import {
    Space,
} from 'antd';

import SeoMeta from 'Components/SeoMeta';

function PestMediaMgmt({
    selectedPest,
}) {
    const [media, setMedia] = useState(selectedPest?.media);

    useEffect(() => {
        if (selectedPest) {
            setMedia(selectedPest.media);
        }
    }, [selectedPest]);

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                {media}
            </Space>
        </>

    );
}

export default PestMediaMgmt;
