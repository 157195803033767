import {
    message,
    Space,
} from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import UploadableAvatar from '../Media/UploadableAvatar';
import Sources from '../WaterMgmt';
import { useUpdateLand } from '../../apis/lands';
import { AuthContext } from '../../shared/AuthProvider';
import CropsDashboardMgmt from '../CropsDashboardMgmt';
import ProfilesDetails from '../../Pages/UsersManagement/ProfilesDetails';
import DrawerContent from '../DrawerContent';
import './styles.scss';

function CropAggregationDashboard({
    entry, onComplete, profileEntry, setSubTitle = () => null,
}) {
    const queryClient = useQueryClient();
    const [selectedPage, setSelectedPage] = useState(<CropsDashboardMgmt dashboardEntry={entry} onComplete={onComplete} profileEntry={profileEntry} />);
    const updateLand = useUpdateLand();
    const { user } = useContext(AuthContext);
    const [selectedItem, setSelectedItem] = useState('crops');

    const menu = [
        {
            id: 'crops',
            title: 'اصناف',
            onClick: () => {
                setSelectedPage(<CropsDashboardMgmt entry={entry} onComplete={onComplete} profileEntry={profileEntry} />);
                setSelectedItem('crops');
            },
        },
        {
            id: 'benificiaries',
            title: 'المزارعين المستفيدين',
            onClick: () => {
                setSelectedPage(<ProfilesDetails dashboardEntry={entry} />);
                setSelectedItem('benificiaries');
            },
        },
        {
            id: 'traders',
            title: 'التجار',
            onClick: () => {
                setSelectedPage(<Sources land={entry} onComplete={onComplete} />);
                setSelectedItem('traders');
            },
        },
    ];

    const onUploadCompleted = async (subCropType, url) => {
        const newForm = {
            image: url,
        };

        try {
            await updateLand.mutateAsync({
                data: newForm,
                landId: entry?.id,
                userUid: user?.uid,
            });
            message.success('It worked!');
        } catch (e) {
            message.error('Error!');
            console.log(e);
        }
        queryClient.invalidateQueries(['useGetLands']);
    };

    useEffect(() => {
        setSubTitle(menu[0].title);
    }, []);

    return (
        <div className="CropAggregationProfileDashboard">
            <DrawerContent
                sideBarContent={(
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div className="UserDetails">
                            <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                                <div className="Avatar">
                                    <UploadableAvatar image={entry?.image} entry={entry} onComplete={onUploadCompleted} />
                                </div>
                                <div className="Name">{entry?.name}</div>
                            </Space>
                        </div>
                        <div className="Menu">
                            <ul>
                                {
                                    menu.map((item) => (
                                        <li key={item?.id}>
                                            <button
                                                onClick={() => {
                                                    item.onClick();
                                                    setSubTitle(item.title);
                                                }}
                                                type="button"
                                                className={selectedItem === item.id ? 'selected' : ''}
                                            >
                                                {item.title}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Space>
                )}
                mainContent={selectedPage}
            />
        </div>
    );
}

export default CropAggregationDashboard;
