import SeoMeta from 'Components/SeoMeta';

import SubCropTypeMgmt from '../../../Components/SubCropTypeMgmt';

function SubCropTypes() {
    return (
        <>
            <SeoMeta title="تسجيل الدخول" />
            <div>
                <SubCropTypeMgmt />
            </div>
        </>
    );
}

export default SubCropTypes;
