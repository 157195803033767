import {
    Button, Dropdown, Progress, Space,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SeoMeta from 'Components/SeoMeta';
import DynamicTable from 'Components/DynamicTable';

import { plantFamily } from '../../shared/constants';
import { useGetCropsAggregation } from '../../apis/crops-aggregation';
import FullCropsDetailsDrawer from '../FullCropsDetailDrawer';
import ListPlantedCrops from '../ListPlantedCrops';

const _ = require('lodash');

function CropsDashboardMgmt({ dashboardEntry }) {
    const { id } = useParams();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedCropsList, setSelectedCropsList] = useState(null);
    const [entry, setEntry] = useState(null);
    const { data: entities, isLoading, refetch: refetchEntities } = useGetCropsAggregation(null, [id ?? dashboardEntry?.id]);
    const [isPlantedCropsDrawerOpen, setPlantedCropsDrawerOpen] = useState(false);

    const onCreate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
    };

    const handleOnClose = () => {
        setEntry(null);
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        if (entry) {
            setIsDrawerOpen(true);
        }
    }, [entry]);

    const handleShowCropsDetails = () => {
        setPlantedCropsDrawerOpen(true);
    };

    const closeCropsDetails = () => {
        setPlantedCropsDrawerOpen(false);
    };

    const handleSelectionChange = () => {
        setPlantedCropsDrawerOpen(false);
    };

    const handlePlantedAreasDetailsClicked = (data) => {
        setSelectedCropsList(data);
        handleShowCropsDetails();
    };

    const columns = [
        {
            title: 'صنف',
            dataIndex: 'subCropTypeName',
            key: 'subCropTypeName',
        },
        {
            title: 'عائلة زرع',
            dataIndex: 'family',
            key: 'family',
            render: (family) => {
                const plant = _.find(plantFamily, { id: Number(family) });
                return plant ? plant.name : '';
            },
        },
        {
            title: 'مساحة مزروعة',
            dataIndex: 'plantingDetails',
            key: 'plantingDetails',
            render: (plantingDetails) => (
                plantingDetails && plantingDetails.length > 0
                    ? (
                        <span
                            role="button"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => handlePlantedAreasDetailsClicked(plantingDetails)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handlePlantedAreasDetailsClicked(plantingDetails);
                                }
                            }}
                            tabIndex={0}
                        >
                            {`${plantingDetails.reduce((sum, detail) => sum + (detail.cityDetails ? detail.cityDetails.plantedArea || 0 : 0), 0)} Tn`}
                        </span>
                    )
                    : 'N/A' // Or any default text you prefer when the area data is not available
            ),
        },
        {
            title: 'مائة للمحاصيل الأخرى',
            dataIndex: 'plantedPercentage',
            key: 'plantedPercentage',
        },
        {
            title: 'العائد المتوقع',
            key: 'estimatedYield',
            dataIndex: 'estimatedYield',
        },
        {
            title: 'نسبة فائدة البلد',
            key: 'countryPercent',
            dataIndex: 'countryPercent',
        },
        {
            title: 'عدد المزارعين',
            key: 'farmersCount',
            dataIndex: 'farmersCount',
        },
        {
            title: 'نسبة النمو',
            key: 'growthPercent',
            dataIndex: 'growthPercent',
            render: (growthPercent) => (
                <Progress size={[100, 15]} percent={growthPercent} />
            ),
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'عرض الملف',
                                onClick: () => {
                                    setEntry(record);
                                    setIsDrawerOpen(true);
                                },
                            },
                            {
                                key: '2',
                                label: 'حذف',
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <FullCropsDetailsDrawer
                width="calc(100% - 200px)"
                entry={entry}
                onClose={() => handleOnClose()}
                isOpen={isDrawerOpen}
                onConfirm={onCreate}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', overflow: 'auto', height: '100%' }}
            >
                <DynamicTable columns={columns} dataSource={entities} loading={isLoading} rowKey="id" light />
            </Space>
            <ListPlantedCrops isOpen={isPlantedCropsDrawerOpen} onClose={closeCropsDetails} handleSelectionChange={handleSelectionChange} cropsList={selectedCropsList} />
        </>

    );
}

export default CropsDashboardMgmt;
