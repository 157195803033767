import {
    Dropdown, Button, Badge, Avatar, Space, Menu, Progress, Typography,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import SeoMeta from 'Components/SeoMeta';
import CreateProfileDrawer from 'Components/CreateProfileDrawer';
import { useGetProfiles } from 'apis/profiles';
import { ProfileTypes } from 'shared/constants';
import FullProfileDrawer from 'Components/FullProfileDrawer';
import DynamicTable from 'Components/DynamicTable';

import SubscriptionWidget from '../Components/SubscriptionWidget';
import { isValidProfileType } from '../../../utils/validators';

function Profiles() {
    const [currentProfile, setCurrentProfile] = useState(ProfileTypes[0].id);
    const queryClient = useQueryClient();
    const [isCreateProfileOpen, setIsCreateProfileOpen] = useState(false);
    const [isFullProfileDrawerOpen, setIsFullProfileDrawerOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(page - 1);

    const { data: profiles, isLoading, refetch: refetchProfiles } = useGetProfiles(
        [currentProfile],
        current,
        pageSize,
    );

    const changeProfile = (profile) => {
        setPage(1);
        setCurrent(page - 1);
        setPageSize(10);
        setCurrentProfile(profile);
    };

    const mappedProfiles = useMemo(() => profiles?.data?.map((entry) => ({
        id: entry.id,
        key: entry.id,
        name: entry.name,
        age: entry.age ?? 'N/A',
        address: entry.address,
        subscription: {
            state: 'active',
            expirationDate: '01.02.2023',
        },
        image: entry.image,
        email: entry.email,
        profileType: entry.profileType,
    })), [profiles]);

    useEffect(() => {
        console.table(profiles);
        refetchProfiles();
    }, [page, pageSize]);

    const getCityArabicName = (city) => {
        switch (city) {
        case 'hebron-governorate':
            return 'محافظة الخليل';
        case 'jenin-governorate':
            return 'محافظة جنين';
        case 'jericho-governorate':
            return 'محافظة اريحا';
        case 'jerusalem-governorate':
            return 'محافظة القدس';
        case 'nablus-governorate':
            return 'محافظة نابلس';
        case 'qalqiliya-governorate':
            return 'محافظة قلقيلية';
        case 'ramallah_and_al_bireh-governorate':
            return 'محافظة رام الله والبيرة';
        case 'salfit-governorate':
            return 'محافظة سلفيت';
        case 'tubas-governorate':
            return 'محافظة طوباس';
        case 'tulkarem-governorate':
            return 'محافظة طولكرم';
        default:
            return '';
        }
    };

    const openDetailsPage = (record) => {
        setEntryToEdit(record);
        setIsFullProfileDrawerOpen(true);
    };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src={record?.image ? record.image : 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'}
                        />
                    </Badge>
                    <Typography.Link
                        style={{
                            marginRight: '5px', fontSize: '18px', color: '#131313', fontWeight: 'bold',
                        }}
                        onClick={() => openDetailsPage(record)}
                    >
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{text}</div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.email}</div>
                    </Typography.Link>
                </div>
            ),
        },
        {
            title: 'العنوان',
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{getCityArabicName(text?.city?.parent)}</div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{text?.city?.arabic}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'معلومات',
            dataIndex: 'infoPercentage',
            key: 'infoPercentage',
            render: (value) => (
                <Progress percent={value} />
            ),
        },
        {
            title: 'مراسلات',
            dataIndex: 'address2',
            key: 'address2',
        },
        {
            title: 'الاشتراك',
            key: 'subscription',
            dataIndex: 'subscription',
            render: (entry) => (
                <SubscriptionWidget subscription={entry} />
            ),
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                setEntryToEdit(record);
                                setIsFullProfileDrawerOpen(true);
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدراة الملفات" />
            <div>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <Menu
                        onClick={(newKey) => { changeProfile(newKey.key); }}
                        selectedKeys={[currentProfile]}
                        mode="horizontal"
                        items={ProfileTypes.map((entry) => ({ label: entry.name, key: entry.id }))}
                    />
                    <Button type="primary" onClick={() => { setIsCreateProfileOpen(true); }}>إنشاء جديد</Button>
                    <DynamicTable
                        columns={columns}
                        dataSource={mappedProfiles}
                        loading={isLoading}
                        pagination={{
                            total: profiles?.total,
                            current: page,
                            pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: (newPage, pageSizeNew) => {
                                setPage(newPage);
                                setCurrent(newPage - 1);
                                setPageSize(pageSizeNew);
                            },
                        }}
                    />
                </Space>
            </div>
            <CreateProfileDrawer
                isOpen={isCreateProfileOpen}
                onClose={() => setIsCreateProfileOpen(false)}
                prefetchedType={isValidProfileType(currentProfile) && currentProfile}
                onComplete={(createElement) => {
                    if (createElement) {
                        setEntryToEdit(createElement);
                        setIsFullProfileDrawerOpen(true);
                    }
                    queryClient.invalidateQueries(['profiles']);
                }}
            />
            <FullProfileDrawer
                isOpen={isFullProfileDrawerOpen}
                onClose={() => setIsFullProfileDrawerOpen(false)}
                entry={entryToEdit}
                width="calc(100%)"
            />
        </>
    );
}

export default Profiles;
