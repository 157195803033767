import React from 'react';
import {
    Input, Button, Form, Space,
} from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function PropertyField({
    control, label1, placeholder1, fieldName1, label2, placeholder2, fieldName2, errors, removeFunction,
}) {
    const handleRemoveRange = () => {
        // Handle adding the range here, e.g., send it to a function or component state.
        if (removeFunction) {
            removeFunction();
        }
    };

    return (
        <Space
            style={{ display: 'flex', marginBottom: 8 }}
            direction="horizontal"
            align="baseline"
        >
            <Form.Item
                label={label1}
                validateStatus={get(errors, fieldName1) ? 'error' : ''}
                help={get(errors, fieldName1)?.message}
            >
                <Controller
                    control={control}
                    name={fieldName1}
                    render={({ field }) => (
                        <Input
                            {...field}
                            placeholder={placeholder1}
                            onChange={(e) => {
                                field.onChange(e); // Update the value in React Hook Form
                            }}
                        />
                    )}
                />
            </Form.Item>
            <Form.Item
                label={label2}
                validateStatus={get(errors, fieldName2) ? 'error' : ''}
                help={get(errors, fieldName2)?.message}
            >
                <Controller
                    control={control}
                    name={fieldName2}
                    render={({ field }) => (
                        <Input
                            {...field}
                            placeholder={placeholder2}
                            onChange={(e) => {
                                field.onChange(e); // Update the value in React Hook Form
                            }}
                        />
                    )}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" icon={<MinusOutlined />} onClick={handleRemoveRange} />
            </Form.Item>
        </Space>
    );
}

export default PropertyField;
