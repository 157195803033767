import React, { } from 'react';
import { Modal, Form, Input } from 'antd';

function CreateLinkDialog({ visible, onCreate, onCancel }) {
    const [form] = Form.useForm();

    const handleCreate = () => {
        form
            .validateFields()
            .then((values) => {
                onCreate(values);
                form.resetFields();
            })
            .catch(() => {
            });
    };

    return (
        <Modal
            open={visible}
            title="أضف الرابط والوصف"
            okText="ضيف"
            cancelText="لغي"
            onCancel={onCancel}
            onOk={handleCreate}
        >
            <Form form={form} layout="vertical" autocomplete="off">
                <Form.Item
                    name="link"
                    label="الرابط"
                    rules={[
                        {
                            required: true,
                            message: 'الرجاء إدخال الرابط',
                        },
                    ]}
                >
                    <Input placeholder="أدخل الرابط" />
                </Form.Item>
                <Form.Item
                    name="title"
                    label="عنوان"
                    rules={[
                        {
                            required: true,
                            message: 'الرجاء إدخال عنوان',
                        },
                    ]}
                >
                    <Input placeholder="أدخل عنوان" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="وصف"
                    rules={[
                        {
                            required: true,
                            message: 'الرجاء إدخال الوصف',
                        },
                    ]}
                >
                    <Input.TextArea placeholder="أدخل الوصف" rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateLinkDialog;
