import { useState } from 'react';
import { Form, DatePicker } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';
import * as dayjs from 'dayjs';

function DatePickerField({
    control, label, placeholder, fieldName, errors, onChange,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOnChange = (text) => {
        if (onChange) {
            onChange?.(text);
        }
    };
    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        placeholder={placeholder}
                        value={field.value && dayjs(field.value).isValid() ? dayjs(field.value) : null}
                        onChange={(date, dateString) => {
                            let value = new Date();
                            try {
                                value = new Date(dateString);
                            } catch (err) {
                                value = new Date();
                            }
                            field.onChange(value); // Update the value in React Hook Form
                            handleOnChange(value);
                        }}
                        onOpenChange={(open) => setIsOpen(open)}
                        popupStyle={
                            // a work around to avoid date picker bug (always active even after close)
                            isOpen
                                ? {
                                    display: null,
                                } : {
                                    display: 'none',
                                }
                        }
                    />
                )}
            />
        </Form.Item>
    );
}

export default DatePickerField;
