import citiesJson from './cities.json';

export const AcademicQualifications = {
    bachelor: 'bachelor',
    master: 'master',
    doctoral: 'doctoral',
};

export const AcademicQualificationsNames = {
    [AcademicQualifications.bachelor]: 'Bachelor\'s degree',
    [AcademicQualifications.master]: 'Master\'s degree',
    [AcademicQualifications.doctoral]: 'Doctoral\'s degree',
};

export const cities = citiesJson;

export const ContactType = {
    phone: 'phone',
    mobile: 'mobile',
    fax: 'fax',
    email: 'email',
};

export const ContactTypeName = {
    phone: 'هاتف أرضي',
    mobile: 'هاتف محمول',
    fax: 'فاكس',
    email: 'بريد إلكتروني',
};

export const SocialMediaType = {
    facebook: 'facebook',
    tiktok: 'tiktok',
    twitter: 'twitter',
    instagram: 'instagram',
    telegram: 'telegram',
    website: 'website',
    other: 'other',
};

export const SocialMediaTypeName = {
    facebook: 'فيسبوك',
    tiktok: 'تكتوك',
    twitter: 'تويتر',
    instagram: 'انستجرام',
    telegram: 'تلجرام',
    website: 'موقع إالكتروني',
    other: 'أخرى',
};

export const Gender = {
    male: 'male',
    female: 'female',
};

export const GenderName = {
    [Gender.male]: 'ذكر',
    [Gender.female]: 'أنثى',
};

export const OwnerShipType = {
    facebook: 'facebook',
    tiktok: 'tiktok',
    twitter: 'twitter',
    instagram: 'instagram',
    telegram: 'telegram',
    website: 'website',
    other: 'other',
};

export const soilTypes = [
    {
        value: '1',
        eng_name: 'Sandy',
        label: 'رملي',
    },
    {
        value: '2',
        eng_name: 'Rocky',
        label: 'صخري',
    },
    {
        value: '3',
        eng_name: 'Clayey',
        label: 'طيني',
    },
    {
        value: '4',
        eng_name: 'Loamy',
        label: 'لوحي',
    },
    {
        value: '5',
        eng_name: 'Calcareous Soil',
        label: 'تربة جيرية',
    },
    {
        value: '6',
        eng_name: 'Acidic Soil',
        label: 'تربة حمضية ',
    },
    {
        value: '7',
        eng_name: 'Alkaline Soil',
        label: 'تربة قلوية ',
    },
    {
        value: '8',
        eng_name: 'Silty Clay',
        label: 'تربة طينية رملية ',
    },
    {
        value: '9',
        eng_name: 'Sandy Clay',
        label: 'ربة رملية طينية',
    },
    {
        value: '10',
        eng_name: 'Sandy Loam',
        label: 'تربة رملية لوحية',
    },
];

export const landTypes = [
    {
        value: '1',
        eng_name: 'Agricultural Land',
        label: 'أرض زراعية',
    },
    {
        value: '2',
        eng_name: 'Residential Land',
        label: 'أرض سكنية',
    },
    {
        value: '3',
        eng_name: 'Industrial Land',
        label: 'أرض صناعية',
    },
    {
        value: '4',
        eng_name: 'Commercial Land',
        label: 'أرض تجارية',
    },
    {
        value: '5',
        eng_name: 'Natural Land',
        label: 'أرض طبيعية',
    },
    {
        value: '6',
        eng_name: 'Urban Land',
        label: 'أرض حضرية',
    },
    {
        value: '7',
        eng_name: 'Forest Land',
        label: 'أرض غابات',
    },
    {
        value: '8',
        eng_name: 'Mountainous Land',
        label: 'أرض جبلية',
    },
    {
        value: '9',
        eng_name: 'Desert Land',
        label: 'أرض صحراوية',
    },
    {
        value: '10',
        eng_name: 'Waterfront Land',
        label: 'أرض مائية',
    },
];

export const landFeatures = [
    {
        value: 1,
        eng_name: 'Small Stones',
        label: 'صغار الحصى',
    },
    {
        value: 2,
        eng_name: 'Small Gravel',
        label: 'زلال الأرض',
    },
    {
        value: 3,
        eng_name: 'Shingle',
        label: 'حصمة',
    },
    {
        value: 4,
        eng_name: 'Fine Gravel',
        label: 'حصى دقيقة',
    },
    {
        value: 5,
        eng_name: 'Sandy Gravel',
        label: 'رمل حصوي',
    },
    {
        value: 6,
        eng_name: 'Smooth Stones',
        label: 'حصى ناعمة',
    },
];

export const OwnerShipTypes = [
    {
        id: 'Individual',
        name: 'الفردية',
    },
    {
        id: 'Joint',
        name: 'المشتركة',
    },
    {
        id: 'Collective',
        name: 'الجماعية',
    },
    {
        id: 'Family',
        name: 'العائلية',
    },
    {
        id: 'Public',
        name: 'العامة',
    },
    {
        id: 'Private',
        name: ' الخاصة',
    },
    {
        id: 'Commercial',
        name: 'التجارية',
    },
    {
        id: 'Industrial',
        name: 'الصناعية',
    },
    {
        id: 'Government',
        name: ' الحكومية',
    },
    {
        id: 'Agricultural',
        name: ' الزراعية',
    },
];

export const plantFamily = [
    {
        value: '1',
        name: 'ورقيات',
        label: 'الخضراوات', // Leafy under Vegetable Types
    },
    {
        value: '2',
        name: 'حمضيات',
        label: 'حمضيات', // Citrus under Fruit Types
    },
    {
        value: '3',
        name: 'فواكه',
        label: 'الفواكه', // Fruits under Fruit Types
    },
    {
        value: '4',
        name: 'ثمارية',
        label: 'ثمارية', // Berries under Fruit Types
    },
    {
        value: '5',
        name: 'بقولية',
        label: 'بقولية', // Legumes under Vegetable Types
    },
    {
        value: '6',
        name: 'معمرة',
        label: 'معمرة', // Perennial under Perennial Plant Types
    },
    {
        value: '7',
        name: 'موسمية',
        label: 'موسمية', // Seasonal under Seasonal Plant Types
    },
    {
        value: '8',
        name: 'حبوب',
        label: 'حبوب', // Grains under Crop Types
    },
    {
        value: '9',
        name: 'وردية',
        label: 'الزهور', // Roses under Flower Types
    },
    {
        value: '10',
        name: 'جذرية',
        label: 'جذرية', // Root Vegetables under Vegetable Types
    },
    // Add more plant types and categories as needed
];

export const waterSources = [
    {
        id: 1,
        name: 'ماء الأمطار',
        english_name: 'Rainwater', // Leafy under Vegetable Types
    },
    {
        id: 2,
        name: 'نهر',
        english_name: 'River', // Fruity under Vegetable Types
    },
    {
        id: 3,
        name: 'بحيرة',
        english_name: 'Lake', // Leguminous under Vegetable Types
    },
    {
        id: 4,
        name: 'ينبوع',
        english_name: 'Spring', // Perennial under Plant Types
    },
    {
        id: 5,
        name: 'بئر',
        english_name: 'Well', // Seasonal under Plant Types
    },
];

export const fertilizerNames = [
    {
        id: 1,
        name: 'سماد النيتروجين',
        enhName: 'Nitrogen Fertilizer',
    },
    {
        id: 2,
        name: 'الفوسفور',
        enhName: 'Phosphorus Fertilizer',
    },
    {
        id: 3,
        name: 'البوتاسيوم',
        enhName: 'Potassium Fertilizer',
    },
    {
        id: 4,
        name: 'الزنك',
        enhName: 'Zinc Fertilizer',
    },
    {
        id: 5,
        name: 'الحديد',
        enhName: 'Iron Fertilizer',
    },
    {
        id: 6,
        name: 'الكالسيوم',
        enhName: 'Calcium Fertilizer',
    },
    {
        id: 7,
        name: 'المغنيسيوم',
        enhName: 'Magnesium Fertilizer',
    },
    {
        id: 8,
        name: 'الكبريت',
        enhName: 'Sulfur Fertilizer',
    },
    {
        id: 9,
        name: 'البورون',
        enhName: 'Boron Fertilizer',
    },
    {
        id: 10,
        name: 'النحاس',
        enhName: 'Copper Fertilizer',
    },
];

export const afterLoginRedirectUrl = '/dashboard';

export const ProfileTypes = [
    {
        id: 'all',
        name: 'الكل',
        showInForms: false,
    },
    {
        id: 'people',
        name: 'الأفراد',
        showInForms: true,
    },
    // {
    //     id: 'merchants',
    //     name: 'التجار',
    //     showInForms: true,
    // },
    // {
    //     id: 'agricultural_institutions',
    //     name: 'مؤسسات زراعية',
    // },
    {
        id: 'companies',
        name: 'شركات ومؤسسات',
        showInForms: true,
    },
    // {
    //     id: 'institutions',
    //     name: 'مؤسسات',
    // },
];

export const supportedProfiles = [
    'people',
    'merchants',
    'companies',
];

export const OrganizationTypes = [
    {
        value: 'general',
        label: 'عام',
    },
    {
        value: 'private',
        label: 'خاصة',
    },
    {
        value: 'ngo',
        label: 'NGO',
    },
    {
        value: 'associations',
        label: 'جمعيات',
    },
];

export const CompaniesFormParts = [
    {
        id: 'information',
        name: 'معلومات',
    },
    {
        id: 'contacts',
        name: 'جهات اتصال',
    },
];

export const CropsMenuItems = [
    {
        id: 'general_info',
        name: 'معلومات',
    },
    // {
    //     id: 'phases',
    //     name: 'مراحل',
    // },
    // {
    //     id: 'soil_info',
    //     name: 'تربة',
    // },
    // {
    //     id: 'pests',
    //     name: 'آفات',
    // },
];

export const PestsInSubCropTypes = [
    {
        title: 'معلومات',
        name: 'معلومات',
        id: 'information',
    },
    {
        title: 'مكافحة',
        name: 'مكافحة',
        id: 'fight',
    },
    {
        title: 'الصور والروابط',
        name: 'الصور والروابط',
        id: 'links',
    },
];

export const PestsFamilies = [
    { value: '1', label: 'فصيلة اللوزة', name_english: 'Aphididae' },
    { value: '2', label: 'فصيلة البق الأبيض', name_english: 'Aleyrodidae' },
    { value: '3', label: 'فصيلة الذباب الأبيض', name_english: 'Drosophilidae' },
    { value: '4', label: 'فصيلة الناموس', name_english: 'Culicidae' },
    { value: '5', label: 'فصيلة الجراد', name_english: 'Acrididae' },
    { value: '6', label: 'فصيلة الفراشة', name_english: 'Noctuidae' },
    { value: '7', label: 'فصيلة الثاقبة', name_english: 'Curculionidae' },
    { value: '8', label: 'فصيلة البذور', name_english: 'Chrysomelidae' },
    { value: '9', label: 'فصيلة العنكبوت', name_english: 'Araneidae' },
    { value: '10', label: 'فصيلة النمل', name_english: 'Formicidae' },
    { value: '11', label: 'فصيلة الحشرات الزاحفة', name_english: 'Hemiptera' },
    { value: '12', label: 'فصيلة الذباب', name_english: 'Muscidae' },
    { value: '13', label: 'فصيلة النخلة', name_english: 'Rhynchophoridae' },
    { value: '14', label: 'فصيلة الدودة القارضة', name_english: 'Scarabaeidae' },
    { value: '15', label: 'فصيلة العث', name_english: 'Acaridae' },
    { value: '16', label: 'فصيلة الخنفساء', name_english: 'Coccinellidae' },
];

export const CROP_TYPE_STATUS = [
    {
        value: '1',
        name: 'new',
        label: 'محصول جديد',
    },
    {
        value: '2',
        name: 'progress',
        label: 'محصول قيد النمو',
    },
    {
        value: '3',
        name: 'completed',
        label: 'مكتمل',
    },
];
export const sampleCropTypes = [
    {
        id: 1,
        name: 'فواكه',
        parent: null, // ليس لها والد، إنها فئة عليا
    },
    {
        id: 2,
        name: 'حمضيات',
        parent: 1, // تنتمي إلى فئة الفواكه
    },
    {
        id: 3,
        name: 'برتقال',
        parent: 2, // تنتمي إلى فئة الحمضيات
    },
    {
        id: 4,
        name: 'خضروات',
        parent: null, // ليس لها والد، إنها فئة عليا
    },
    {
        id: 5,
        name: 'طماطم',
        parent: 4, // تنتمي إلى فئة الخضروات
    },
    {
        id: 6,
        name: 'البذور الزيتية',
        parent: 'المحاصيل',
    },
    {
        id: 7,
        name: 'البقوليات',
        parent: 'المحاصيل',
    },
    {
        id: 8,
        name: 'الأعشاب الطبية',
        parent: 'المحاصيل',
    },
    {
        id: 9,
        name: 'حبوب كاملة',
        parent: 'المحاصيل',
    },
    {
        id: 10,
        name: 'الثومية',
        parent: 'الخضراوات',
    },
    {
        id: 11,
        name: 'البقوليات البيضاء',
        parent: 'المحاصيل',
    },
    {
        id: 12,
        name: 'الخشخاش',
        parent: 'المحاصيل',
    },
    {
        id: 13,
        name: 'الخضروات الورقية',
        parent: 'الخضراوات',
    },
    {
        id: 14,
        name: 'الفواكه الموسمية',
        parent: 'الفواكه',
    },
    {
        id: 15,
        name: 'فطريات',
        parent: 'المحاصيل',
    },
    // يمكنك إضافة المزيد من أنواع الزراعة حسب الحاجة
];

export const areaMeasurementUnits = [
    {
        id: 1,
        name: 'فدان',
    },
    {
        id: 2,
        name: 'متر مربع',
    },
    {
        id: 3,
        name: 'هكتار',
    },
    {
        id: 4,
        name: 'دونم',
    },
    {
        id: 5,
        name: 'كيلومتر مربع',
    },
];

export const weightMeasurementUnits = [
    {
        id: 1,
        name: 'جرام',
    },
    {
        id: 2,
        name: 'كيلوجرام',
    },
    {
        id: 3,
        name: 'طن',
    },
    {
        id: 4,
        name: 'ملغ',
    },
];

export const Saltiness = [
    {
        id: 1,
        name: 'أقل من 1000 pmm',
    },
    {
        id: 2,
        name: '1000 - 3000 pmm',
    },
    {
        id: 3,
        name: '3000 - 5000 pmm',
    },
    {
        id: 4,
        name: '5000 - 10000 pmm',
    },
];

export const Qualifications = [
    {
        value: '1',
        label: 'اساسي', // Leafy under Vegetable Types
    },
    {
        value: '2',
        label: 'اعدادي', // Citrus under Fruit Types
    },
    {
        value: '3',
        label: 'ثانوي', // Fruits under Fruit Types
    },
    {
        value: '4',
        label: 'بكالوريوس', // Berries under Fruit Types
    },
    {
        value: '5',
        label: 'ماجستير', // Legumes under Vegetable Types
    },
    {
        value: '6',
        label: 'دكتوراة', // Perennial under Perennial Plant Types
    },
    {
        value: '7',
        label: 'دبلوم', // Perennial under Perennial Plant Types
    },
    // Add more plant types and categories as needed
];

export const GenderValues = [
    {
        value: '1',
        label: 'ذكر', // Leafy under Vegetable Types
    },
    {
        value: '2',
        label: 'أنثى', // Citrus under Fruit Types
    },
    // Add more plant types and categories as needed
];

export const LandOwnerShipType = [
    {
        value: 'private',
        label: 'ملكية خاصة',
    },
    {
        value: 'rent',
        label: 'اجار',
    },
    {
        value: 'guarantee',
        label: 'ضمان',
    },
];

export const GovControlType = [
    {
        value: 'Agricultural',
        label: 'زراعية',
    },
    {
        value: 'Residential',
        label: 'سكنية',
    },
    {
        value: 'Industrial',
        label: 'صناعية',
    },
];

export const countries = [
    'فلسطين',
    'مصر',
    'المملكة العربية السعودية',
    'العراق',
    'الأردن',
    'الكويت',
    'البحرين',
    'قطر',
    'الإمارات العربية المتحدة',
    'عمان',
    'اليمن',
    'سوريا',
    'لبنان',
    'ليبيا',
    'تونس',
    'الجزائر',
    'المغرب',
    'السودان',
    'الصومال',
    'موريتانيا',
    'جزر القمر',
    'جيبوتي',
];
