import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

const quillModules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['link'],
        ['clean'],
    ],
};

const quillFormats = [
    'header',
    'font',
    'size',
    'list',
    'bold',
    'italic',
    'underline',
    'color',
    'background',
    'script',
    'link',
];

export default function RichTextEditor({
    control, label, fieldName, errors,
}) {
    return (
        <div>
            <Form.Item
                label={label}
                validateStatus={get(errors, fieldName) ? 'error' : ''}
                help={get(errors, fieldName)?.message}
            >
                <Controller
                    control={control}
                    name={fieldName}
                    render={({ field }) => (
                        <ReactQuill
                            style={{ width: '700px', height: '200px' }}
                            modules={quillModules}
                            formats={quillFormats}
                            {...field}
                            onChange={(value) => field.onChange(value)}
                        />
                    )}
                />
            </Form.Item>
        </div>
    );
}
