import { Form, Switch } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function SwitchField({
    control, label, placeholder, fieldName, setValue = () => null, errors,
}) {
    return (
        <Form.Item
            label={label}
            placeholder={placeholder}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <Switch
                        checked={field.value}
                        onChange={(status) => {
                            setValue(fieldName, status);
                        }}
                    />
                )}
            />
        </Form.Item>
    );
}

export default SwitchField;
