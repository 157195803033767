/* eslint-disable import/prefer-default-export */
export const DbTableNames = {
    users: 'users',
    profiles: 'profiles',
    userRoles: 'userRoles',
    lands: 'lands',
    waterSources: 'waterSources',
    crops: 'crops',
    cropsAggregator: 'cropsAggregator',
    cropTypes: 'cropTypes',
    subCropTypes: 'subCropTypes',
    pests: 'pests',
    pestsReports: 'pestsReports',
    pestsReportsAggregator: 'pestsReportsAggregator',
    media: 'media',
    requests: 'requests',
};
