import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Divider,
    Form,
    message,
    List,
} from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextAreaField from '../../FormFields/TextAreaField';
import AutoCompleteField from '../../FormFields/AutoCompleteField';
import { useGetSubCropType, useUpdateSubCropType } from '../../../apis/sub-crop-types';
import { AuthContext } from '../../../shared/AuthProvider';
import { ProfilesContext } from '../../../shared/ProfilesProvider';
import { useUpdateRequest } from '../../../apis/requests';

const schema = yup.object().shape({
    adminRemarks: yup.string().required('العائلة مطلوبة'),
    engRemarks: yup.string().required('العائلة مطلوبة'),
});

export default function AssignSubCropTypeForm({
    request, onComplete, onClose,
}) {
    const { user, isAdmin } = useContext(AuthContext);
    const updateRequest = useUpdateRequest();
    const updateSubCropType = useUpdateSubCropType();
    const { engineersList } = useContext(ProfilesContext);
    const [canEdit, setCanEdit] = useState(false);

    const { data: subCropType } = useGetSubCropType(request?.entityId);
    const [notes, setNotes] = useState([]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            engRemarks: request?.engRemarks,
            selectedEngineer: request?.selectedEngineer,
            adminRemarks: request?.adminRemarks,
        },
    });

    useEffect(() => {
        if (request && subCropType) {
            console.table(subCropType);
            setCanEdit(true);
            // Initialize notes from the request if available
            if (request.notes) {
                setNotes(request.notes);
            }
        }
    }, [request, subCropType]);

    const handleUpdateSubCropType = async () => {
        try {
            const data = { status: 'approved' };
            const createdSubCropType = await updateSubCropType.mutateAsync({
                data,
                subCropTypeId: subCropType?.id,
                userUid: user?.uid,
            });
            return createdSubCropType;
        } catch (e) {
            message.error('Error!');
            return null;
        }
    };

    /**
     * Publication of the request and the subcrop type
     * @param data
     * @returns {Promise<void>}
     */
    const handleFinishPublish = async (data) => {
        const newForm = { ...data };

        if (request && subCropType) {
            newForm.status = 'approved';
            console.table(newForm);
            const updatedItem = await handleUpdateSubCropType();
            console.log(request?.id);
            if (updatedItem) {
                const changedRequest = await updateRequest.mutateAsync({
                    data: newForm,
                    requestId: request?.id,
                    userUid: user?.uid,
                });
                console.log(changedRequest);
                message.success('تم إنشاء طلب النبات!');
            }
        }

        if (onComplete) {
            onComplete();
        }

        if (onClose) {
            onClose();
        }
    };

    const handleAssignFinish = async () => {
        const values = getValues();

        try {
            const newForm = { ...values };
            // Check if the 'notes' array exists, if not, create it
            if (!newForm.notes) {
                newForm.notes = [];
            }
            newForm.notes.push({
                comment: values.adminRemarks,
                type: 'admin',
                timestamp: new Date().toISOString(), // Optional: Add a timestamp
            });

            newForm.notes.push({
                comment: values.engRemarks,
                type: 'engineer',
                timestamp: new Date().toISOString(), // Optional: Add a timestamp
            });

            console.table(newForm);
            if (request) {
                newForm.status = 'assigned';
                const changedRequest = await updateRequest.mutateAsync({
                    data: newForm,
                    requestId: request?.id,
                    userUid: user?.uid,
                });
                console.log(changedRequest);
                message.success('تم تحديث طلب النبات!');
            }

            if (onComplete) {
                onComplete();
            }

            if (onClose) {
                onClose();
            }
        } catch (error) {
            console.log('Submission Failed:', error);
        }
    };

    const handleEngineerSelected = (value, record) => {
        if (record) {
            setValue('selectedEngineer', record.value);
            setValue('engineer', record.item);
        }
    };

    const handleCancel = () => {
        reset();
        console.log('Form cancelled');
    };

    const handlePublish = (data) => {
        handleFinishPublish(data);
    };

    const handleAssign = (data) => {
        handleAssignFinish(data);
    };

    return (
        <>
            <Form layout="vertical" autoComplete="off" onFinish={handleSubmit(handleFinishPublish)}>
                <Divider orientation="left" orientationMargin={50}>
                    تعيين مهندس
                </Divider>
                <AutoCompleteField
                    label="تعيين مهندس"
                    control={control}
                    errors={errors}
                    options={engineersList}
                    onSelect={handleEngineerSelected}
                    placeholder="تعيين مهندس"
                    fieldName="selectedEngineer"
                />
                <TextAreaField
                    label="ملاحظات للمهندس"
                    control={control}
                    errors={errors}
                    placeholder="ملاحظات للمهندس"
                    fieldName="engRemarks"
                />
                <TextAreaField
                    label="ملاحظات المدير"
                    control={control}
                    errors={errors}
                    placeholder="ملاحظات المدير"
                    fieldName="adminRemarks"
                />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={updateSubCropType.isLoading}
                        disabled={!canEdit}
                    >
                        {isAdmin ? 'موافقة كمسؤول' : 'موافقة'}
                    </Button>
                    <Button
                        type="default"
                        onClick={handleAssign}
                        style={{ marginLeft: 10 }}
                    >
                        تعيين
                    </Button>
                    <Button
                        type="default"
                        onClick={handleCancel}
                        style={{ marginLeft: 10 }}
                    >
                        إلغاء
                    </Button>
                    <Button
                        type="dashed"
                        disabled={!canEdit}
                        onClick={handleSubmit(handlePublish)}
                        style={{ marginLeft: 10 }}
                    >
                        نشر
                    </Button>
                </Form.Item>
            </Form>
            <List
                header={<div>Notes</div>}
                bordered
                dataSource={notes}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={`Type: ${item.type}`}
                            description={`Comment: ${item.comment} - Timestamp: ${item.timestamp}`}
                        />
                    </List.Item>
                )}
            />
        </>
    );
}
