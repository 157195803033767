import {
    Button, Dropdown, Progress, Space,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

import SeoMeta from 'Components/SeoMeta';

import { useGetLandsCrops } from '../../apis/crops';
import { useGetLands } from '../../apis/lands';
import FullCropsDrawer from '../FullCropsDrawer';
import ListProfileLands from '../ListProfileLands';
import DynamicTable from '../DynamicTable';

import CropsCardView from './CropsCardView';

function CropsMgmt({ land, profileEntry }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isChooseProfileLandOpen, setIsChooseProfileLandOpen] = useState(false);
    const [entry, setEntry] = useState(null);
    const [lands, setLands] = useState(null);

    // isLoading: profileLandsLoading,
    const { data: profileLands, refetch: refetchLands } = useGetLands(null, profileEntry);
    const { data: entities, isLoading, refetch: refetchEntities } = useGetLandsCrops(lands);
    const [tabuler, setTabuler] = useState(true);
    const [selectedLand, setSelectedLand] = useState(null);

    const onCreate = () => {
        refetchEntities();
        setIsDrawerOpen(false);
    };

    const handleOnClose = () => {
        setEntry(null);
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        if (entry) {
            setIsDrawerOpen(true);
        }
    }, [entry]);

    useEffect(() => {
        if (!profileEntry && land) {
            setLands([land.id]);
            setSelectedLand(land);
            refetchEntities();
        }
    }, [land]);

    useEffect(() => {
        if (profileEntry) {
            refetchLands();
        }
    }, [profileEntry]);

    useEffect(() => {
        if (profileEntry && profileLands && profileLands.length > 0) {
            const landIds = profileLands.map((element) => element.id);
            setLands(landIds);
            refetchEntities();
        }
    }, [profileLands]);

    useEffect(() => {
        if (lands && lands.length > 0) {
            refetchEntities();
        }
    }, [lands]);

    const switchToTableView = () => {
        setTabuler(true);
    };

    const switchToOtherView = () => {
        setTabuler(false);
    };

    const convertTimeStampToDate = (date) => {
        if (!date) {
            return '';
        }
        try {
            const conversion = date && date instanceof Timestamp ? date.toDate() : date || null;
            if (conversion) {
                return dayjs(conversion).format('MM-DD-YYYY');
            }
        } catch (err) {
            return '';
        }

        return '';
    };

    const calculatePassPercent = (plantingDate, plantingExpectedEndDate) => {
        if (!plantingDate || !plantingExpectedEndDate) {
            return 0;
        }

        // Calculate the percentage of time passed
        const now = dayjs();
        const totalDuration = dayjs(plantingExpectedEndDate.toDate()).diff(plantingDate.toDate());
        const timePassed = now.diff(plantingDate.toDate());
        const growthPercent = Math.round((timePassed / totalDuration) * 100);
        return growthPercent;
    };

    const columns = [
        {
            title: 'صنف',
            dataIndex: 'subCropTypeName',
            key: 'subCropTypeName',
        },
        {
            title: 'مساحة مزروعة',
            dataIndex: 'plantingDetails',
            key: 'plantingDetails',
            render: (plantingDetails) => (
                plantingDetails && plantingDetails.cityDetails
                    ? plantingDetails.cityDetails.plantedArea
                    : 'N/A' // Or any default text you prefer when the area data is not available
            ),
        },
        {
            title: 'العائد المتوقع',
            key: 'estimatedYield',
            dataIndex: 'estimatedYield',
        },
        {
            title: 'نسبة فائدة البلد',
            key: 'countryPercent',
            dataIndex: 'countryPercent',
        },
        {
            title: 'تاريخ الزراعة',
            key: 'plantingDate',
            dataIndex: 'plantingDate',
            render: (plantingDate) => (
                <div>{convertTimeStampToDate(plantingDate)}</div>
            ),
        },
        {
            title: 'نسبة النمو',
            key: 'growthPercent',
            dataIndex: 'growthPercent',
            render: (text, record) => {
                const growthPercent = calculatePassPercent(record.plantingDate, record.plantingExpectedEndDate);
                return <Progress size={[100, 15]} percent={growthPercent} />;
            },
        },
        {
            key: 'operation',
            render: (record) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'عرض الملف',
                                onClick: () => {
                                    setEntry(record);
                                    setIsDrawerOpen(true);
                                },
                            },
                            {
                                key: '2',
                                label: 'حذف',
                            },
                        ],
                    }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const closeProfileLandSelection = () => {
        setIsChooseProfileLandOpen(false);
    };

    const checkLandsAndOpenDrawer = () => {
        if (!land) {
            setIsChooseProfileLandOpen(true);
        } else {
            setIsDrawerOpen(true);
        }
    };

    const handleSelectionChange = (selectedLandElement) => {
        if (selectedLandElement) {
            setSelectedLand(selectedLandElement);
            setIsChooseProfileLandOpen(false);
            setIsDrawerOpen(true);
        } else {
            // we will show another image telling the user we have an issue.
            setSelectedLand(null);
        }
    };

    return (
        <>
            <SeoMeta title="إدارة المحاصيل" />
            <FullCropsDrawer
                width="calc(100%)"
                entry={entry}
                onClose={() => handleOnClose()}
                isOpen={isDrawerOpen}
                onConfirm={onCreate}
                selectedLand={selectedLand}
                level={2}
            />
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', overflow: 'auto' }}
            >
                <Space
                    direction="horizontal"
                    size="middle"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Space>
                        <Button type={tabuler ? 'primary' : 'default'} onClick={switchToTableView}>عرض الجدول</Button>
                        <Button type={tabuler ? 'default' : 'primary'} onClick={switchToOtherView}>عرض آخر</Button>
                    </Space>
                </Space>
                <div>
                    <ListProfileLands profileEntry={profileEntry} isOpen={isChooseProfileLandOpen} onClose={closeProfileLandSelection} handleSelectionChange={handleSelectionChange} />

                    {tabuler ? (
                        <DynamicTable
                            tableType="pro"
                            search={false}
                            columns={columns}
                            dataSource={entities}
                            rowKey="id"
                            loading={isLoading}
                            toolBarRender={() => [
                                <Button type="primary" onClick={() => { checkLandsAndOpenDrawer(); }}>اضافه زرعه جديده</Button>,
                            ]}
                            light
                        />
                    ) : (
                        // Render something else when tabuler is false
                        <CropsCardView dataSource={entities} />
                    )}
                </div>
            </Space>
        </>

    );
}

export default CropsMgmt;
