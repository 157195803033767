import {
    Button, Drawer, Space, Typography,
} from 'antd';

import CreateEditSubCropTypeRequestForm from '../CreateEditSubCropTypeRequestForm';
import { useGetSubCropType } from '../../apis/sub-crop-types';
// useUpdateSubCropType,
const { Text } = Typography;

export default function FullSubCropTypeRequestDrawer({
    onClose, isOpen, entry, onConfirm, request,
}) {
    const { data } = useGetSubCropType(entry?.id);

    function getExtraWidgets() {
        return (
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                {/* Title on the right */}
                <div style={{ marginLeft: 'auto' }}>
                    <Text strong>عنوان الصفحة</Text>
                </div>
                {data ? (
                    <div>
                        <Text strong>{data.name}</Text>
                    </div>
                ) : (
                    <div>&nbsp;</div>
                )}
                <div>
                    <Button onClick={onClose}>إغلاق</Button>
                </div>
            </Space>
        );
    }

    return (
        <Drawer
            title="إدارة الطلبات"
            width="calc(50% - 200px)"
            onClose={onClose}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={getExtraWidgets()}
            destroyOnClose
            placement="left"
            closable={false}
        >
            <CreateEditSubCropTypeRequestForm entry={entry} onComplete={onConfirm} cropType={data} request={request} />
        </Drawer>
    );
}
