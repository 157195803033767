import React, { useState } from 'react';
import { Button, Drawer } from 'antd';

import CreateEditLandBasicsForm from '../CreateEditLandBasicsForm';
import LandProfileDashboard from '../LandProfileDashboard';
import WajedModal from '../WajedModal';

import './styles.scss';

export default function FullLandsDrawer({
    onClose, isOpen, width, entry, onComplete, profileEntry,
}) {
    const [subTitle, setSubTitle] = useState('');
    return (
        <div>
            { entry ? (
                <WajedModal
                    title="إدارة الأراضي"
                    subTitle={subTitle}
                    width={width}
                    onClose={onClose}
                    open={isOpen}
                    isOpen={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={<Button onClick={onClose}>إغلاق</Button>}
                    destroyOnClose
                    placement="left"
                    closable={false}
                    id="full-lands-drawer"
                >
                    <LandProfileDashboard entry={entry} onComplete={onComplete} profileEntry={profileEntry} setSubTitle={setSubTitle} />
                </WajedModal>
            ) : (
                <Drawer
                    title="إدارة الأراضي/أضف الأرض"
                    width="calc(50% - 200px)"
                    onClose={onClose}
                    open={isOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    destroyOnClose
                    placement="left"
                    closable={false}
                >
                    <CreateEditLandBasicsForm onComplete={onComplete} entry={entry} profileEntry={profileEntry} submitText="حفظ" />
                </Drawer>
            )}

        </div>
    );
}
