import { Form, Input } from 'antd';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

function TextField({
    control, label, placeholder, fieldName, errors, onChange, disabled,
}) {
    const handleOnChange = (text) => {
        if (onChange) {
            onChange(text);
        }
    };
    return (
        <Form.Item
            label={label}
            validateStatus={get(errors, fieldName) ? 'error' : ''}
            help={get(errors, fieldName)?.message}
        >
            <Controller
                control={control}
                name={fieldName}
                render={({ field }) => (
                    <Input
                        {...field}
                        placeholder={placeholder}
                        onChange={(e) => {
                            field.onChange(e); // Update the value in React Hook Form
                            handleOnChange(e.target.value);
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </Form.Item>
    );
}

export default TextField;
