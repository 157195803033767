import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyAmm-niqeNUA3OSTgKtKS2UwBtx1T4rbDc',
    authDomain: 'akkaria.firebaseapp.com',
    projectId: 'akkaria',
    storageBucket: 'akkaria.appspot.com',
    messagingSenderId: '809571063195',
    appId: '1:809571063195:web:4e412c48216fc593b7bd3e',
    measurementId: 'G-HDY67VBL8W',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const storageRef = ref(firebaseStorage);
