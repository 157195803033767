import * as dayjs from 'dayjs';
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    collection, where, limit, startAfter, getDocs, query, doc, getDoc, addDoc, updateDoc, deleteDoc, writeBatch,
} from 'firebase/firestore';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

export const useGetPests = ({
    cropTypeId = null, subCropTypes = [], offset = 0, queryLimit = 9999,
    activeCityId = null,
}) => useQuery(['useGetPests', {
    cropTypeId, subCropTypes, queryLimit, offset,
}], async () => {
    const entries = [];

    const pestsRef = collection(firebaseFirestore, DbTableNames.pests);
    const queryParams = [pestsRef];

    if (cropTypeId) {
        queryParams.push(where('subCropType.cropTypeId', '==', cropTypeId));
    }

    if (subCropTypes && subCropTypes.length > 0) {
        queryParams.push(where('subCropTypesIds', 'array-contains-any', subCropTypes));
    }

    if (activeCityId) {
        queryParams.push(where('cities', 'array-contains-any', [activeCityId]));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useGetPest = (pestId) => useQuery(
    ['useGetPest', pestId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.pests, pestId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!pestId,
    },
);

export const useCreatePest = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const createdData = {
            ...data,
            ...(profileId ? { profiles: [profileId] } : {}),
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.pests), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

export const useUpdatePest = () => useMutation({
    mutationFn: async ({ data = {}, pestId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.pests, pestId), updateData);
        return {
            ...data,
        };
    },
});

export const batchUpdatePests = async (pestsData) => {
    const batch = writeBatch(firebaseFirestore);

    if (pestsData && pestsData.length > 0) {
        pestsData.forEach(({ pestId, newData }) => {
            const pestRef = doc(collection(firebaseFirestore, DbTableNames.pests), pestId);
            batch.update(pestRef, newData);
        });

        try {
            await batch.commit();
            console.log('Batch update successful!');
        } catch (error) {
            console.error('Error during batch update:', error);
        }
    }
};

export const searchPests = (queryString) => {
    const results = [].filter((crop) => {
        // Case-insensitive search by family or name
        const familyMatch = crop.family.includes(queryString);
        const nameMatch = crop.name.includes(queryString);
        return familyMatch || nameMatch;
    });
    return results;
};

export const useDeletePest = () => useMutation({
    mutationFn: async (id) => {
        await deleteDoc(doc(firebaseFirestore, DbTableNames.pests, id));
    },
});

// // We will replace this with the real db.
// const listDataSource = [
//     {
//         id: 1,
//         family: 1,
//         text: 'خطر',
//         color: 'red',
//         name: 'آفة القمح الصحراوي',
//         scientificName: 'القمح الصحراوي',
//         content: 'الجراد الصحراوي هو نوع من الجرادات الذي يشكل تهديداً كبيراً للمزارعين',
//         images: [{
//             uid: '-1',
//             name: 'image.png',
//             status: 'done',
//             url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//         }],
//         links: [
//             {
//                 title: 'رابط لصفحة الآفات',
//                 description: 'تحتوي هذه الصفحة على كافة المعلومات حول الآفات',
//             },
//         ],
//     },
//     {
//         id: 2,
//         family: 1,
//         text: 'لا يوجد خطر',
//         color: 'green',
//         name: 'آفة العنب',
//         scientificName: 'العنب',
//         content: ' نوع من الجرادات الذي يشكل ت',
//         links: [
//             {
//                 title: 'رابط لصفحة الآفات',
//                 description: 'تحتوي هذه الصفحة على كافة المعلومات حول الآفات',
//             },
//         ],
//     },
//     {
//         id: 3,
//         family: 3,
//         text: 'خطر خفيف',
//         color: 'pink',
//         name: 'آفة الزهور',
//         scientificName: 'الزهور',
//         content: ' نوع من الجرادات الذي يشكل ت',
//         links: [
//             {
//                 title: 'رابط لصفحة الأسمدة',
//                 description: 'تحتوي هذه الصفحة على كافة المعلومات حول الأسمدة',
//             },
//         ],
//     },
// ];
//
// export function getPestInfo(id) {
//     return listDataSource.find((item) => item.id === id);
// }
//
// export function listPests() {
//     return listDataSource;
// }
