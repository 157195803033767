import { useQuery, useMutation } from '@tanstack/react-query';
import {
    doc,
    getDoc,
    addDoc,
    collection,
    where,
    query,
    getDocs,
    getCountFromServer,
    orderBy,
    startAfter,
    limit,
    documentId,
    updateDoc,
} from 'firebase/firestore';
import * as dayjs from 'dayjs';

import { firebaseFirestore } from 'shared/firebase.init';
import { DbTableNames } from 'shared/db-constants';

import { isValidProfileType } from '../utils/validators';

export const useGetProfile = (profileId) => useQuery(
    ['profiles', profileId],
    async () => {
        const docRef = doc(firebaseFirestore, DbTableNames.profiles, profileId);
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data() : null;
    },
    {
        enabled: !!profileId,
    },
);

export const useUpdateProfile = () => useMutation({
    mutationFn: async ({ data, profileId, userUid }) => {
        const updateData = {
            ...data,
            updatedAt: dayjs().unix(),
            updatedBy: userUid,
        };
        await updateDoc(doc(firebaseFirestore, DbTableNames.profiles, profileId), updateData);
        // await setDoc(doc(firebaseFirestore, DbTableNames.profiles, profileId), updateData);
        return {
            ...data,
        };
    },
});

export const useCreateProfile = () => useMutation({
    mutationFn: async ({ data, userUid }) => {
        const createdData = {
            ...data,
            admins: [userUid],
            createdAt: dayjs().unix(),
            createdBy: userUid,
        };

        const docRef = await addDoc(collection(firebaseFirestore, DbTableNames.profiles), createdData);
        return {
            ...createdData,
            id: docRef.id,
        };
    },
});

async function getLastDocument(skipCount) {
    const q = query(collection(firebaseFirestore, DbTableNames.profiles), orderBy('createdAt'), limit(skipCount + 1));
    const snapshot = await getDocs(q);

    let lastDocument;
    snapshot.forEach((queryDoc) => {
        lastDocument = queryDoc;
    });

    return lastDocument;
}

export const useGetProfiles = (types = [], offset = 0, queryLimit = 9999) => useQuery(['profiles', { types, queryLimit, offset }], async () => {
    const entries = [];
    const queryTypes = [];

    const profilesRef = collection(firebaseFirestore, DbTableNames.profiles);
    const queryParams = [profilesRef];

    types.forEach((profileType) => {
        if (isValidProfileType(profileType)) {
            queryTypes.push(profileType);
        }
    });

    if (queryTypes.length > 0) {
        queryParams.push(where('profileType', 'in', queryTypes));
    }

    const queryTotal = query(...queryParams);
    const snapshot = await getCountFromServer(queryTotal);
    const totalCount = snapshot.data().count;

    if (offset) {
        queryParams.push(orderBy('createdAt'));
        const lastDocument = await getLastDocument(offset * queryLimit);
        queryParams.push(startAfter(lastDocument));
    } else {
        queryParams.push(orderBy('createdAt'));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    // console.table(entries);
    return { data: entries, total: totalCount };
});

export const useGetProfilesDetails = (ids, offset = 0, queryLimit = 9999) => useQuery(['useGetProfilesDetails', { ids, queryLimit, offset }], async () => {
    const entries = [];

    const profilesRef = collection(firebaseFirestore, DbTableNames.profiles);
    const queryParams = [profilesRef];

    if (ids && ids.length > 0) {
        console.table(ids);
        queryParams.push(where(documentId(), 'in', ids));
    }

    const queryTotal = query(...queryParams);
    const snapshot = await getCountFromServer(queryTotal);
    const totalCount = snapshot.data().count;

    // if (offset) {
    //     queryParams.push(orderBy('createdAt'));
    //     const lastDocument = await getLastDocument(offset * queryLimit);
    //     queryParams.push(startAfter(lastDocument));
    // } else {
    //     queryParams.push(orderBy('createdAt'));
    // }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    // console.table(entries);
    return { data: entries, total: totalCount };
});

export const useListProfiles = (types, offset = 0, queryLimit = 9999) => useQuery(['listProfiles', { types, queryLimit, offset }], async () => {
    const entries = [];

    const profilesRef = collection(firebaseFirestore, DbTableNames.profiles);
    const queryParams = [profilesRef];

    if (types && types.length > 0) {
        queryParams.push(where('profileType', 'in', types));
    }

    if (offset) {
        queryParams.push(startAfter(offset));
    }

    queryParams.push(limit(queryLimit));
    const q = query(...queryParams);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});

export const useListEngineers = (types, offset = 0, queryLimit = 9999) => useQuery(['useListEngineers', { types, queryLimit, offset }], async () => {
    const entries = [];

    const profilesRef = collection(firebaseFirestore, DbTableNames.userRoles);
    const queryParams = [profilesRef];

    if (types && types.length > 0) {
        queryParams.push(where('engineer', '==', true));
    }

    const q = query(...queryParams);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queryDoc) => {
        const entry = queryDoc.data();
        entries.push({
            ...entry,
            id: queryDoc.id,
        });
    });

    return entries;
});
