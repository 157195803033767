import {
    Dropdown, Button, Badge, Avatar, Space, Progress,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import SeoMeta from 'Components/SeoMeta';
import CreateProfileDrawer from 'Components/CreateProfileDrawer';
import { useGetProfilesDetails } from 'apis/profiles';
import FullProfileDrawer from 'Components/FullProfileDrawer';
import DynamicTable from 'Components/DynamicTable';

import SubscriptionWidget from '../Components/SubscriptionWidget';
import { useGetCropsAggregation } from '../../../apis/crops-aggregation';

function ProfilesDetails({ dashboardEntry }) {
    const queryClient = useQueryClient();
    const [isCreateProfileOpen, setIsCreateProfileOpen] = useState(false);
    const [isFullProfileDrawerOpen, setIsFullProfileDrawerOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    // const [current, setCurrent] = useState(page - 1);

    const { data: entities, isLoading } = useGetCropsAggregation(null, [dashboardEntry?.id]);
    const [ids, setIds] = useState([]);

    const { data: profiles, refetch: refetchProfiles } = useGetProfilesDetails(
        ids,
    );

    const mappedProfiles = useMemo(() => profiles?.data?.map((entry) => ({
        id: entry.id,
        key: entry.id,
        name: entry.name,
        age: entry.age ?? 'N/A',
        address: entry.address,
        subscription: {
            state: 'active',
            expirationDate: '01.02.2023',
        },
        email: entry.email,
        profileType: entry.profileType,
    })), [profiles]);

    useEffect(() => {
        refetchProfiles();
    }, [page, pageSize]);

    useEffect(() => {
        if (entities && entities.length > 0) {
            const entityIds = [];
            // Extracting beneficiaries IDs and adding them to the entityIds array
            entities.forEach((entity) => {
                if (entity.beneficiaries && entity.beneficiaries.length > 0) {
                    entityIds.push(...entity.beneficiaries.map((beneficiary) => beneficiary));
                }
            });

            // Removing duplicates by converting the array to a Set and back to an array
            const uniqueIds = Array.from(new Set(entityIds));
            // Setting the unique IDs in the state
            setIds(uniqueIds);

            refetchProfiles();
        }
    }, [entities]);

    useEffect(() => {
        if (profiles) {
            console.table(profiles);
        }
    }, [profiles]);

    const getCityArabicName = (city) => {
        switch (city) {
        case 'hebron-governorate':
            return 'محافظة الخليل';
        case 'jenin-governorate':
            return 'محافظة جنين';
        case 'jericho-governorate':
            return 'محافظة اريحا';
        case 'jerusalem-governorate':
            return 'محافظة القدس';
        case 'nablus-governorate':
            return 'محافظة نابلس';
        case 'qalqiliya-governorate':
            return 'محافظة قلقيلية';
        case 'ramallah_and_al_bireh-governorate':
            return 'محافظة رام الله والبيرة';
        case 'salfit-governorate':
            return 'محافظة سلفيت';
        case 'tubas-governorate':
            return 'محافظة طوباس';
        case 'tulkarem-governorate':
            return 'محافظة طولكرم';
        default:
            return '';
        }
    };

    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge size="default" offset={[49, 5]}>
                        <Avatar
                            style={{ backgroundColor: '#f56a00' }}
                            size={49}
                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        />
                    </Badge>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{text}</div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{record.email}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'العنوان',
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ fontSize: '15px', color: '#131313', fontWeight: 'bold' }}>{getCityArabicName(text?.city?.parent)}</div>
                        <div style={{ fontSize: '14px', color: '#6E6893' }}>{text?.city?.arabic}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'معلومات',
            dataIndex: 'infoPercentage',
            key: 'infoPercentage',
            render: (value) => (
                <Progress percent={value} />
            ),
        },
        {
            title: 'مراسلات',
            dataIndex: 'address2',
            key: 'address2',
        },
        {
            title: 'الاشتراك',
            key: 'subscription',
            dataIndex: 'subscription',
            render: (entry) => (
                <SubscriptionWidget subscription={entry} />
            ),
        },
        {
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record) => (
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: 'عرض الملف',
                            onClick: () => {
                                setEntryToEdit(record);
                                setIsFullProfileDrawerOpen(true);
                            },
                        },
                        {
                            key: '2',
                            label: 'حذف',
                        },
                    ],
                }}
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <SeoMeta title="إدراة الملفات" />
            <div>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <DynamicTable
                        columns={columns}
                        dataSource={mappedProfiles}
                        loading={isLoading}
                        pagination={{
                            total: profiles?.total,
                            current: page,
                            pageSize,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            onChange: (newPage, pageSizeNew) => {
                                setPage(newPage);
                                // setCurrent(newPage - 1);
                                setPageSize(pageSizeNew);
                            },
                        }}
                    />
                </Space>
            </div>
            <CreateProfileDrawer
                isOpen={isCreateProfileOpen}
                onClose={() => setIsCreateProfileOpen(false)}
                onComplete={(createElement) => {
                    if (createElement) {
                        setEntryToEdit(createElement);
                        setIsFullProfileDrawerOpen(true);
                    }
                    queryClient.invalidateQueries(['profiles']);
                }}
            />
            <FullProfileDrawer
                isOpen={isFullProfileDrawerOpen}
                onClose={() => setIsFullProfileDrawerOpen(false)}
                entry={entryToEdit}
                width="calc(100%)"
            />
        </>
    );
}

export default ProfilesDetails;
